import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaginatedResult } from '../_models/pagination';
import { map, take } from 'rxjs/operators';
import { Constants } from '../_utils/constants';
import { Meta } from '../_models/meta';

@Injectable({
  providedIn: 'root'
})

export class MetaService {

  baseURL =  Constants.URL_BASE+'meta';

  constructor(private http: HttpClient) { }


  public getDados(){
    return this.http.get<Meta[]>(this.baseURL);  }

  add(dados: Meta) {
    return this.http.post(this.baseURL, dados);
  }

  update(dados: Meta) {
    return this.http.post(this.baseURL, dados);
  }
  validarGatilho(meta: Meta) {
    return this.http.post(`${this.baseURL}/validarGatilho`, meta);
  }
}
