import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { Pedido } from '../../_models/pedido';
import { PedidoService } from '../../_service/pedido.service';
import { MensagemService } from '../../_service/mensagem.service';
import { Mensagem } from '../../_models/mensagem';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Resultado } from '../../_models/resultado';
import { PedidoIQ } from '../../_models/pedidoIQ';
import { PedidoAuditoria } from '../../_models/pedidoAuditoria';

@Component({
  templateUrl: 'sistema.component.html'
})

export class SistemaComponent implements OnInit {
  @ViewChild('myModal') public myModal: ModalDirective;
  @ViewChild('myModalResultado') public myModalResultado: ModalDirective;
  @ViewChild('myAuditoria') public myAuditoria: ModalDirective;


  alerts: any[];


  isShow: boolean = true;
  isShowEmail: boolean = false;
  isShowPedido: boolean = false;
  isShowDebito: boolean = false;
  isShowAuditoria: boolean = false;

  listFiltrados: Pedido[];
  list: Pedido[];


  listEmailFiltrados: Mensagem[];
  listEmail: Mensagem[];

  listDebitoFiltrados: Pedido[];
  listDebito: Pedido[];

  listAuditoriaFiltrados: Pedido[];
  listAuditoria: Pedido[];
  auditoria: PedidoAuditoria[];

  dados: Pedido;
  dadosResultado: Resultado;
  modoSalvar = 'post';


  registerForm: FormGroup;

  texto = '';

  dataInicio = '';
  dataFinal = '';


  _filtroLista = '';
  _filtroEmailLista = '';
  _filtroDebitoLista = '';
  _filtroAuditoriaLista = '';


  constructor(private _service: PedidoService, private _serviceMensagem: MensagemService, private fb: FormBuilder,
    public router: Router, private spinner: NgxSpinnerService) { }

  get filtroLista(): string {
    return this._filtroLista;
  }
  set filtroLista(value: string) {
    this._filtroLista = value;
    this.listFiltrados = this.filtroLista ? this.filtrarDados(this.filtroLista) : this.list;
  }
  get filtroEmailLista(): string {
    return this._filtroEmailLista;
  }
  set filtroEmailLista(value: string) {
    this._filtroEmailLista = value;
    this.listEmailFiltrados = this.filtroEmailLista ? this.filtrarDadosEmail(this.filtroEmailLista) : this.listEmail;
  }

  get filtroDebitoLista(): string {
    return this._filtroDebitoLista;
  }
  set filtroDebitoLista(value: string) {
    this._filtroDebitoLista = value;
    this.listDebitoFiltrados = this._filtroDebitoLista ? this.filtrarDadosDebito(this.filtroDebitoLista) : this.listDebito;
  }
  get filtroAuditoriaLista(): string {
    return this._filtroAuditoriaLista;
  }
  set filtroAuditoriaLista(value: string) {
    this._filtroAuditoriaLista = value;
    this.listAuditoriaFiltrados = this._filtroAuditoriaLista ? this.filtrarDadosAuditoria(this.filtroAuditoriaLista) : this.listAuditoria;
  }


  ngOnInit(): void {
    this.validation();
    this.alerts = new Array();
    window.scrollTo(0, 0);
  }

  voltar() {
    this.router.navigate(['/config']);
  }

  filtrarDados(filtrarPor: string): Pedido[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    return this.list.filter(
      d => d.nomeCliente.toLocaleLowerCase().indexOf(filtrarPor) !== -1
    );
  }

  filtrarDadosEmail(filtrarPor: string): Mensagem[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    return this.listEmail.filter(
      d => d.nomeDestino.toLocaleLowerCase().indexOf(filtrarPor) !== -1
    );
  }

  filtrarDadosDebito(filtrarPor: string): Pedido[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    return this.listDebito.filter(
      d => d.nomeCliente.toLocaleLowerCase().indexOf(filtrarPor) !== -1
    );
  }

  filtrarDadosAuditoria(filtrarPor: string): Pedido[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    return this.listAuditoria.filter(
      d => d.nomeCliente.toLocaleLowerCase().indexOf(filtrarPor) !== -1
    );
  }

  showForm(form: string) {
    this.isShow = false;
    this.isShowEmail = false;
    this.isShowPedido = false;
    this.isShowDebito = false;
    this.isShowAuditoria = false;

    switch (form) {
      case "isShow":
        this.isShow = true;
        break;
      case "isShowEmail":
        this.isShowEmail = true;
        break;
      case "isShowPedido":
        this.isShowPedido = true;
        break;
      case "isShowDebito":
        this.isShowDebito = true;
        break;
      case "isShowAuditoria":
        this.isShowAuditoria = true;
        break;

      default:
        break;
    }
  }

  getDados() {
    this.spinner.show();
    this._service.getbyResponsavel().subscribe({
      next: (_list: Pedido[]) => {

        this.list = _list;
        this.listFiltrados = _list;
        this.showForm("isShowPedido");

        this.spinner.hide();

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }
    });

  }

  getDebitos() {
    this.spinner.show();
    this._service.getDebitos().subscribe({
      next: (_list: Pedido[]) => {

        this.list = _list;
        this.listDebitoFiltrados = _list;
        this.showForm("isShowDebito");

        this.spinner.hide();

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }
    });

  }

  getAuditoria() {
    this.spinner.show();
    this._service.getbyNegada(7).subscribe({
      next: (_list: Pedido[]) => {

        this.listAuditoria = _list;
        this.listAuditoriaFiltrados = _list;
        this.showForm("isShowAuditoria");

        this.spinner.hide();

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }
    });

  }

  getEmails() {
    this.spinner.show();
    this._serviceMensagem.getNaoEnviado().subscribe({
      next: (_listEmail: Mensagem[]) => {

        this.listEmail = _listEmail;
        this.listEmailFiltrados = _listEmail;
        this.showForm("isShowEmail");

        this.spinner.hide();

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }
    });

  }

  cancelar(item: Pedido) {
    this.spinner.show();
    item.observacao = "CANCELAMENTO AUTOMATICO - AUDITORIA NEGADA";
    item.diaVencimento = 0;

    this._service.cancelar(item).subscribe({
      next: (novoRegistro: Pedido) => {
        this.getAuditoria();
        this.add('Registro cancelado!', 'success');
      }, error: (error: any) => {
        this.spinner.hide();

        this.add(error.error.detail, 'danger');
      }
    });
  }

  cancelarTodos()
  {
    this.spinner.show();

    this._service.cancelarTodos().subscribe({
      next: (novoRegistro: Pedido) => {
        this.getAuditoria();
        this.add('Registros cancelados!', 'success');
      }, error: (error: any) => {
        this.spinner.hide();

        this.add(error.error.detail, 'danger');
      }
    });
  }

  refresh() {
    this.showForm("isShow");
  }

  openAuditoria(item: Pedido) {
    this.auditoria= item.auditoria;
    this.myAuditoria.show();
  }


  liberar(pedido: Pedido) {

    this.spinner.show();
    this._service.liberarPedido(pedido).subscribe({
      next: async (registro: Pedido) => {

        this.getDados();
        this.add('Pedido Liberado', 'success');

        this.spinner.hide();

      }, error: (e: any) => {
        this.spinner.hide();
        this.add(e.error.detail, 'danger');
      }
    });

  }

  enviarTodos() {
    this.spinner.show();
    this._serviceMensagem.enviarTodos().subscribe({
      next: () => {
        this.getEmails();
        this.add('Todos Emails enviados!', 'success');
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(error.error.detail, 'danger');
      }
    });
  }

  enviar(email: Mensagem) {
    this.spinner.show();
    this._serviceMensagem.enviar(email).subscribe({
      next: () => {
        this.getEmails();
        this.add('Email enviado!', 'success');
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(error.error.detail, 'danger');
      }
    });
  }

  naoEnviar(email: Mensagem) {
    this.spinner.show();
    this._serviceMensagem.naoEnviar(email).subscribe({
      next: () => {
        this.getEmails();
        this.add('Email desconsiderado!', 'success');
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(error.error.detail, 'danger');
      }
    });
  }

  ver(email: Mensagem) {
    this.texto = email.texto;
    this.myModal.show();
  }

  emailResultado() {
    this.dataInicio = '';
    this.dataFinal = '';
    this.myModalResultado.show();
  }

  gerarEmail() {
    this.spinner.show();
    this.dadosResultado = new Resultado();
    this.dadosResultado.dataInicio = this.dataInicio;
    this.dadosResultado.dataFinal = this.dataFinal;

    this._serviceMensagem.gerarEmail(this.dadosResultado).subscribe({
      next: () => {
        this.getEmails();
        this.myModalResultado.hide();
        this.add('Emails gerados!', 'success');
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(error.error.detail, 'danger');
      }
    });

  }

  validation() {
    this.registerForm = this.fb.group({
      cpfCnpj: ['', [Validators.required]],
      nomeCliente: ['', [Validators.required]],
      tipoVenda: ['', [Validators.required]],
      numeroPedido: [''],
      segmento: [''],
      numeroOS: [''],
      dataOSEmitida: [''],
      dataAgendamento: [''],
      turno: [''],
      idBundle: [''],
      funcionarioId: ['', [Validators.required]],
      diaVencimento: [''],
      bolMigracaoFixoFibra: [''],
      bolMigracaoVeloxFibra: [''],
      bolPortabilidade: [''],
      vendaRecadastrada: [''],
      viabilidadeParcial: [''],
      contaOnline: [''],
      observacao: ['']
    });


  }

  add(msg: string, tipo: string): void {
    //danger, success, info, warning
    this.alerts.push({
      type: tipo,
      msg: msg,
      timeout: 5000
    });
    console.log(this.alerts);
  }

  onClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }



}
