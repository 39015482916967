export class PedidoPosVenda {

  constructor() { }

  id: number;
  pedidoId: string;
  descricao: string;
  nomeCliente: string;
  segmento: string;
  vendedor:string;
  cpf: string;
  numeroOs: string;
  dataProgamadaInteracao: string;
  dataInteracao: string;
  statusPosVenda: number;
  statusPosVendaDescricao: string;
  diasAberto: number;
  contatoRealizado: boolean;
  dataContato: string;
  observacao: string;
  possivelChurn: boolean;
  pendente: boolean;
  dataPendencia: string;
  rastro: string[];
}
