export class Importacao {

    constructor() { }

    id: number;
    descricao: string;
    status: number;
    statusDescricao: string;

    statusBov: number;
    statusBovDescricao:string;

    totalRegistro: number;
    totalRegistroImportado : number;
    dataInicio: string;
    dataFinal: string;
    dataEnvio: string;
    tipoImportacao: number;
    tipoImportacaoDescricao: string;
    dataReferenciaBov: string;
    importacao: number;
    observacao:string;
    dados: string;
    operadora: number;
    operadoraDescricao:string;
  //  public List<ImportacaoRetornoResponse> Retorno { get; set; }
}
