import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { Constants } from '../../_utils/constants';
import { Pedido } from '../../_models/pedido';
import { PedidoService } from '../../_service/pedido.service';
import { GeralService } from '../../_service/geral.service';
import { Geral } from '../../_models/geral';
import { PlanoService } from '../../_service/plano.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ExcelService } from '../../_service/excel.service';
import { ClienteTelefone } from '../../_models/clienteTelefone';
import { AuthService } from '../../_service/auth.service';


@Component({
  templateUrl: 'agendamento.component.html'
})

export class AgendamentoComponent implements OnInit {

  @ViewChild('myModal') public myModal: ModalDirective;
  @ViewChild('myTarefa') public myTarefa: ModalDirective;
  @ViewChild('myModalTelefone') public myModalTelefone: ModalDirective;



  alerts: any[];

  pag: number = 1;
  contador: number = 10;

  dataView: string;
  pedidoId: number;
  status: number = 0;

  statusTarefa: number = 0;

  isShow: boolean = false;
  isShowLimpar: boolean = false;
  isShowPesquisa: boolean = false;

  listExport: Pedido[];
  listFiltrados: Pedido[];
  list: Pedido[];
  telefone: ClienteTelefone[];

  vendedorList: Geral[];
  segmentoList: Geral[];
  statusList: Geral[];
  tipoList: Geral[];

  dados: Pedido;
  dadosPesquisa: Pedido;
  modoSalvar = 'post';

  mask_cpf = Constants.CPF_FMT;
  mask_cnpj = Constants.CNPJ_FMT;
  mask_data = Constants.DATE_FMT;
  mask_telefone = Constants.TELEFONE_FMT;
  mask_celular = Constants.CELULAR_FMT;

  registerForm: FormGroup;
  reagendamentoForm: FormGroup;
  tarefaForm: FormGroup;


  obs = '';

  _filtroLista = '';

  constructor(private _service: PedidoService, public authService: AuthService, private _serviceGeral: GeralService, private _servicePlano: PlanoService, private fb: FormBuilder,
    public router: Router, private spinner: NgxSpinnerService, private _serviceExcel: ExcelService) { }

  get filtroLista(): string {
    return this._filtroLista;
  }
  set filtroLista(value: string) {
    this._filtroLista = value;
    this.listFiltrados = this.filtroLista ? this.filtrarDados(this.filtroLista) : this.list;
  }


  ngOnInit(): void {
    this.spinner.show();
    this.validation();
    this.getDados();
    this.alerts = new Array();
    this.telefone = new Array();
    window.scrollTo(0, 0);

    if (this.authService.tokenExpired())
    this.logout();
}


logout(): void {
  this.authService.logout();
  this.router.navigateByUrl("/register");
}
  voltar() {
    this.router.navigate(['/dashboard']);
  }

  filtrarDados(filtrarPor: string): Pedido[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    return this.list.filter(
      d => d.nomeCliente.toLocaleLowerCase().indexOf(filtrarPor) !== -1
    );
  }

  getDados() {
    this.spinner.show()
    this._service.getAgendamento().subscribe({
      next: (_list: Pedido[]) => {
        this.list = _list;
        this.listFiltrados = this.list;
        if (_list.length > 0)
          this.dataView = _list[0].dataAgendamento.substring(0, 10);

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  getVendedor() {
    this._serviceGeral.getCombo("Vendedor").subscribe({
      next: (_list: Geral[]) => {
        this.vendedorList = _list;
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar Vendedores`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  getSegmento() {
    this._serviceGeral.getCombo("Segmento").subscribe({
      next: (_list: Geral[]) => {
        this.segmentoList = _list;
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar Segmentos`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  getStatusAgendamento() {
    this._serviceGeral.getCombo("StatusAgendamento").subscribe({
      next: (_list: Geral[]) => {
        this.statusList = _list;
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar Status do Agendamento`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  refresh() {

    this.spinner.show();


    this.registerForm.reset();
    this.isShow = false;
    this.isShowPesquisa = false;
    this.isShowLimpar = false;

    this.getDados();

  }

  confimar(pedido: Pedido) {
    this.spinner.show()
    this._service.confirmarAgendamento(pedido).subscribe({
      next: (item: Pedido) => {
        if (this.isShowLimpar)
          this.pesquisar();
        else
          this.getDados();
          this.add('Agendamento Confirmado', 'success');
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar o pedido`, 'danger');
      }, complete: () => this.spinner.hide()
    });

  }

  pesquisaAvancada() {
    this.spinner.show();
    this.isShowPesquisa = true;
    this.registerForm.reset();
    this.getSegmento();
    this.getStatusAgendamento();
    this.getVendedor();
    this.dadosPesquisa = new Pedido();
    this.dados = new Pedido();

    this.spinner.hide();
  }

  exportar(): void {
    this.spinner.show();
    this._service.downloadAgendamento(this.dadosPesquisa).subscribe({
      next: fileData => {
        const b: any = new Blob([fileData], { type: 'application/vnd.ms-excel' });
        var dt = Date.now();
        let a = document.createElement('a');
        a.download = "Agendamento" + dt.toString() + ".xls";
        a.href = window.URL.createObjectURL(b);
        a.click();
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar ao gerar o relatório`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  pesquisar() {
    if (this.registerForm.valid) {
      this.spinner.show();

      this.dados = Object.assign({}, this.registerForm.value);
      this.dados.statusVenda ??= 0;
      this.dados.tipoVenda ??= 0;
      this.dados.segmento ??= 0;
      this.dados.funcionarioId ??= 0;

      this.dadosPesquisa = this.dados;

      this._service.pesquisarAgendamento(this.dados).subscribe({
        next: (_list: Pedido[]) => {
          this.list = _list;
          this.isShowPesquisa = false;
          this.isShowLimpar = true;
          this.listFiltrados = this.list;


        }, error: (error: any) => {
          this.spinner.hide();
          this.add(`Erro ao tentar carrgar dados`, 'danger');
        }, complete: () => this.spinner.hide()
      });
    }
  }

  openModal(item: Pedido) {
    this.reagendamentoForm.reset();
    this.myModal.show();
    this.pedidoId = item.id;
  }

  openTarefa(item: Pedido) {
    this.statusTarefa = 0;
    this.tarefaForm.reset();
    this.myTarefa.show();
    this.pedidoId = item.id;
  }

  setTarefa(valor: number) {
    this.statusTarefa = valor;
  }

  registrarTarefa(myTarefa: any) {
    this.spinner.show();

    this.dados = new Pedido();
    this.dados = Object.assign({}, this.tarefaForm.value);
    this.dados.id = this.pedidoId;

    this._service.registrarTarefa(this.dados).subscribe({
      next: (registro: Pedido) => {
        myTarefa.hide()
        if (this.isShowLimpar)
        this.pesquisar();
      else
        this.getDados();
        this.add('Registo salvo', 'success');
      }, error: (e: any) => {
        myTarefa.hide();
        this.spinner.hide();
        this.add(`Erro ao reagendar: ${e.error.detail}`, 'danger');
      }
    });
  }

  reagendar(myModal: any) {
    this.spinner.show();

    this.dados = new Pedido();
    this.dados = Object.assign({}, this.reagendamentoForm.value);
    this.dados.id = this.pedidoId;

    this._service.reagendar(this.dados).subscribe({
      next: (registro: Pedido) => {
        myModal.hide()
        if (this.isShowLimpar)
        this.pesquisar();
      else
        this.getDados();
        this.add('Pedido Reagendado', 'success');
      }, error: (e: any) => {
        myModal.hide();
        this.spinner.hide();
        this.add(`Erro ao reagendar: ${e.error.detail}`, 'danger');
      }
    });
  }

  validation() {
    this.registerForm = this.fb.group({
      cpfCnpj: [''],
      nomeCliente: [''],
      segmento: [''],
      dataAgendamento: [''],
      dataAgendamentoFim: [''],
      turno: [''],

      statusVenda: [''],
      bairro: [''],
      cidade: [''],

      statusVendaDescricao: [''],
      segmentoDescricao: [''],
      tipoVendaDescricao: [''],
      funcionarioId: ['']
    });

    this.reagendamentoForm = this.fb.group({

      dataAgendamento: [''],
      turno: ['']

    });

    this.tarefaForm = this.fb.group({
      statusVenda: [''],
      observacao: ['']
    });

  }


  getTelefone(cpf: string)
  {
    this.spinner.show();
    this._service.pesquisarTelefone(cpf).subscribe({
      next: (_list: ClienteTelefone[]) => {
        this.telefone = _list;
        this.myModalTelefone.show();
        this.spinner.hide();

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }
    });

  }

  add(msg: string, tipo: string): void {
    //danger, success, info, warning
    this.alerts.push({
      type: tipo,
      msg: msg,
      timeout: 5000
    });
    console.log(this.alerts);
  }

  onClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }
}
