export class PedidoIQ {

  constructor() { }

  id: number;
  pedidoId: number;
  descricao: string;
  dataVencimento: string;
  dataPagamento: string;
  dataPrevisaoPagamento: string;
  statusIQ: number;
  statusIQDescricao: string;
  diasAberto: number;
  valorFatura: number;
  bolProvavelChurn: boolean;
  contatoRealizado: boolean;
  dataContato: string;
  bolFPD:boolean;
  observacao: string;
  rastro: string[];
}
