import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from '../_utils/constants';
import { Geral } from '../_models/geral';
import { Endereco } from '../_models/endereco';
import { take } from 'rxjs/operators';
import { DadosReceita } from '../_models/dadosReceita';

@Injectable({
  providedIn: 'root'
})

export class GeralService {

  baseURL =  Constants.URL_BASE+'geral';

  constructor(private http: HttpClient) { }

  getCombo(option: string): Observable<Geral[]> {
    return this.http.get<Geral[]>(`${this.baseURL}/${option}`);
  }

  getOperadoraByUser(): Observable<Geral[]> {
    return this.http.get<Geral[]>(`${this.baseURL}/OperadoraByUser`);
  }

  getTipoVendaByOperadora(operadora: number): Observable<Geral[]> {
    return this.http.get<Geral[]>(`${this.baseURL}/TipoVendaByOperadora?operadora=${operadora}`);
  }


  getFuncao(setorId: number): Observable<Geral[]> {
    return this.http.get<Geral[]>(`${this.baseURL}/funcao?idSetor=${setorId}`);
  }

  getEndereco(cep: string): Observable<Endereco> {

    var dados = cep.replaceAll('.', '').replaceAll('-', '');
    return this.http.get<Endereco>(`${this.baseURL}/getendereco?cep=${dados}`)
      .pipe(take(1));
  }

  getCnpj(cnpj: string): Observable<DadosReceita> {

    var dados = cnpj.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '');
    return this.http.get<DadosReceita>(`${this.baseURL}/GetCNPJ?cnpj=${dados}`)
      .pipe(take(1));
  }
}
