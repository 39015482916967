import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaginatedResult } from '../_models/pagination';
import { map, take } from 'rxjs/operators';
import { Constants } from '../_utils/constants';
import { Plano } from '../_models/plano';
import { Produto } from '../_models/produto';

@Injectable({
  providedIn: 'root'
})

export class PlanoService {

  baseURL =  Constants.URL_BASE+'plano';

  constructor(private http: HttpClient) { }


  public getDados(){
    return this.http.get<Plano[]>(this.baseURL);  }

  add(dados: Plano) {
    return this.http.post(this.baseURL, dados);
  }

  update(dados: Plano) {
    return this.http.post(this.baseURL, dados);
  }
  delete(dados: Plano) {
    return this.http.post(`${this.baseURL}/delete`, dados);
  }

  public getProdutoById(id: number): Observable<Produto[]> {
    return this.http.get<Produto[]>(`${this.baseURL}/produtos?id=${id}`).pipe(take(1));
  }

}
