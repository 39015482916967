import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { IconSetService } from '@coreui/icons-angular';
import { freeSet } from '@coreui/icons';
import { User } from './_models/user';
import { AuthService } from './_service/auth.service';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  templateUrl: './app.component.html',
  providers: [IconSetService],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    public iconSet: IconSetService,
    public authService: AuthService
  ) {
    // iconSet singleton
    iconSet.icons = { ...freeSet };
  }

  ngOnInit() {
    let user: User;

    if(localStorage.getItem('user'))
    {
      user = JSON.parse(localStorage.getItem('user' ?? '{}'));
    }
    else {
      user = null;
    }
    if(user)
    this.authService.setCurrentUser(user);
  }
}
