import { Component, OnInit, ViewChild, } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Funcao } from '../../_models/funcao';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { CheckListExeService } from '../../_service/checkListExe.service';
import { CheckList } from '../../_models/checkList';
import { CheckListExe } from '../../_models/checkListExe';
import { CheckListService } from '../../_service/checkList.service';
import { CheckListExeItem } from '../../_models/checkListExeItem';
import { Funcionario } from '../../_models/funcionario';
import { FuncionarioService } from '../../_service/funcionario.service';


@Component({
  templateUrl: 'checkListExe.component.html'
})


export class CheckListExeComponent implements OnInit {
  alerts: any[];
  resp: any[];
  listfuncionario: Funcionario[];
  listCheckList: CheckList[];
  listCheckListItem: CheckListExeItem[];
  ItemSalvar: CheckListExeItem[];
  item: CheckListExe;
  itemList: CheckListExe[];
  descricao: string;
  bodyDeletar = '';
  checkListSel: any;
  checkListSelDescricao = '';
  registerForm: FormGroup;
  isShow: boolean = false;

  obs: string;

  constructor(private _serviceCheckList: CheckListService, private _service: CheckListExeService,
    private _serviceFuncionario: FuncionarioService, private fb: FormBuilder, public router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.getCheckList();
    this.alerts = new Array();
    window.scrollTo(0, 0);
  }

  voltar() {
    this.router.navigate(['/config']);
  }

  getCheckList() {
    this._serviceCheckList.getAll().subscribe({
      next: (_list: CheckList[]) => {
        this.listCheckList = _list;

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar Carregar: ${error}`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  listar() {
    if (this.checkListSel == null) {
      this.add(`Selecione um ChekList`, 'danger');
      return;
    }


    this.spinner.show();
    let id = this.checkListSel;
    this._service.getAll(id).subscribe({
      next: (_list: CheckListExe[]) => {
        console.log(_list);
        this.itemList = _list;
        this.checkListSelDescricao = '';
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carregar dados`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  incluir() {
    if (this.checkListSel == null) {
      this.add(`Selecione um ChekList`, 'danger');
      return;
    }

    this.listCheckList.forEach((currentValue, index) => {
      if (currentValue.id == this.checkListSel) {
        this.checkListSelDescricao = currentValue.descricao;
      }
    });
    this.getCheckListItem();
    this.isShow = true;
  }

  getCheckListItem() {

    this._serviceFuncionario.getFuncionarioAll().subscribe({
      next: (_list: Funcionario[]) => {
        this.listfuncionario = _list;
        //Itens
        this._service.getItens(this.checkListSel).subscribe({
          next: (_list: CheckListExeItem[]) => {
            this.listCheckListItem = _list;
            this.ItemSalvar = new Array();
          }, error: (error: any) => {
            this.spinner.hide();
            this.add(`Erro ao tentar Carregar: ${error}`, 'danger');
          }, complete: () => this.spinner.hide()
        });



      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar Carregar Funcionarios`, 'danger');
      }, complete: () => this.spinner.hide()
    });




  }

  visualizar(itemCK: CheckListExe) {

    /*   if (this.setorSel == null) {
         this.add(`Selecione um SETOR`, 'danger');
         return;
       }

       this.listCheckList.forEach((currentValue, index) => {
         if (currentValue.id == this.setorSel) {
           this.setorSelDescricao = currentValue.descricao;
         }

       });
       this.modoSalvar = 'put';
       this.openModal();
       this.funcao = Object.assign({}, funcao);
       this.registerForm.patchValue(this.funcao);*/
  }

  desconsiderar(funcionarioId: number) {
    this.spinner.show();
    this.listfuncionario = this.listfuncionario.filter(obj => obj.id !== funcionarioId);
    setTimeout(() => {
      this.spinner.hide();
    }, 1000)
  }
  registrar(funcionarioId: number) {
    this.desconsiderar(funcionarioId);
  }

  IncluirResposta(item: number, funcionario: number, resp: any) {


    const value = resp.target.value;

    var r = this.listCheckListItem.filter(obj => obj.funcionarioId == funcionario && obj.checkListItemId == item)[0];
    r.resposta = value;

    var p = this.ItemSalvar.filter(obj => obj.funcionarioId == funcionario && obj.checkListItemId == item)[0];
    if (!p)
      this.ItemSalvar.push(r);
    else
      p.resposta = value;
  }

  salvar() {

      this.spinner.show();

      this.item.checkListId = parseInt(this.checkListSel);
      this.item.observacao = this.obs;
      this.item.item = this.ItemSalvar;

      console.log(this.item);

      this._service.add(this.item).subscribe({
        next: (novoRegistro: CheckListExe) => {
          this.listar();
          this.add('Registro Salvo!', 'success');
        }, error: (error: any) => {
          this.spinner.hide();
          this.add(`Erro ao tentar Carregar: ${error}`, 'danger');
        }, complete: () => this.spinner.hide()
      });
    }

  refresh() {
    this.registerForm.reset();
    this.isShow = false;
    this.getCheckList();
    this.listar();
  }

  validation() {
    this.registerForm = this.fb.group({
      descricao: ['', [Validators.required]],
      funcaoPrincipal: [false]
    });
  }

  excluir(funcao: Funcao) {
    /*  this.confirm.show();
      this.funcao = funcao;
      this.bodyDeletar = `Tem certeza que deseja excluir o Registro: ${funcao.descricao}, Código: ${funcao.id}`;*/
  }

  confirmeDelete() {
    /*this._service.delete(this.funcao).subscribe({
      next:() => {
        this.confirm.hide();
        this.listar();
        this.add('Registro deletado!','success');
      }, error: (error: any) =>
      {
        this.spinner.hide();
        this.add(`Erro ao tentar Deletar: ${error}`,'danger');
      }, complete: () => this.spinner.hide()
    });
*/
  }

  add(msg: string, tipo: string): void {
    //danger, success, info, warning
    this.alerts.push({
      type: tipo,
      msg: msg,
      timeout: 5000
    });
    console.log(this.alerts);
  }

  onClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }



}
