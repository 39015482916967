import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { Funcionario } from '../../_models/funcionario';
import { FuncionarioService } from '../../_service/funcionario.service';
import { Empresa } from '../../_models/empresa';
import { Constants } from '../../_utils/constants';
import { Endereco } from '../../_models/endereco';
import { GeralService } from '../../_service/geral.service';
import { EmpresaService } from '../../_service/empresa.service';
import { Geral } from '../../_models/geral';
import { FuncaoService } from '../../_service/funcao.service';
import { ModalDirective } from 'ngx-bootstrap/modal';


@Component({
  templateUrl: 'funcionario.component.html'
})


export class FuncionarioComponent implements OnInit {
  alerts: any[];
  empresa: Empresa;

  pag: Number = 1;
  contador: Number = 10;

  demissaoId: number;

  mask_cpf = Constants.CPF_FMT;
  mask_data = Constants.DATE_FMT;
  mask_telefone = Constants.TELEFONE_FMT;
  mask_celular = Constants.CELULAR_FMT;
  mask_cep = Constants.CEP_FMT;
  mask_hora = Constants.TIME_FMT;

  selectList: Geral[];

  setorSel = '';
  setorIdSel = 0;

  funcaoSel = '';
  funcaoIdSel = 0;

  operadoraSel = '';
  operadorArray: any = [];
  operadoraId: any[] = [];
  operadoraList: any[] = [];

  contratacaoSel = '';
  contratacaoIdSel = 0;

  isShow: boolean = false;
  isShowOperadora: boolean = false;

  listFiltrados: Funcionario[];
  list: Funcionario[];
  dados: Funcionario;

  registerForm: FormGroup;
  demissaoForm: FormGroup;
  bodyDeletar = '';
  modoSalvar = '';
  _filtroLista = '';
  modalTitle = '';

  config = {

    displayKey: 'name',
    search: false,
    height: 'auto',
    placeholder: 'Selecione',
    limitTo: 0,
    moreText: 'selecionado(s)',
    noResultsFound: 'Nenhum registro localizado',
    inputDirection: 'ltr'
  }


  @ViewChild('myModal') public myModal: ModalDirective;
  @ViewChild('myChangeModal') public myChangeModal: ModalDirective;


  constructor(private _service: FuncionarioService, private fb: FormBuilder, public router: Router, private spinner: NgxSpinnerService, private _serviceFuncao: FuncaoService,
    private _serviceGeral: GeralService, private _serviceEmpresa: EmpresaService) { }

  get filtroLista(): string {
    return this._filtroLista;
  }
  set filtroLista(value: string) {
    this._filtroLista = value;
    this.listFiltrados = this.filtroLista ? this.filtrarDados(this.filtroLista) : this.list;
  }

  async ngOnInit(): Promise<void> {
    this.spinner.show();
    this.validation();
    this.getFuncionarios();
    this.alerts = new Array();
    window.scrollTo(0, 0);

  }

  voltar() {
    this.router.navigate(['/config']);
  }

  filtrarDados(filtrarPor: string): Funcionario[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    return this.list.filter(
      d => d.nomeAbreviado.toLocaleLowerCase().indexOf(filtrarPor) !== -1
    );
  }


  getFuncionarios() {
    this.spinner.show();

    this._service.getFuncionarioAll().subscribe({
      next: (_list: Funcionario[]) => {

        this.list = _list;
        this.listFiltrados = this.list;
        this.spinner.hide();
      }, error: (e: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar Carregar: ${e.error.message}`, 'danger');
      }
    });
  }

  getEmpresa() {
    this.spinner.show();
    this._serviceEmpresa.getEmpresa().subscribe({
      next: (_empresa: Empresa) => {
        this.empresa = _empresa;
        this.spinner.hide();

      }, error: (e: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar Carregar dados: : ${e.error.message}`, 'danger');
      }
    });
  }

  getEndereco(cep: string) {
    if (cep == "")
      return;

    this.spinner.show();
    this._serviceGeral.getEndereco(cep).subscribe({
      next: (_endereco: Endereco) => {

        this.dados.logradouro = _endereco.logradouro;
        this.dados.complemento = _endereco.complemento;
        this.dados.bairro = _endereco.bairro;
        this.dados.cidade = _endereco.localidade;
        this.dados.estado = _endereco.estado;
        this.dados.cep = _endereco.cep;
        this.dados.numero = '';

        this.registerForm.patchValue(this.dados);
        this.spinner.hide();
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar Carregar endereço`, 'danger');
      }
    });
  }


  refresh() {

    this.spinner.show();
    this.registerForm.reset();
    this.isShow = false;
    this.getFuncionarios();
    this.spinner.hide();

  }

  incluir() {
    this.dados = new Funcionario();
    this.modoSalvar = 'post';
    this.registerForm.reset();
    this.isShow = true;
    this.setorSel = "Selecione";
    this.funcaoSel = "Selecione";
    this.contratacaoSel = "Selecione";
    this.operadoraSel = "Selecione";



    this.funcaoIdSel = 0;
    this.contratacaoIdSel = 0;
    this.setorIdSel = 0;

    this.getEmpresa();

  }

  editar(item: Funcionario) {

    this.modoSalvar = 'put';
    this.spinner.show();
    this._service.getFuncionarioById(item.id).subscribe({
      next: (_funcionario: Funcionario) => {

        this.dados = Object.assign({}, _funcionario);
        this.registerForm.patchValue(this.dados);

        this.funcaoIdSel = this.dados.setorFuncaoId;
        this.contratacaoIdSel = this.dados.contratacaoId;
        this.setorIdSel = this.dados.setorId;

        this.setorSel = _funcionario.setor == "" ? "Selecione" : _funcionario.setor;
        this.funcaoSel = _funcionario.funcao == "" ? "Selecione" : _funcionario.funcao;
        this.contratacaoSel = _funcionario.contratacao == "" ? "Selecione" : _funcionario.contratacao;
        this.operadoraSel = _funcionario.operadoras == "" ? "Selecione" : _funcionario.operadoras;

        this.isShow = true;
        this.getEmpresa();

      }, error: (e: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar Carregar dados: : ${e.error.message}`, 'danger');
      }
    });




  }

  criaUsuario(item: Funcionario) {
    this.spinner.show();
    this._service.addUser(item).subscribe({
      next: (registro: Funcionario) => {
        this.refresh();
        this.add('Usuário Criado!', 'success');
      }, error: (e: any) => {
        this.spinner.hide();
        this.add(`Erro ao criar usuário: ${e.error.detail}`, 'danger');
      }
    });
  }

  changeDados(item: number) {
    this.spinner.show();
    this.isShowOperadora = false;
    switch (item) {
      case 1:
        {
          this.modalTitle = "Setor";

          this._serviceGeral.getCombo("Setor").subscribe({
            next: (_listSetor: Geral[]) => {
              this.selectList = _listSetor;
              this.myChangeModal.show();
              this.spinner.hide();
            }, error: (error: any) => {

              this.spinner.hide();
              this.add(`Erro ao tentar carrgar Setores`, 'danger');
            }
          });


          break;
        }
      case 2:
        {
          if (this.setorIdSel == 0) {
            this.spinner.hide();
            this.add(`Selecione um Setor.`, 'danger');

          }
          else {
            this.modalTitle = "Função";
            this._serviceGeral.getFuncao(this.setorIdSel).subscribe({

              next: (_listfuncao: Geral[]) => {
                this.selectList = _listfuncao;
                this.myChangeModal.show();
                this.spinner.hide();
              }, error: (error: any) => {

                this.spinner.hide();
                this.add(`Erro ao tentar carrgar Função`, 'danger');
              }
            });
          }
          break;
        }
      case 3:
        {
          this.modalTitle = "Contratação";
          this._serviceGeral.getCombo("Contratacao").subscribe({
            next: (_listContratacao: Geral[]) => {
              this.selectList = _listContratacao;
              this.myChangeModal.show();
              this.spinner.hide();

            }, error: (error: any) => {
              this.spinner.hide();
              this.add(`Erro ao tentar carrgar Tipo de Contratação`, 'danger');
            }
          });


          break;
        }
      case 4:
        {
          this.modalTitle = "Operadoras";
          this.operadorArray = [];
          this.operadoraList = [];
          this._serviceGeral.getCombo("Operadora").subscribe({
            next: (_listOperadora: Geral[]) => {
              _listOperadora.forEach((currentValue, index) => {
                this.operadoraList.push(currentValue.id + "-" + currentValue.descricao);
              });
              this.myChangeModal.show();
              this.operadoraSel = ""
              this.isShowOperadora = true;
              this.spinner.hide();
            }, error: (error: any) => {

              this.spinner.hide();
              this.add(`Erro ao tentar carrgar Setores`, 'danger');
            }
          });

          break;
        }
      default:
        break;
    }


  }

  changeSelect(valor: any) {

if(valor == 0 && this.isShowOperadora)
this.operadoraSel = this.operadorArray.length > 0 ? this.operadorArray.join(",") :  "Selecione";
else
  {  switch (this.modalTitle) {
      case "Setor":
        {
          this.setorIdSel = valor.target.value;
          this.setorSel = valor.target.selectedOptions[0].innerText;

          this.funcaoIdSel =  0;
          this.funcaoSel = "Selecione";
          break;
        }
      case "Função":
        {
          this.funcaoIdSel =  valor.target.value;
          this.funcaoSel = valor.target.selectedOptions[0].innerText;
          break;
        }
      case "Contratação":
        {
          this.contratacaoIdSel =  valor.target.value;
          this.contratacaoSel = valor.target.selectedOptions[0].innerText;
          break;
        }

      default:
        break;
    }
  }

    this.myChangeModal.hide();
}

  resetSelect() {

    switch (this.modalTitle) {
      case "Setor":
        {
          this.setorIdSel = this.dados.setorId;
          break;
        }
      case "Função":
        {
          this.funcaoIdSel = this.dados.setorFuncaoId;
          break;
        }
      case "Contratação":
        {
          this.contratacaoIdSel = this.dados.contratacaoId;
          break;
        }
      case "Operadoras":
        {

          break;
        }
      default:
        break;
    }

    this.myChangeModal.hide();
  }



  openModal(item: Funcionario) {
    this.demissaoForm.reset();
    this.myModal.show();
    this.demissaoId = item.id;
  }

  demissao(myModal: any) {
    this.spinner.show();

    this.dados = new Funcionario();
    this.dados = Object.assign({}, this.demissaoForm.value);
    this.dados.id = this.demissaoId;


    this._service.setDemissao(this.dados).subscribe({
      next: (registro: Funcionario) => {
        myModal.hide()
        this.refresh();
        this.add('Demissão registrada', 'success');
      }, error: (e: any) => {
        this.spinner.hide();
        this.add(`Erro ao criar usuário: ${e.error.detail}`, 'danger');
      }
    });
  }


  salvar() {
    if (this.funcaoIdSel == 0 || this.setorIdSel == 0 || this.contratacaoIdSel == 0 || this.operadoraSel == "") {
      this.add(`Preencha todos os campos obrigatórios para salvar o registro`, 'danger');
      return;
    }


    if (this.registerForm.valid) {
      this.spinner.show();
      if (this.modoSalvar === 'post') {

        this.dados = Object.assign({}, this.registerForm.value);
        this.dados.empresaId = this.empresa.id;


        this.dados.bolSupervisor ??= false;
        this.dados.bolVendendor ??= false;
        this.dados.cadastroVenda ??= false;
        this.dados.notificacaoVenda ??= false;
        this.dados.jornadaFlexivel ??= false;

        this.dados.setorFuncaoId = this.funcaoIdSel;
        this.dados.setorId = this.setorIdSel;
        this.dados.contratacaoId = this.contratacaoIdSel;
        this.dados.operadoras = this.operadoraSel;

        this._service.add(this.dados).subscribe({
          next: (registro: Funcionario) => {
            this.refresh();
            this.add('Registro Salvo!', 'success');
          }, error: (e: any) => {
            this.spinner.hide();
            this.add(`Erro ao tentar Carregar: ${e.error.detail}`, 'danger');
          }
        });
      } else {
        this.dados = Object.assign({ id: this.dados.id }, this.registerForm.value);

        this.dados.empresaId = this.empresa.id;
        this.dados.bolSupervisor ??= false;
        this.dados.bolVendendor ??= false;
        this.dados.cadastroVenda ??= false;
        this.dados.notificacaoVenda ??= false;
        this.dados.jornadaFlexivel ??= false;

        this.dados.setorFuncaoId = this.funcaoIdSel;
        this.dados.setorId = this.setorIdSel;
        this.dados.contratacaoId = this.contratacaoIdSel;
        this.dados.operadoras = this.operadoraSel;

        this._service.update(this.dados).subscribe({
          next: () => {

            this.refresh();
            this.add('Registro Editado!', 'success');
          }, error: (e: any) => {
            this.spinner.hide();
            this.add(`Erro ao tentar Carregar: ${e.error.detail}`, 'danger');
          }
        });
      }
    }
  }

  validation() {
    this.registerForm = this.fb.group({
      nome: ['', [Validators.required]],
      nomeAbreviado: ['', [Validators.required]],
      cpf: ['', [Validators.required]],
      rg: [''],
      email: ['', [Validators.required]],
      dataNascimentoResponse: [''],
      observacao: [''],
      telefone: [''],
      celular: ['', [Validators.required]],
      logradouro: ['', [Validators.required]],
      numero: ['', [Validators.required]],
      complemento: [''],
      bairro: ['', [Validators.required]],
      cidade: ['', [Validators.required]],
      estado: ['', [Validators.required]],
      cep: ['', [Validators.required]],
      dataAdmissaoResponse: ['', [Validators.required]],
      bolSupervisor: [''],
      bolVendendor: [''],
      notificacaoVenda: [''],
      cadastroVenda: [''],
      setorFuncaoId: [''],
      contratacaoId: [''],
      jornadaFlexivel: [''],
      jornadaInicio: [''],
      jornadaFinal: [''],
      jornadaDescanso: [''],
    });

    this.demissaoForm = this.fb.group({
      dataDemissaoResponse: ['', [Validators.required]],
    });
  }

  resetUsuario(item: Funcionario) {
    this.spinner.show();
    this._service.resetUser(item).subscribe({
      next: (registro: Funcionario) => {
        this.refresh();
        this.add('Usuário Alterado!', 'success');
      }, error: (e: any) => {
        this.spinner.hide();
        this.add(`Erro ao criar usuário: ${e.error.detail}`, 'danger');
      }
    });
  }

  excluir(item: Funcionario) {
    this.dados = item;
    this.bodyDeletar = `Tem certeza que deseja excluir o Registro: ${item.nomeAbreviado}, Código: ${item.cpf}`;
  }

  confirmeDelete() {
    this._service.delete(this.dados).subscribe({
      next: () => {
        //this.confirm.hide();
        this.getFuncionarios();
        this.add('Registro deletado!', 'success');
      }, error: (e: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar Deletar: ${e.error.detail}`, 'danger');
      }, complete: () => this.spinner.hide()
    });

  }

  add(msg: string, tipo: string): void {
    window.scrollTo(0, 0);
    //danger, success, info, warning
    this.alerts.push({
      type: tipo,
      msg: msg,
      timeout: 5000
    });
  }

  onClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }



}
