export class Constants {


  static readonly DATE_FMT = '00/00/0000';
  static readonly DATE_TIME_FMT = '00/00/0000 00:00';
  static readonly TIME_FMT = `00:00`;
  static readonly CNPJ_FMT = '00.000.000/0000-00';
  static readonly CPF_FMT = '000.000.000-00';
  static readonly CELULAR_FMT = '(00) 000000000';
  static readonly TELEFONE_FMT = '(00) 00000000';
  static readonly CEP_FMT = '00.000-000';


  static readonly VERSAO = 'ap24.1.0.1304';

 //static readonly URL_BASE = 'http://localhost:5000/api/';
 static readonly URL_BASE = 'https://api.apicest.com.br/api/';


  static readonly EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  static readonly EXCEL_EXTENSION = '.xlsx';


  static readonly imgOI = '../../../assets/img/brand/4.png';
  static readonly imgCLARO = '../../../assets/img/brand/2.jpg';

}
