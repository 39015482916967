import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { Pedido } from '../../_models/pedido';
import { PedidoService } from '../../_service/pedido.service';
import { FilaTrabalho } from '../../_models/filaTrabalho';
import {Subscription, timer} from 'rxjs';
import { map } from 'rxjs/operators';


@Component({
  templateUrl: 'filaTrabalho.component.html'
})

export class FilaTrabalhoComponent implements OnInit {


  alerts: any[];


  listFiltrados: FilaTrabalho[];
  list: FilaTrabalho[];

  dados: Pedido;

  timerSubscription: Subscription;


  constructor(private _service: PedidoService,  public router: Router, private spinner: NgxSpinnerService) { }



  ngOnInit(): void {
    this.timerSubscription = timer(0, 60000).pipe(
      map(() => {
        this.spinner.show();
        this.getDados();
        this.alerts = new Array();
        window.scrollTo(0, 0);
      })
    ).subscribe();


  }

  ngOnDestroy(): void {
  this.timerSubscription.unsubscribe();
}

  voltar() {
    this.router.navigate(['/dashboard']);
  }
  getDados() {
    this._service.getFilaTrabalho().subscribe({
      next: (_list: FilaTrabalho[]) => {
        this.list = _list;
        this.listFiltrados = this.list;

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }


  add(msg: string, tipo: string): void {
    //danger, success, info, warning
    this.alerts.push({
      type: tipo,
      msg: msg,
      timeout: 5000
    });
    console.log(this.alerts);
  }

  onClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }



}
