import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from '../_utils/constants';
import { Pedido } from '../_models/pedido';
import { PedidoAuditoria } from '../_models/pedidoAuditoria';
import { FilaTrabalho } from '../_models/filaTrabalho';
import { map } from 'rxjs/operators';
import { PedidoIQ } from '../_models/pedidoIQ';
import { PedidoIndicacao } from '../_models/pedidoIndicacao';
import { ClienteTelefone } from '../_models/clienteTelefone';
import { PedidoPosVenda } from '../_models/pedidoPosVenda';

@Injectable({
  providedIn: 'root'
})
export class PedidoService {

  baseURL = Constants.URL_BASE + 'pedido';

  constructor(private http: HttpClient) { }

  getDados() {
    return this.http.get<Pedido[]>(this.baseURL);
  }
  getbyStatus(status: string) {
    return this.http.get<Pedido[]>(`${this.baseURL}/status?status=${status}`);
  }

  pesquisarTelefone(cpf: string) {
    return this.http.get<ClienteTelefone[]>(`${this.baseURL}/pesquisarTelefone?cpf=`+ cpf);
  }

  getbyAuditoria() {
    return this.http.get<Pedido[]>(`${this.baseURL}/byauditoria`);
  }
  getbyPendenteBO() {
    return this.http.get<Pedido[]>(`${this.baseURL}/bypendenteBo`);
  }
  getbyAprovada() {
    return this.http.get<Pedido[]>(`${this.baseURL}/byaprovada`);
  }
  getbyNegada(dias: number) {
    return this.http.get<Pedido[]>(`${this.baseURL}/bynegada?dias=`+dias);
  }
  getPreVenda() {
    return this.http.get<Pedido[]>(`${this.baseURL}/prevenda`);
  }
  getOSGerada() {
    return this.http.get<Pedido[]>(`${this.baseURL}/osgerada`);
  }
  getFilaTrabalho() {
    return this.http.get<FilaTrabalho[]>(`${this.baseURL}/filatrabalho`);
  }

  enviarAuditoria(dados: Pedido) {
    return this.http.post(`${this.baseURL}/EnviarAuditoria`, dados);
  }

  salvarIndicacao(dados: PedidoIndicacao) {
    return this.http.post(`${this.baseURL}/salvarIndicacao`, dados);
  }


  getbyResponsavel() {
    return this.http.get<Pedido[]>(`${this.baseURL}/byreponsavel`);
  }

  liberarPedido(dados: Pedido) {
    return this.http.post(`${this.baseURL}/LiberarPedido`, dados);
  }

  updateResponsavel(dados: Pedido) {
    return this.http.post(`${this.baseURL}/responsavel`, dados);
  }

  updateSemContato(dados: Pedido) {
    return this.http.post(`${this.baseURL}/semContato`, dados);
  }
  salvarAuditoria(dados: PedidoAuditoria) {
    return this.http.post(`${this.baseURL}/auditoria`, dados);
  }
  gerarOS(dados: Pedido) {
    return this.http.post(`${this.baseURL}/gerarOS`, dados);
  }

  liberarPreVenda(dados: Pedido) {
    return this.http.post(`${this.baseURL}/liberarpre`, dados);
  }

  cancelar(dados: Pedido) {
    return this.http.post(`${this.baseURL}/cancelar`, dados);
  }


  cancelarTodos() {
    return this.http.get(`${this.baseURL}/cancelarTodos`);
  }
  pendenciar(dados: Pedido) {
    return this.http.post(`${this.baseURL}/pendenciar`, dados);
  }
  pesquisar(dados: Pedido) {
    return this.http.post<Pedido[]>(`${this.baseURL}/pesquisa`, dados);
  }

  getPedido(id: string) {
    return this.http.get<Pedido>(`${this.baseURL}/pedido?id=${id}`);
  }

  trocarVendedor(dados: Pedido) {
    return this.http.post(`${this.baseURL}/trocarVendedor`, dados);
  }

  downloadFile(dados: Pedido) {

    return this.http.post(this.baseURL + '/export', dados , {responseType: 'blob' as 'blob'}).pipe(
      map((x) => {
        return x;
    })
    );
  }

  //Agendamento
  getAgendamento() {
    return this.http.get<Pedido[]>(`${this.baseURL}/agendamento`);
  }

  pesquisarAgendamento(dados: Pedido) {
    return this.http.post<Pedido[]>(`${this.baseURL}/pesquisarAgendamento`, dados);
  }

  confirmarAgendamento(dados: Pedido) {
    return this.http.post(`${this.baseURL}/confirmarAgendamento`, dados);
  }

  reagendar(dados: Pedido) {
    return this.http.post(`${this.baseURL}/reagendar`, dados);
  }

  registrarTarefa(dados: Pedido) {
    return this.http.post(`${this.baseURL}/registrarTarefa`, dados);
  }

  downloadAgendamento(dados: Pedido) {

    return this.http.post(this.baseURL + '/exportAgendamento', dados , {responseType: 'blob' as 'blob'}).pipe(
      map((x) => {
        return x;
    })
    );
  }


  //FDPIQ

  getFpdIq() {
    return this.http.get<Pedido[]>(`${this.baseURL}/pedidoIq`);
  }

  getPedidosBySafra(id: string, tipoPesquisa: number) {
    return this.http.get<Pedido[]>(`${this.baseURL}/getPedidosBySafra?id=${id}&tipoPesquisa=${tipoPesquisa}`);
  }
  getFpdIqDetalhe(id: string) {
    return this.http.get<PedidoIQ[]>(`${this.baseURL}/Iq?id=${id}`, );
  }
  pesquisarIq(dados: Pedido) {
    return this.http.post<Pedido[]>(`${this.baseURL}/pesquisarIq`, dados);
  }

  AtualizaIQ(dados: Pedido) {
    return this.http.post(`${this.baseURL}/AtualizaIQ`, dados);
  }

  getDebitos() {
    return this.http.get<Pedido[]>(`${this.baseURL}/debitosAberto`);
  }

  downloadFileIq(dados: Pedido) {

    return this.http.post(this.baseURL + '/exportIQ', dados , {responseType: 'blob' as 'blob'}).pipe(
      map((x) => {
        return x;
    })
    );
  }

  getPedidoIQRasdtro(id:  number) {
    return this.http.get<Pedido[]>(`${this.baseURL}/historico?id=${id}`);
  }

  //Pos Venda
  getPosVenda() {
    return this.http.get<PedidoPosVenda[]>(`${this.baseURL}/pedidoPosVendaList`);
  }

  getPosVendaDetalhe(id: number) {
    return this.http.get<PedidoPosVenda>(`${this.baseURL}/pedidoPosVenda?id=${id}`, );
  }

  updateSemContatoPosVenda(dados: PedidoPosVenda) {
    return this.http.post(`${this.baseURL}/semContatoPosVenda`, dados);
  }
  AtualizaPosVenda(dados: PedidoPosVenda) {
    return this.http.post(`${this.baseURL}/atualizaPosVenda`, dados);
  }

  pesquisarPosVenda(dados: PedidoPosVenda) {
    return this.http.post<PedidoPosVenda[]>(`${this.baseURL}/pesquisarPosVenda`, dados);
  }

}
