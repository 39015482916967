import { Component, OnInit, ViewChild, } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Contratacao } from '../../_models/contratacao';
import { ContratacaoService } from '../../_service/contratacao.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertComponent } from 'ngx-bootstrap/alert';


@Component({
  templateUrl: 'contratacao.component.html'
})


export class ContratacaoComponent implements OnInit {
  alerts: any[];
  list: Contratacao[];
  dados: Contratacao;
  bodyDeletar = '';
  modoSalvar = 'post';
  registerForm: FormGroup;
  @ViewChild('myModal') public myModal: ModalDirective;
  @ViewChild('confirm') public confirm: ModalDirective;

  constructor(private _service: ContratacaoService,
    private fb: FormBuilder,
    public router: Router,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.validation();
    this.getContratacao();
    this.alerts = new Array();
    window.scrollTo(0, 0);
  }

  voltar() {
    this.router.navigate(['/config']);
  }

  getContratacao() {
    this._service.getContratacao().subscribe({
      next: (_list: Contratacao[]) => {
        this.list = _list;

      }, error: (error: any) =>
      {
        this.spinner.hide();
        this.add(`Erro ao tentar Carregar: ${error}`,'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  incluir() {
    this.modoSalvar = 'post';
    this.openModal();
  }

  editar(contratacao: Contratacao) {
    this.modoSalvar = 'put';
    this.openModal();
    this.dados = Object.assign({}, contratacao);
    this.registerForm.patchValue(this.dados);
  }

  openModal() {
    this.registerForm.reset();
    this.myModal.show();
  }

  salvar(myModal: any) {
    if (this.registerForm.valid) {
      this.spinner.show();
      if (this.modoSalvar === 'post') {

        this.dados = Object.assign({}, this.registerForm.value);

        this._service.add(this.dados).subscribe({
          next: (novoRegistro: Contratacao) => {
            myModal.hide();
            this.getContratacao();
            this.add('Registro Salvo!','success');
          }, error: (error: any) =>
          {
            this.spinner.hide();
            this.add(`Erro ao tentar Carregar: ${error}`,'danger');
          }, complete: () => this.spinner.hide()
        });
      } else {
        this.dados = Object.assign({ id: this.dados.id }, this.registerForm.value);


        this._service.update(this.dados).subscribe({
          next:() => {
            myModal.hide();
            this.getContratacao();
            this.add('Registro Editado!','success');
          }, error: (error: any) =>
          {
            this.spinner.hide();
            this.add(`Erro ao tentar salvar dados`,'danger');
          }, complete: () => this.spinner.hide()
        });
      }
    }
  }

  validation() {
    this.registerForm = this.fb.group({
      descricao: ['', [Validators.required]]
    });
  }

  excluir(contratacao: Contratacao) {
    this.confirm.show();
    this.dados = contratacao;
    this.bodyDeletar = `Tem certeza que deseja excluir o Registro: ${contratacao.descricao}, Código: ${contratacao.id}`;
  }

  confirmeDelete() {
    this._service.delete(this.dados).subscribe({
      next:() => {
        this.confirm.hide();
        this.getContratacao();
        this.add('Registro deletado!','success');
      }, error: (error: any) =>
      {
        this.spinner.hide();
        this.add(`Erro ao tentar Deletar: ${error}`,'danger');
      }, complete: () => this.spinner.hide()
    });

  }

  add(msg: string, tipo: string): void {
    //danger, success, info, warning
      this.alerts.push({
      type: tipo,
      msg: msg,
      timeout: 5000
    });
    console.log(this.alerts);
  }

  onClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }


}
