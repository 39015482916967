import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { Constants } from '../../_utils/constants';
import { Produto } from '../../_models/produto';
import { ProdutoService } from '../../_service/produto.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ProdutoItem } from '../../_models/produtoItem';
import { Geral } from '../../_models/geral';
import { GeralService } from '../../_service/geral.service';

@Component({
  templateUrl: 'produto.component.html'
})


export class ProdutoComponent implements OnInit {

  @ViewChild('myModal') public myModal: ModalDirective;

  alerts: any[];
  pag: Number = 1;
  contador: Number = 10;


  isShow: boolean = false;
  listFiltrados: Produto[];
  operadoraList: Geral[];
  list: Produto[];
  dados: Produto;
  modoSalvar = 'post';

  listItem: ProdutoItem[];

  mask_cpf = Constants.CPF_FMT;
  mask_data = Constants.DATE_FMT;
  mask_telefone = Constants.TELEFONE_FMT;
  mask_celular = Constants.CELULAR_FMT;


  registerForm: FormGroup;
  bodyDeletar = '';

  descricaoItem: string = '';
  _filtroLista = '';

  constructor(private _service: ProdutoService, private _serviceGeral: GeralService, private fb: FormBuilder, public router: Router, private spinner: NgxSpinnerService) { }

  get filtroLista(): string {
    return this._filtroLista;
  }
  set filtroLista(value: string) {
    this._filtroLista = value;
    this.listFiltrados = this.filtroLista ? this.filtrarDados(this.filtroLista) : this.list;
  }

  ngOnInit(): void {
    this.spinner.show();
    this.validation();
    this.getDados();
    this.getOperadora();
    this.alerts = new Array();
    this.listItem = new Array<ProdutoItem>();
    window.scrollTo(0, 0);
  }

  voltar() {
    this.router.navigate(['/config']);
  }

  filtrarDados(filtrarPor: string): Produto[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    return this.list.filter(
      d => d.nome.toLocaleLowerCase().indexOf(filtrarPor) !== -1
    );
  }

  getDados() {
    this._service.getDados().subscribe({
      next: (_list: Produto[]) => {
        this.list = _list;
        this.listFiltrados = this.list;

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  getOperadora()
  {
    this._serviceGeral.getOperadoraByUser().subscribe({
      next: (_listOperadora: Geral[]) => {
        this.operadoraList = _listOperadora;
        this.spinner.hide();
      }, error: (error: any) => {

        this.spinner.hide();
        this.add(`Erro ao tentar carrgar Operadoras`, 'danger');
      }
    });

  }

  refresh() {

    this.spinner.show();
    this.registerForm.reset();
    this.isShow = false;
    this.getDados();

    this.spinner.hide();
  }

  incluir() {
    this.spinner.hide();
    this.registerForm.reset();
    this.isShow = true;
    this.modoSalvar = 'post';
  }

  editar(item: Produto) {
    this.spinner.show();
    this.registerForm.reset();
    this.isShow = true;

    this.modoSalvar = 'put';
    this.dados = Object.assign({}, item);
    this.registerForm.patchValue(this.dados);

    this.listItem = this.dados.item;
    this.spinner.hide();
  }

  salvar() {
    if (this.registerForm.valid) {
      this.spinner.show();
      if (this.modoSalvar === 'post') {

        this.dados = Object.assign({}, this.registerForm.value);


        this._service.add(this.dados).subscribe({
          next: (novoRegistro: Produto) => {
            this.refresh();
            this.add('Registro Salvo!', 'success');
          }, error: (error: any) => {
            this.spinner.hide();
            this.add(`Erro ao tentar carregar dados`, 'danger');
          }, complete: () => this.spinner.hide()
        });
      } else {
        this.dados = Object.assign({ id: this.dados.id }, this.registerForm.value);


        this._service.update(this.dados).subscribe({
          next: () => {
            this.refresh();
            this.add('Registro Editado!', 'success');
          }, error: (error: any) => {
            this.spinner.hide();
            this.add(`Erro ao tentar carregar dados`, 'danger');
          }, complete: () => this.spinner.hide()
        });
      }
    }
  }

  validation() {
    this.registerForm = this.fb.group({
      nome: ['', [Validators.required]],
      operadoraId: ['', [Validators.required]]
    });
  }

  excluir(item: Produto) {
    //this.confirm.show();
    this.dados = item;
    this.bodyDeletar = `Tem certeza que deseja excluir o Registro: ${item.nome}, Código: ${item.id}`;
  }

  openModal() {
    this.myModal.show();
  }
  addItem(desc :any)
  {
    this.descricaoItem = desc.target.value;

  }
  incluirItem()
  {
    this.spinner.show();

    let dadosItem = new ProdutoItem();

    dadosItem.descricao = this.descricaoItem;
    dadosItem.id = 0;
    dadosItem.produtoId = (this.dados == null || this.dados == undefined) ? 0 : this.dados.id;

    this.listItem.push(dadosItem);

    this.descricaoItem = '';

    this.myModal.hide();
    this.spinner.hide();

  }

  confirmeDelete() {
    this._service.delete(this.dados).subscribe({
      next: () => {
        //this.confirm.hide();
        this.getDados();
        this.add('Registro deletado!', 'success');
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar Deletar: ${error}`, 'danger');
      }, complete: () => this.spinner.hide()
    });

  }

  add(msg: string, tipo: string): void {
    //danger, success, info, warning
    this.alerts.push({
      type: tipo,
      msg: msg,
      timeout: 5000
    });
    console.log(this.alerts);
  }

  onClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }



}
