import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Funcionario } from '../_models/funcionario';
import { PaginatedResult } from '../_models/pagination';
import { map, take } from 'rxjs/operators';
import { Constants } from '../_utils/constants';

@Injectable({
  providedIn: 'root'
})

export class FuncionarioService {

  baseURL = Constants.URL_BASE + 'Funcionario';

  constructor(private http: HttpClient) { }


   public getFuncionarioAll() {
    return this.http.get<Funcionario[]>(this.baseURL);
  }

  getFuncionarioById(id:number) {
    return this.http.get<Funcionario>(`${this.baseURL}/getById?id=${id}`);
  }

  add(dados: Funcionario) {
    return this.http.post(this.baseURL, dados);
  }

  update(dados: Funcionario) {
    return this.http.post(this.baseURL, dados);
  }
  addUser(dados: Funcionario) {
    return this.http.post(`${ Constants.URL_BASE}user/CreateLogin`, dados);
  }

    setDemissao(dados: Funcionario) {
      return this.http.post(`${this.baseURL}/demissao`, dados);
  }

  resetUser(dados: Funcionario) {
    return this.http.post(`${ Constants.URL_BASE}user/resetLogin`, dados);
  }

  delete(dados: Funcionario) {
    return this.http.post(`${this.baseURL}/delete`, dados);
  }


}
