import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Contratacao} from '../_models/contratacao';
import { Constants } from '../_utils/constants';

@Injectable({
  providedIn: 'root'
})

export class ContratacaoService {

  baseURL =  Constants.URL_BASE+'contratacao';

  constructor(private http: HttpClient) { }

  getContratacao(): Observable<Contratacao[]> {
    return this.http.get<Contratacao[]>(this.baseURL);
  }

  add(dados: Contratacao) {
    return this.http.post(this.baseURL, dados);
  }

  update(dados: Contratacao) {
    return this.http.post(this.baseURL, dados);
  }
  delete(dados: Contratacao) {
    return this.http.post(`${this.baseURL}/delete`, dados);
  }



}
