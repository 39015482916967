import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Resultado } from '../../_models/resultado';
import { ResultadoService } from '../../_service/resultado.service';
import { Router } from '@angular/router';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Consolidado } from '../../_models/consolidado';

@Component({
  templateUrl: 'dashboard.component.html'
})
export class DashboardComponent implements OnInit {

  alerts: any[];
  list: Consolidado[];
  dados: Resultado;
  qtdAuditoria: number = 0;
  qtdAgendamento: number = 0;
  qtdTarefa: number = 0;
  data: string;
  dataTarefa: string;
  dataAgendamento: string;
  cadastro:Resultado;

  osGerada:Resultado;
  parcial:Resultado;

  isCollapsedCadastro: boolean = true;
  refreshCadastro: boolean = false;

  isCollapsedOS: boolean = true;
  refreshOS: boolean = false;

  isCollapsedParcial: boolean = true;
  refreshParcial: boolean = false;


  auditoria: Consolidado[];
  isCollapsedAuditoria: boolean = true;
  refreshAuditoria: boolean = false;

  agendamento: Consolidado[];
  isCollapsedAgendamento: boolean = true;
  refreshAgendamento: boolean = false;


  tarefa: Consolidado[];
  isCollapsedTarefa: boolean = true;
  refreshTarefa: boolean = false;

  @ViewChild('myModal') public myModal: ModalDirective;

  constructor(private _service: ResultadoService, public router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    // this.spinner.show();
    this.alerts = new Array();
    this.dados = new Resultado();
    this.cadastro = new Resultado();
    this.osGerada = new Resultado();
    this.parcial = new Resultado();

    this.data = new Date().toLocaleDateString();
    this.dataAgendamento = this.addDays(1).toLocaleDateString();
    this.dataTarefa = this.addDays(-1).toLocaleDateString();
    this.getDados();
    window.scrollTo(0, 0);
  }

  getDados() {
    this.spinner.show()
    this._service.getDadosHome().subscribe({
      next: (_dados: Resultado) => {
        this.dados = _dados;

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  expandsAuditoria(): void {

    this.auditoria = [];
    this.refreshAuditoria = true;
    this._service.getAuditoriaHome().subscribe({
      next: (_dados: Consolidado[]) => {


        this.qtdAuditoria = 0;
        _dados.forEach((currentValue, index) => {
          if (index > 0) {
            this.qtdAuditoria += currentValue.total;
            this.auditoria.push(currentValue);
          }
          else
            this.dados.pedidoAuditado = currentValue.total
        });

        this.refreshAuditoria = false;
      }, error: (error: any) => {
        this.refreshAuditoria = true;
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }
    });
  }

  expandsCadastro(): void {
    this.cadastro = new Resultado();
    this.refreshCadastro = true;

    this._service.getCadastroHome("cadastro").subscribe({
      next: (_dados: Resultado) => {
        this.dados.pedidoCadastrado = _dados.pedidoCadastrado;
        this.cadastro = _dados;
        this.refreshCadastro = false;
      }, error: (error: any) => {
        this.refreshCadastro = false;
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }
    });
  }

  expandsOS(): void {
    this.osGerada = new Resultado();
    this.refreshOS = true;

    this._service.getCadastroHome("osgerada").subscribe({
      next: (_dados: Resultado) => {
        this.dados.osGerada = _dados.pedidoCadastrado;
        this.osGerada = _dados;
        this.refreshOS = false;
      }, error: (error: any) => {
        this.refreshOS = false;
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }
    });
  }

   expandsParcial(): void {
    this.parcial = new Resultado();
    this.refreshParcial = true;

    this._service.getCadastroHome("parcial").subscribe({
      next: (_dados: Resultado) => {
        this.dados.viabilidade = _dados.pedidoCadastrado;
        this.parcial = _dados;
        this.refreshParcial = false;
      }, error: (error: any) => {
        this.refreshParcial = false;
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }
    });
  }

  expandsAgendamento(): void {

    this.agendamento = [];
    this.refreshAgendamento = true;
    this._service.getAgendamentoHome().subscribe({
      next: (_dados: Consolidado[]) => {


        this.qtdAgendamento = 0;
        _dados.forEach((currentValue, index) => {
          if (index > 0) {
            this.qtdAgendamento += currentValue.total;
            this.agendamento.push(currentValue);
          }
          else
            this.dados.agendamentoTotal = currentValue.total
        });

        this.refreshAgendamento = false;
      }, error: (error: any) => {
        this.refreshAgendamento = true;
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }
    });
  }

  expandsTarefa(): void {

    this.tarefa = [];
    this.refreshTarefa = true;
    this._service.getTarefaHome().subscribe({
      next: (_dados: Consolidado[]) => {


        this.qtdTarefa = 0;
        _dados.forEach((currentValue, index) => {
          if (index > 0) {
            this.qtdTarefa += currentValue.total;
            this.tarefa.push(currentValue);
          }
          else
            this.dados.agendamentoConcluido = currentValue.total
        });

        this.refreshTarefa = false;
      }, error: (error: any) => {
        this.refreshTarefa = true;
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }
    });
  }

  add(msg: string, tipo: string): void {
    //danger, success, info, warning
    this.alerts.push({
      type: tipo,
      msg: msg,
      timeout: 5000
    });
    console.log(this.alerts);
  }

  openModal() {
    this.spinner.show();
    this._service.getProduto().subscribe({
      next: (_list: Consolidado[]) => {
        this.list = _list;
        this.myModal.show();
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }, complete: () => this.spinner.hide()
    });

  }

  onClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }

  addDays(days: number): Date {
    var futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + days);
    return futureDate;
  }



}
