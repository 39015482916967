import { Geral } from "./geral";

export class Meta {

    constructor() { }

    id: number;

    descricao: string;
    pedidoLiquido: number;
    pedidoLiquidoTotal: number;
    pedidoLiquidoFinanceiro: number;
    pedidoLiquidoFinanceiroTotal: number;
    pedidoInstalado: number;
    pedidoInstaladoTotal: number;
    pedidoInstaladoFinanceiro: number;
    pedidoInstaladoFinanceiroTotal: number;

    dataInicial: string;
    dataFinal: string;
    qtdDias: number;
    gatilhoEmp: number;
    metaTipo: number;
    metaTipoDescrica: string;

    vendedores: Geral[];
  }
