import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Constants } from '../_utils/constants';
import { Cliente } from '../_models/cliente';
import { ClienteTelefone } from '../_models/clienteTelefone';

@Injectable({
  providedIn: 'root'
})

export class ClienteService {

  baseURL =  Constants.URL_BASE+'cliente';

  constructor(private http: HttpClient) { }



  public getDados(){
    return this.http.get<Cliente[]>(this.baseURL);
  }

  add(dados: Cliente) {
    return this.http.post(this.baseURL, dados);
  }

  update(dados: Cliente) {
    return this.http.post(this.baseURL, dados);
  }
  delete(dados: Cliente) {
    return this.http.post(`${this.baseURL}/delete`, dados);
  }

  public getById(id: number): Observable<Cliente> {
    return this.http.get<Cliente>(`${this.baseURL}/${id}`).pipe(take(1));
  }

  pesquisarTelefone(id: number) {
    return this.http.get<ClienteTelefone[]>(`${this.baseURL}/pesquisarTelefone?id=`+ id);
  }
}
