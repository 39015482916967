import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../_models/user';
import { AuthService } from '../../_service/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {

  msgErro: string = "";
  registerForm: FormGroup;
  alterForm: FormGroup;

  dados: User;
  isShow: boolean = false;

  constructor(public authService: AuthService, private fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.hide();
    this.validation();
    this.dados = new User();
    window.scrollTo(0, 0);
  }


  public login(): void {

    this.msgErro  = "";

    this.dados = Object.assign({}, this.registerForm.value);
    if (this.dados.login == "" || this.dados.password == "") {
      this.msgErro = "Login ou senha inválida";
      return;
    }
    this.spinner.show();
    this.authService.login(this.dados).subscribe(
      () => {

        var first = localStorage.getItem('first');

        if (first == "0") {
          this.spinner.hide();
          this.router.navigateByUrl("/dashboard");
        }
        else {

          this.dados.firstLogin = true;
          this.dados.nome = localStorage.getItem('nome');
          this.dados.password = "";
          this.dados.passwordComfirm = "";

          this.alterForm.patchValue(this.dados);

          this.isShow = true;
          this.spinner.hide();
        }
      }, (erro: any) => {
        this.spinner.hide();
        this.msgErro  = `Erro: ${erro.error} `;
      }

    )

  }


  alteraSenha() {
    this.msgErro  = "";
    if (this.alterForm.valid) {

      this.dados = Object.assign({}, this.alterForm.value);

      if (this.dados.password != this.dados.passwordComfirm) {
        this.msgErro  = `Senha e confirmação não confere`;
        return;
      }

      this.spinner.show();

      this.authService.alterPassword(this.dados).subscribe({
        next: () => {
          this.spinner.hide();
          localStorage.setItem('first', '0');
          this.router.navigateByUrl("/login");


        }, error: (e: any) => {
          this.spinner.hide();
          this.msgErro  = `Erro ao tentar alterar senha`;
        }
      });
    }
  }


  validation() {
    this.registerForm = this.fb.group({
      login: ['', [Validators.required]],
      password: ['', [Validators.required]],
      nome: [''],
      token: [''],
    });

    this.alterForm = this.fb.group({
      login: [''],
      password: ['', [Validators.required]],
      passwordComfirm: ['', [Validators.required]],
      nome: [''],
      token: [''],
    });
  }

}
