import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Constants } from '../_utils/constants';
import { Mensagem } from '../_models/mensagem';
import { Resultado } from '../_models/resultado';

@Injectable({
  providedIn: 'root'
})

export class MensagemService {

  baseURL = Constants.URL_BASE + 'mensagem';

  constructor(private http: HttpClient) { }


  public getDados() {
    return this.http.get<Mensagem[]>(this.baseURL);
  }

  public getNaoEnviado() {
    return this.http.get<Mensagem[]>(`${this.baseURL}/NaoEnviado`);
  }


  enviar(dados: Mensagem) {
    return this.http.post(`${this.baseURL}/enviar`, dados);
  }

  naoEnviar(dados: Mensagem) {
    return this.http.post(`${this.baseURL}/naoEnviar`, dados);
  }
  enviarTodos() {
    return this.http.get(`${this.baseURL}/EnviarTodos`);
  }
  gerarEmail(dados: Resultado) {
    return this.http.post(`${this.baseURL}/gerarEmail`, dados);
  }


}
