import { Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Empresa } from '../../_models/empresa';
import { EmpresaService } from '../../_service/empresa.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { Constants } from '../../_utils/constants';
import { Endereco } from '../../_models/endereco';
import { GeralService } from '../../_service/geral.service';


@Component({
  templateUrl: 'empresa.component.html'
})


export class EmpresaComponent implements OnInit {

  @ViewChild('cep') cepInput:ElementRef;
  alerts: any[];
  dados: Empresa;
  modoSalvar = 'post';
  registerForm: FormGroup;

  mask_cnpj = Constants.CNPJ_FMT;
  mask_data = Constants.DATE_FMT;
  mask_telefone = Constants.TELEFONE_FMT;
  mask_celular = Constants.CELULAR_FMT;
  mask_cep = Constants.CEP_FMT;


  constructor(private _service: EmpresaService,
    private _serviceGeral: GeralService,
    private fb: FormBuilder,
    public router: Router,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.validation();
    this.getEmpresa();
    this.alerts = new Array();
    window.scrollTo(0, 0);
  }

  voltar() {
    this.router.navigate(['/config']);
  }

  getEmpresa() {
    this._service.getEmpresa().subscribe({
      next: (_empresa: Empresa) => {
        this.editar(_empresa);
      }, error: (error: any) =>
      {
        this.spinner.hide();
        this.add(`Erro ao tentar Carregar dados`,'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  editar(empresa: Empresa) {
    this.modoSalvar = 'put';
    this.dados = Object.assign({}, empresa);
    this.registerForm.patchValue(this.dados);
  }

  salvar() {
    if (this.registerForm.valid) {
      this.spinner.show();
        this.dados = Object.assign({ id: this.dados.id }, this.registerForm.value);
        this._service.update(this.dados).subscribe({
          next:() => {
            window.scrollTo(0, 0);
            this.getEmpresa();
            this.add('Registro Salvo!','success');
          }, error: (error: any) =>
          {
            this.spinner.hide();
            this.add(`Erro ao tentar Carregar: ${error}`,'danger');
          }, complete: () => this.spinner.hide()
        });
    }
  }

  validation() {
    this.registerForm = this.fb.group({
      nome: ['', [Validators.required]],
      codigo: [''],
      cnpj: ['', [Validators.required]],
      telefone: ['',[Validators.required]],
      celular: ['',[Validators.required]],
      email:['',[Validators.required]],
      homePage: [''],
      nomeResponsavel: ['',[Validators.required]],
      contatoResponsavel: ['',[Validators.required]],
      emailResponsavel:['',[Validators.required]],
      logradouro: ['',[Validators.required]],
      numero: ['',[Validators.required]],
      complemento: [''],
      bairro:['',[Validators.required]],
      cidade: ['',[Validators.required]],
      estado: ['',[Validators.required]],
      cep: ['',[Validators.required]]
    });
  }


  getEndereco(cep:string) {
    if(cep == "")
    return;

    this.spinner.show();
       this._serviceGeral.getEndereco(cep).subscribe({
      next: (_endereco: Endereco) => {

        this.dados.logradouro = _endereco.logradouro;
        this.dados.complemento = _endereco.complemento;
        this.dados.bairro = _endereco.bairro;
        this.dados.cidade = _endereco.localidade;
        this.dados.estado = _endereco.estado;
        this.dados.cep = _endereco.cep;
        this.dados.numero = '';

        this.registerForm.patchValue(this.dados);

      }, error: (error: any) =>
      {
        this.spinner.hide();
        this.add(`Erro ao tentar Carregar endereço`,'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  add(msg: string, tipo: string): void {
    //danger, success, info, warning
      this.alerts.push({
      type: tipo,
      msg: msg,
      timeout: 5000
    });
    console.log(this.alerts);
  }

  onClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }


}
