import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ClienteCategoria} from '../_models/clienteCategoria';
import { Constants } from '../_utils/constants';
import { Geral } from '../_models/geral';

@Injectable({
  providedIn: 'root'
})

export class ClienteCategoriaService {

  baseURL =  Constants.URL_BASE+'ClienteCategoria';

  constructor(private http: HttpClient) { }

  getDados(): Observable<ClienteCategoria[]> {
    return this.http.get<ClienteCategoria[]>(this.baseURL);
  }

  getCategoria(): Observable<Geral[]> {
    return this.http.get<Geral[]>(`${this.baseURL}/GetClienteCategoria`);
  }

  getSegmento(): Observable<Geral[]> {
    return this.http.get<Geral[]>(`${this.baseURL}/GetSegmento`);
  }


  add(dados: ClienteCategoria) {
    return this.http.post(this.baseURL, dados);
  }

  update(dados: ClienteCategoria) {
    return this.http.post(this.baseURL, dados);
  }
  delete(dados: ClienteCategoria) {
    return this.http.post(`${this.baseURL}/delete`, dados);
  }



}
