import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { ClienteCategoria } from '../../_models/clienteCategoria';
import { ClienteCategoriaService } from '../../_service/clienteCategoria.service';


@Component({
  templateUrl: 'clienteCategoria.component.html'
})


export class ClienteCategoriaComponent implements OnInit {


  alerts: any[];
  isShow: boolean = false;
  isObrigatorio: boolean = false;
  listFiltrados: ClienteCategoria[];
  list: ClienteCategoria[];
  dados: ClienteCategoria;
  modoSalvar = 'post';

  registerForm: FormGroup;
  bodyDeletar = '';

  _filtroLista = '';

  constructor(private _service: ClienteCategoriaService, private fb: FormBuilder, public router: Router, private spinner: NgxSpinnerService) { }

  get filtroLista(): string {
    return this._filtroLista;
  }
  set filtroLista(value: string) {
    this._filtroLista = value;
    this.listFiltrados = this.filtroLista ? this.filtrarDados(this.filtroLista) : this.list;
  }

  ngOnInit(): void {
    this.spinner.show();
    this.validation();
    this.getDados();
    this.alerts = new Array();
    window.scrollTo(0, 0);
  }

  voltar() {
    this.router.navigate(['/config']);
  }

  filtrarDados(filtrarPor: string): ClienteCategoria[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    return this.list.filter(
      d => d.nome.toLocaleLowerCase().indexOf(filtrarPor) !== -1
    );
  }

  getDados() {
    this._service.getDados().subscribe({
      next: (_list: ClienteCategoria[]) => {
        this.list = _list;
        this.listFiltrados = this.list;

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  refresh() {
    this.registerForm.reset();
    this.isShow = false;
    this.getDados();
  }

  incluir() {
    this.registerForm.reset();
    this.isShow = true;
  }

  editar(item: ClienteCategoria) {
    this.modoSalvar = 'put';
    this.incluir();
    this.dados = Object.assign({}, item);

    this.isObrigatorio = item.bolVisitaObrigatorio;
    this.registerForm.patchValue(this.dados);
  }

  openModal() {
    this.registerForm.reset();
  }

  salvar() {
    if (this.registerForm.valid) {
      this.spinner.show();
      if (this.modoSalvar === 'post') {

        this.dados = Object.assign({}, this.registerForm.value);


        this.dados.bolVisitaObrigatorio == null ? this.dados.bolVisitaObrigatorio = false : this.dados.bolVisitaObrigatorio = this.dados.bolVisitaObrigatorio;
        this.dados.tempoVisitoriaObrigatoria == null ? this.dados.tempoVisitoriaObrigatoria = 0 : this.dados.tempoVisitoriaObrigatoria = this.dados.tempoVisitoriaObrigatoria;
        this.dados.tipoVisita  == null ?  this.dados.tipoVisita  = "CONTATO" : this.dados.tipoVisita  =this.dados.tipoVisita;


        this._service.add(this.dados).subscribe({
          next: (novoRegistro: ClienteCategoria) => {
            this.refresh();
            this.add('Registro Salvo!', 'success');
          }, error: (error: any) => {
            this.spinner.hide();
            this.add(`Erro ao tentar carregar dados`, 'danger');
          }, complete: () => this.spinner.hide()
        });
      } else {
        this.dados = Object.assign({ id: this.dados.id }, this.registerForm.value);


        this._service.update(this.dados).subscribe({
          next: () => {
            this.refresh();
            this.add('Registro Editado!', 'success');
          }, error: (error: any) => {
            this.spinner.hide();
            this.add(`Erro ao tentar Carregar: ${error}`, 'danger');
          }, complete: () => this.spinner.hide()
        });
      }
    }
  }

  validation() {
    this.registerForm = this.fb.group({
      nome: ['', [Validators.required]],
      bolVisitaObrigatorio: [false],
      tempoVisitoriaObrigatoria: [''],
      tipoVisita: ['']
    });
  }

  visita(resp: any) {
    this.isObrigatorio = resp.target.checked;
  }

  excluir(item: ClienteCategoria) {
    //this.confirm.show();
    this.dados = item;
    this.bodyDeletar = `Tem certeza que deseja excluir o Registro: ${item.nome}, Código: ${item.id}`;
  }

  confirmeDelete() {
    this._service.delete(this.dados).subscribe({
      next: () => {
        //this.confirm.hide();
        this.getDados();
        this.add('Registro deletado!', 'success');
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar Deletar: ${error}`, 'danger');
      }, complete: () => this.spinner.hide()
    });

  }

  add(msg: string, tipo: string): void {
    //danger, success, info, warning
    this.alerts.push({
      type: tipo,
      msg: msg,
      timeout: 5000
    });
    console.log(this.alerts);
  }

  onClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }



}
