import { Consolidado } from "./consolidado";
import { ConsolidadoFuncionario } from "./consolidadoFuncionario";
import { ConsolidadoResultado } from "./consolidadoResultado";
import { Pedido } from "./pedido";

export class Resultado {

    constructor() { }



    liquidoPeriodo: ConsolidadoResultado[];
    instaladoPeriodo : ConsolidadoResultado[];
    qtdTotalDias: number;
    tipoMeta: number;
    gatilhoEmp: number;
    totalGatilhoEmp: number;

    dataInicio: string;
    dataFinal: string;

    pedidoLiquido: number;
    pedidoInstalado: number;
    pedidoInstaladoRes: number;
    pedidoCadastrado: number;
    pedidoAuditado: number;
    osGerada: number;
    agendamentoProgramado: number;
    viabilidade: number;
    motivoCancelamento: Consolidado[];
    evolucaoLiquido: Consolidado[];
    evolucaoInstalado: Consolidado[];
    listaProduto: Consolidado[];

    agendamentoTotal: number;
    mediaAgendamento: number;
    agendamentoConcluido: number;
    agendamentoNaoRealizado: number;
    indicadorAgendamento: number;
    agendamentoConsolidado: Consolidado[];


    funcionarioConsolidado: ConsolidadoFuncionario[];
    equipeConsolidado: ConsolidadoFuncionario[];
    equipe:string;
    vendedor:string

    referencia:string;
    valorReferencia:string;
    listaPedidos: Pedido[];
    listaCancelados: Pedido[];

  }
