import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from '../_utils/constants';
import { Equipe } from '../_models/equipe';
import { Vendedor } from '../_models/vendedor';

@Injectable({
  providedIn: 'root'
})

export class EquipeService {

  baseURL =  Constants.URL_BASE+'equipe';

  constructor(private http: HttpClient) { }

  getDados(): Observable<Equipe[]> {
    return this.http.get<Equipe[]>(this.baseURL);
  }

  getAvulso(): Observable<Vendedor[]> {
    return this.http.get<Vendedor[]>(`${this.baseURL}/avulso`);
  }

  add(dados: Equipe) {
    return this.http.post(this.baseURL, dados);
  }

  update(dados: Equipe) {
    return this.http.post(this.baseURL, dados);
  }
  desativar(dados: Equipe) {
    return this.http.post(`${this.baseURL}/desativar`, dados);
  }

  addVendedor(dados: Equipe) {
    return this.http.post(`${this.baseURL}/addVendedor`, dados);
  }


  desativarVendedor(dados: Equipe) {
    return this.http.post(`${this.baseURL}/desativarVendedor`, dados);
  }



}
