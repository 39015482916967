import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { Pedido } from '../../_models/pedido';
import { PedidoService } from '../../_service/pedido.service';
import { PedidoIQ } from '../../_models/pedidoIQ';
import { GeralService } from '../../_service/geral.service';
import { Geral } from '../../_models/geral';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ClienteTelefone } from '../../_models/clienteTelefone';
import { AuthService } from '../../_service/auth.service';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { Safra } from '../../_models/safra';
import { SafraService } from '../../_service/safra.service';

@Component({
  templateUrl: 'fpdIq.component.html'
})

export class FpdIqComponent implements OnInit {

  alerts: any[];
  rastro: any[];
  pag: Number = 1;
  pedidoIQId: number;
  contador: Number = 10;
  isShow: boolean = false;
  isShowTable: boolean = false;
  isShowForm: boolean = false;
  isShowPesquisa: boolean = false;

  list: Pedido[];
  listFiltrados: Pedido[];

  vendedorList: Geral[];
  safraList: Safra[];
  statusList: Geral[];
  tipoList: Geral[];
  telefone: ClienteTelefone[] = new Array();
  statusSel1: number = 0;
  statusSel2: number = 0;
  statusSel3: number = 0;
  statusSel4: number = 0;

  dadosM1: PedidoIQ;
  dadosM2: PedidoIQ;
  dadosM3: PedidoIQ;
  dadosM4: PedidoIQ;

  pesquisaForm: FormGroup;
  pedidoForm: FormGroup;
  pedidoIQM1Form: FormGroup;
  pedidoIQM2Form: FormGroup;
  pedidoIQM3Form: FormGroup;
  pedidoIQM4Form: FormGroup;

  dados: Pedido;
  obsPedido: string = "";
  safraSelecionada: string = "";
  safraIdSelecionada: string = "";
  safraFilter: string = "";
  tipoPesquisa: number = 1;
  nomeCliente: string = "";
  vendedor: string = "";
  dataVecimento: string = "";
  valorFatura: string = "";
  dataPrevisaoPagamento: string = "";
  dadosPesquisa: Pedido;
  listIQ: PedidoIQ[]=[];
  dadosDetalhe: PedidoIQ[];

  _filtroLista = '';

  constructor(private _service: PedidoService, private _serviceSafra: SafraService, private _serviceGeral: GeralService, private fb: FormBuilder, public authService: AuthService,
    public router: Router, private spinner: NgxSpinnerService) { }




  get filtroLista(): string {
    return this._filtroLista;
  }
  set filtroLista(value: string) {
    this._filtroLista = value;
    this.listFiltrados = this.filtroLista ? this.filtrarDados(this.filtroLista) : this.list;
  }



  ngOnInit(): void {
    this.spinner.show();
    this.validation();
    this.getSafra();
    this.getStatus();
    this.alerts = new Array();
    window.scrollTo(0, 0);
    if (this.authService.tokenExpired())
      this.logout();
  }


  logout(): void {
    this.authService.logout();
    this.router.navigateByUrl("/register");
  }

  filtrarDados(filtrarPor: string): Pedido[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    return this.list.filter(
      d => d.nomeCliente.toLocaleLowerCase().indexOf(filtrarPor) !== -1
    );
  }

  voltar() {
    this.router.navigate(['/dashboard']);
  }

  voltaSafra() {
    this._filtroLista = "";
    this.isShowPesquisa = false;
    this.getPedidosBySafra(this.safraIdSelecionada, this.safraSelecionada, this.tipoPesquisa);

  }

  getPedidosBySafra(id: string, safraNome: string, tipoPesquisa: number) {
    this.spinner.show();
    this._service.getPedidosBySafra(id, tipoPesquisa).subscribe({
      next: (_list: Pedido[]) => {

        this.list = _list;
        this.listFiltrados = _list;
        this.dados = new Pedido();
        this.dadosPesquisa = new Pedido();
        this.safraSelecionada = safraNome;
        this.safraIdSelecionada = id;
        this.tipoPesquisa = tipoPesquisa;
        this.isShow = false;
        this.isShowForm = false;
        this.isShowTable = true;
        this.spinner.hide();

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
        this.spinner.hide();
      }
    });
  }

  getVendedor() {
    this._serviceGeral.getCombo("VendedorAtivo").subscribe({
      next: (_list: Geral[]) => {
        this.vendedorList = _list;
        this.spinner.hide();

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar Vendedores`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  getSafraByFilter(tipoPesquisa: number) {
    this.isShowPesquisa = false;
    switch (tipoPesquisa) {
      case 1:
        this.safraFilter = " - Prioridades";
        break;
      case 2:
        this.safraFilter = " - M1 - Não Pago (FPD)";
        break;
      case 3:
        this.safraFilter = " - M2 - Não Pago";
        break;
      case 4:
        this.safraFilter = " - M3 - Não Pago";
        break;
      case 5:
        this.safraFilter = " - M4 - Não Pago";
        break;
      default:
        this.safraFilter = "";
        break;
    }
    this.getPedidosBySafra(this.safraIdSelecionada, this.safraSelecionada, tipoPesquisa);
  }

  getPedidoIQAux() {
    var pedidoAux = new PedidoIQ();
    pedidoAux.id = 0;
    pedidoAux.pedidoId = 0;
    pedidoAux.descricao = "";
    pedidoAux.dataVencimento = "";
    pedidoAux.dataPagamento = "";
    pedidoAux.dataPrevisaoPagamento = "";
    pedidoAux.statusIQ = 1;
    pedidoAux.statusIQDescricao = "";
    pedidoAux.diasAberto = 0;
    pedidoAux.valorFatura = 0;
    pedidoAux.bolProvavelChurn = false;
    pedidoAux.contatoRealizado = false;
    pedidoAux.dataContato = "";
    pedidoAux.bolFPD = false;
    pedidoAux.observacao = "";
    pedidoAux.rastro = null;

    return pedidoAux;
  }

  getSafra() {
    this.spinner.show();
    this._serviceSafra.getSafraAtiva().subscribe({
      next: (_list: Safra[]) => {
        this.safraList = _list;
        this.isShow = true;
        this.isShowTable = false;
        this.isShowForm = false;
        this.spinner.hide();
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar as Safras`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  getStatus() {
    this._serviceGeral.getCombo("StatusIQ").subscribe({
      next: (_list: Geral[]) => {
        this.statusList = _list;

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar o Status do IQ`, 'danger');
      }
    });
  }

  getTipoPesquisaIQ() {
    this._serviceGeral.getCombo("TipoPesquisaIQ").subscribe({
      next: (_list: Geral[]) => {
        this.tipoList = _list;

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar os registros`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  pesquisaAvancada() {
    this.spinner.show();
    this.isShowPesquisa = true;
    this.pesquisaForm.reset();
    this.getStatus();
    this.getTipoPesquisaIQ();
    this.getVendedor();

    this.spinner.hide();
    this.dados = new Pedido();
    this.dadosPesquisa = new Pedido();
  }

  pesquisar() {
    if (this.pesquisaForm.valid) {
      this.spinner.show();

      this.dados = Object.assign({}, this.pesquisaForm.value);

      this.dados.bolMigracaoFixoFibra = false;
      this.dados.bolMigracaoVeloxFibra = false;
      this.dados.bolPortabilidade = false;
      this.dados.funcionarioId ??= 0;
      this.dados.segmento ??= 0;
      this.dados.vendaRecadastrada = false;
      this.dados.viabilidadeParcial = false;
      if (this.dados.statusVenda != null && this.dados.tipoVenda == null) {
        this.spinner.hide();
        this.add(`Selecione uma Fatura para pesquisar`, 'danger');
        return;
      }
      if (this.dados.statusVenda == null && this.dados.tipoVenda != null) {
        this.spinner.hide();
        this.add(`Selecione um Status para pesquisar`, 'danger');
        return;
      }

      this.dados.statusVenda ??= 0;
      this.dados.tipoVenda ??= 0;
      this.dados.idBundle = this.safraIdSelecionada;
      this.dadosPesquisa = this.dados;

      this._service.pesquisarIq(this.dados).subscribe({
        next: (_list: Pedido[]) => {
          this.list = _list;
          this.listFiltrados = this.list;

          this.spinner.hide();



        }, error: (error: any) => {
          this.spinner.hide();
          this.add(`Erro ao tentar carrgar dados`, 'danger');
        }
      });
    }
  }

  exportar(): void {
    this.spinner.show();
    this._service.downloadFileIq(this.dadosPesquisa).subscribe({
      next: fileData => {
        const b: any = new Blob([fileData], { type: 'application/vnd.ms-excel' });
        var dt = Date.now();
        let a = document.createElement('a');
        a.download = "Pedidos" + dt.toString() + ".xls";
        a.href = window.URL.createObjectURL(b);
        a.click();
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar ao gerar o relatório`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  getDetalhes(pedido: Pedido) {
    this.spinner.show();

    this._service.getFpdIqDetalhe(pedido.id.toString()).subscribe({
      next: (item: PedidoIQ[]) => {


        this._service.getPedidoIQRasdtro(pedido.id).subscribe({
          next: (_list: []) => {
            this.rastro = _list;



            this.dados = pedido;
            this.nomeCliente = pedido.nomeCliente;
            this.vendedor = pedido.vendedor;
            this.dadosDetalhe = item;

            this.isShowForm = true;
            this.isShow = false;
            this.isShowTable = false;
            this.getTelefone(pedido.cpfCnpj);

            var pedidoAux = this.getPedidoIQAux();

            this.pedidoForm.patchValue(pedido);

            this.pedidoIQM1Form.patchValue(pedidoAux);
            this.pedidoIQM2Form.patchValue(pedidoAux);
            this.pedidoIQM3Form.patchValue(pedidoAux);
            this.pedidoIQM4Form.patchValue(pedidoAux);

            this.statusSel1 = 0;
            this.statusSel2 = 0;
            this.statusSel3 = 0;
            this.statusSel4 = 0;

            for (let index = 0; index < item.length; index++) {
              if (index == 0) {
                this.pedidoIQM1Form.patchValue(item[index]);
                this.statusSel1 = item[index].statusIQ;
              }
              else if (index == 1) {
                this.pedidoIQM2Form.patchValue(item[index]);
                this.statusSel2 = item[index].statusIQ;
              }
              else if (index == 2) {
                this.pedidoIQM3Form.patchValue(item[index]);
                this.statusSel3 = item[index].statusIQ;
              }
              else if (index == 3) {
                this.pedidoIQM4Form.patchValue(item[index]);
                this.statusSel4 = item[index].statusIQ;
              }
            }
            this.spinner.hide();

          }, error: (error: any) => {
            this.spinner.hide();
            this.add(`Erro ao tentar carrgar dados`, 'danger');
          }
        });




      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar o pedido`, 'danger');
      }
    });

  }

  refresh() {
    this._filtroLista = "";
    this.isShow = true;
    this.isShowTable = false;
    this.isShowForm = false;
    this.getSafra();
  }

  sincrocinar(item: Pedido) {
    this.getDetalhes(item);
  }

  getTelefone(cpf: string) {
    this.spinner.show();
    this._service.pesquisarTelefone(cpf).subscribe({
      next: (_list: ClienteTelefone[]) => {
        this.telefone = _list;
        this.spinner.hide();

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }
    });
  }

  getHistorico(id: number) {
    this._service.getPedidoIQRasdtro(id).subscribe({
      next: (_list: []) => {
        this.rastro = _list;

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }
    });
  }

  setStatus(valor: number, form: number) {

    switch (form) {
      case 1:
        this.statusSel1 = valor;
        break;
      case 2:
        this.statusSel2 = valor;
        break;
      case 3:
        this.statusSel3 = valor;
        break;
      case 4:
        this.statusSel4 = valor;
        break;
      default:
        break;
    }
  }

  salvar() {
    this.spinner.show();
    this.listIQ = [];

    var dadosPedido = this.dados;
    this.dadosM1 = Object.assign({}, this.pedidoIQM1Form.value);
    this.dadosM2 = Object.assign({}, this.pedidoIQM2Form.value);
    this.dadosM3 = Object.assign({}, this.pedidoIQM3Form.value);
    this.dadosM4 = Object.assign({}, this.pedidoIQM4Form.value);

    this.listIQ.push(this.dadosM1);
    this.listIQ.push(this.dadosM2);
    this.listIQ.push(this.dadosM3);
    this.listIQ.push(this.dadosM4);

    dadosPedido.listaIQ = this.listIQ;
    dadosPedido.observacao = this.obsPedido;


    this._service.AtualizaIQ(dadosPedido).subscribe({
      next: (registro: Pedido) => {
        this.obsPedido = "";
        this.add('Status Alterado', 'success');
        this.voltaSafra();

      }, error: (e: any) => {
        this.spinner.hide();
        this.add(`Erro ao salvar registro: ${e.error.detail}`, 'danger');
      }
    });
  }

  validation() {
    this.pesquisaForm = this.fb.group({
      cpfCnpj: [''],
      nomeCliente: [''],
      tipoVenda: [''],
      numeroPedido: [''],
      segmento: [''],
      numeroOS: [''],
      dataOSEmitida: [''],
      dataOSEmitidaFim: [''],
      dataAgendamento: [''],
      dataAgendamentoFim: [''],
      turno: [''],
      idBundle: [''],
      funcionarioId: [''],
      diaVencimento: [''],
      bolMigracaoFixoFibra: [''],
      bolMigracaoVeloxFibra: [''],
      bolPortabilidade: [''],
      vendaRecadastrada: [''],
      viabilidadeParcial: [''],
      observacao: [''],
      statusVenda: [''],
      bairro: [''],
      cidade: [''],
      dataBundle: [''],
      dataInstalacao: [''],
      dataInstalacaoFim: [''],
      dataPedido: [''],
      dataPedidoFim: [''],
      dataCancelamento: [''],
      dataRetirada: [''],
      statusVendaDescricao: [''],
      segmentoDescricao: [''],
      tipoVendaDescricao: [''],
      vendedor: [''],
      safra: ['']
    });

    this.pedidoForm = this.fb.group({
      cpfCnpj: [''],
      nomeCliente: [''],
      tipoVenda: [''],
      numeroPedido: [''],
      segmento: [''],
      numeroOS: [''],
      dataOSEmitida: [''],
      dataPedido: [''],
      idBundle: [''],
      dataBundle: [''],
      funcionarioId: [''],
      bolMigracaoFixoFibra: [''],
      bolMigracaoVeloxFibra: [''],
      bolPortabilidade: [''],
      vendaRecadastrada: [''],
      viabilidadeParcial: [''],
      statusVenda: [''],
      logradouro: [''],
      numero: [''],
      complemento: [''],
      bairro: [''],
      cidade: [''],
      estado: [''],
      cep: [''],
      dataInstalacao: [''],
      dataAgendamento: [''],
      dataAgendamentoFim: [''],
      statusVendaDescricao: [''],
      segmentoDescricao: [''],
      tipoVendaDescricao: [''],
      vendedor: [''],
      justificativa: ['']

    });

    this.pedidoIQM1Form = this.fb.group({
      dataVencimento: [''],
      dataPagamento: [''],
      dataPrevisaoPagamento: [''],
      statusIQ: [''],
      contatoRealizado: [''],
      bolProvavelChurn: [''],
      valorFatura: [''],
      observacao: [''],
      bolFPD: [''],
      descricao: ['']
    });
    this.pedidoIQM2Form = this.fb.group({
      dataVencimento: [''],
      dataPagamento: [''],
      dataPrevisaoPagamento: [''],
      statusIQ: [''],
      contatoRealizado: [''],
      bolProvavelChurn: [''],
      valorFatura: [''],
      observacao: [''],
      bolFPD: [''],
      descricao: ['']
    });
    this.pedidoIQM3Form = this.fb.group({
      dataVencimento: [''],
      dataPagamento: [''],
      dataPrevisaoPagamento: [''],
      statusIQ: [''],
      contatoRealizado: [''],
      bolProvavelChurn: [''],
      valorFatura: [''],
      observacao: [''],
      bolFPD: [''],
      descricao: ['']
    });
    this.pedidoIQM4Form = this.fb.group({
      dataVencimento: [''],
      dataPagamento: [''],
      dataPrevisaoPagamento: [''],
      statusIQ: [''],
      contatoRealizado: [''],
      bolProvavelChurn: [''],
      valorFatura: [''],
      observacao: [''],
      bolFPD: [''],
      descricao: ['']
    });


  }

  add(msg: string, tipo: string): void {
    //danger, success, info, warning
    this.alerts.push({
      type: tipo,
      msg: msg,
      timeout: 5000
    });
    console.log(this.alerts);
  }

  onClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }



}
