export class PedidoAuditoria {

    constructor() { }

    id: number;
    pedidoId: number;
    bolConfimacaoCliente: boolean;
    nome: string;
    parentesco: string;
    bolDadosCliente: boolean;
    bolDadosInstalacao: boolean;
    bolDadosPlanos: boolean;
    bolDadosBancarios: boolean;
    bolDebitoConta: boolean;
    bolDocumentos: boolean;
    statusContato: number;

    observacao: string;
    funcionarioId: number;
    nomeFuncionario: string;
    statusContatoDescricao: string;
   dataAuditoria:string;

}
