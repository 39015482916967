export class ClienteTelefone {

    constructor() { }
    id: number;
    contato: string;
    bolPrincipal: number;
    bolWhatsApp: number;
    origem: number;
    origemDescricao: string;
    clienteId: number;

}
