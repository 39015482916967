import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Setor} from '../_models/setor';
import { Constants } from '../_utils/constants';

@Injectable({
  providedIn: 'root'
})

export class SetorService {

  baseURL =  Constants.URL_BASE+'setor/';

  constructor(private http: HttpClient) { }

  getSetor(): Observable<Setor[]> {
    return this.http.get<Setor[]>(this.baseURL);
  }

  add(dados: Setor) {
    return this.http.post(this.baseURL, dados);
  }

  update(dados: Setor) {
    return this.http.post(this.baseURL, dados);
  }
  delete(dados: Setor) {
    return this.http.post(`${this.baseURL}delete`, dados);
  }


}
