import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { Pedido } from '../../_models/pedido';
import { PedidoService } from '../../_service/pedido.service';
import { PedidoPosVenda } from '../../_models/pedidoPosVenda';
import { GeralService } from '../../_service/geral.service';
import { Geral } from '../../_models/geral';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ClienteTelefone } from '../../_models/clienteTelefone';
import { AuthService } from '../../_service/auth.service';
import { AccordionModule } from 'ngx-bootstrap/accordion';

@Component({
  templateUrl: 'posVenda.component.html'
})

export class PosVendaComponent implements OnInit {

  @ViewChild('myModal') public myModal: ModalDirective;
  @ViewChild('myPedidoPosVenda') public myPedidoPosVenda: ModalDirective;

  alerts: any[];
  pag: Number = 1;
  pedidoIQId: number;
  contador: Number = 10;
  isShow: boolean = false;
  isShowLimpar: boolean = false;
  isShowPesquisa: boolean = false;

  list: PedidoPosVenda[];
  listFiltrados: PedidoPosVenda[];

  vendedorList: Geral[];
  safraList: Geral[];
  statusList: Geral[];


  telefone: ClienteTelefone[] = new Array();
  statusSel: number = 0;

  registerForm: FormGroup;
  pedidoPosVendaForm: FormGroup;

  dados: PedidoPosVenda;
  nomeCliente: string = "";
  vendedor: string = ""
  dadosPesquisa: PedidoPosVenda;
  dadosDetalhe: PedidoPosVenda;
  dadosPosVenda: PedidoPosVenda;

  _filtroLista = '';

  constructor(private _service: PedidoService, private _serviceGeral: GeralService, private fb: FormBuilder, public authService: AuthService,
    public router: Router, private spinner: NgxSpinnerService) { }




  get filtroLista(): string {
    return this._filtroLista;
  }
  set filtroLista(value: string) {
    this._filtroLista = value;
    this.listFiltrados = this.filtroLista ? this.filtrarDados(this.filtroLista) : this.list;
  }



  ngOnInit(): void {
    this.spinner.show();
    this.validation();
    this.getDados();
    this.alerts = new Array();
    window.scrollTo(0, 0);
    if (this.authService.tokenExpired())
      this.logout();
  }


  logout(): void {
    this.authService.logout();
    this.router.navigateByUrl("/register");
  }

  filtrarDados(filtrarPor: string): PedidoPosVenda[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    return this.list.filter(
      d => d.nomeCliente.toLocaleLowerCase().indexOf(filtrarPor) !== -1
    );
  }

  voltar() {
    this.router.navigate(['/dashboard']);
  }

  getDados() {
    this.spinner.show();
    this._service.getPosVenda().subscribe({
      next: (_list: PedidoPosVenda[]) => {
        this.list = _list;
        this.listFiltrados = _list;
        this.registerForm.reset();
        this.dados = new PedidoPosVenda();
        this.dadosPesquisa = new PedidoPosVenda();

        this.getSafra();
        this.getVendedor();
        this.getStatus();
        this.spinner.hide();

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
        this.spinner.hide();
      }
    });

  }

  getVendedor() {
    this._serviceGeral.getCombo("VendedorAtivo").subscribe({
      next: (_list: Geral[]) => {
        this.vendedorList = _list;
        this.spinner.hide();

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar Vendedores`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  getSafra() {
    this._serviceGeral.getCombo("Safra").subscribe({
      next: (_list: Geral[]) => {
        this.safraList = _list;
        this.spinner.hide();
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar as Safras`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  getStatus() {
    this._serviceGeral.getCombo("statuPosVenda").subscribe({
      next: (_list: Geral[]) => {
        this.statusList = _list;

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar o Status do PosVenda`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }


  getDetalhes(id: number) {
   this.spinner.show();
    this._service.getPosVendaDetalhe(id).subscribe({
      next: (item: PedidoPosVenda) => {

        this.dados = item;
        this.nomeCliente = item.nomeCliente;
        this.isShow = true;

        this.registerForm.patchValue(item);
        this.getTelefone(item.cpf);

        this.spinner.hide();
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar o pedido`, 'danger');
      }
    });

  }

  openModal() {
    this.myModal.show();
  }

  registrarContato()
  {
    this.pedidoPosVendaForm.reset();
    this.pedidoPosVendaForm.patchValue(this.dados);
    this.myPedidoPosVenda.show();
  }

  semContato() {
    this.spinner.show();

    this.dadosPosVenda = new PedidoPosVenda();

    this.dadosPosVenda = Object.assign({}, this.registerForm.value);
    this.dadosPosVenda.id = this.dados.id;

    this._service.updateSemContatoPosVenda(this.dadosPosVenda).subscribe({
      next: (registro: PedidoPosVenda) => {
        this.myModal.hide();
        this.refresh();
        this.add('Registro salvo!', 'success');
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carregar dados`, 'danger');
      }, complete: () => this.spinner.hide()
    });

  }


  refresh() {

    this.spinner.show();


    this.registerForm.reset();
    this.isShow = false;
    this.isShowPesquisa = false;
    this.isShowLimpar = false;

    this.getDados();

  }


  pesquisaAvancada() {
    this.spinner.show();
    this.isShowPesquisa = true;
    this.registerForm.reset();
   // this.getSegmento();
   // this.getStatusAgendamento();
    this.getVendedor();
    //this.dadosPesquisa = new Pedido();
   // this.dados = new Pedido();

    this.spinner.hide();
  }

  exportar(): void {
    this.spinner.show();
   /* this._service.downloadAgendamento(this.dadosPesquisa).subscribe({
      next: fileData => {
        const b: any = new Blob([fileData], { type: 'application/vnd.ms-excel' });
        var dt = Date.now();
        let a = document.createElement('a');
        a.download = "Agendamento" + dt.toString() + ".xls";
        a.href = window.URL.createObjectURL(b);
        a.click();
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar ao gerar o relatório`, 'danger');
      }, gaocomplete: () => this.spinner.hide()
    });*/
  }

  pesquisar() {
    if (this.registerForm.valid) {
      this.spinner.show();

      this.dados = Object.assign({}, this.registerForm.value);


      this.dados.statusPosVenda ??= 0;

      this.dados.pendente ??= false;
      this.dados.possivelChurn = false;
      this.dados.contatoRealizado = false;


      this._service.pesquisarPosVenda(this.dados).subscribe({
        next: (_list: PedidoPosVenda[]) => {
          this.list = _list;
          this.listFiltrados = this.list;

          this.spinner.hide();



        }, error: (error: any) => {
          this.spinner.hide();
          this.add(`Erro ao tentar carrgar dados`, 'danger');
        }
      });
    }
  }

  getTelefone(cpf: string) {
    this.spinner.show();
    this._service.pesquisarTelefone(cpf).subscribe({
      next: (_list: ClienteTelefone[]) => {
        this.telefone = _list;
        //this.myModalTelefone.show();
        this.spinner.hide();

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }
    });
  }

  setStatus(valor: number) {
    if (valor == 6)
      this.statusSel = 1;
    else if (valor == 3)
      this.statusSel = 2;
    else
      this.statusSel = 0;
  }

  salvar() {
    this.spinner.show();

    this.dadosPosVenda = new PedidoPosVenda();

    this.dadosPosVenda = Object.assign({}, this.pedidoPosVendaForm.value);
    this.dadosPosVenda.id = this.dados.id;
    if (this.dadosPosVenda.contatoRealizado == null)
      this.dadosPosVenda.contatoRealizado = false;

      this.dadosPosVenda.possivelChurn = !this.dadosPosVenda.possivelChurn;



    this._service.AtualizaPosVenda(this.dadosPosVenda).subscribe({
      next: (registro: PedidoPosVenda) => {
       this.refresh();
       this.myPedidoPosVenda.hide();
        this.add('Registro Alterado', 'success');
      }, error: (e: any) => {
        this.spinner.hide();
        this.add(`Erro ao criar usuário: ${e.error.detail}`, 'danger');
      }
    });
  }

  validation() {

    this.registerForm = this.fb.group({
      cpf: [''],
      descricao: [''],
      nomeCliente: [''],
      numeroOs:[''],
      dataProgamadaInteracao:[''],
      dataInteracao:[''],
      statusPosVenda:[''],
      statusPosVendaDescricao:[''],
      contatoRealizado:[''],
      dataContato:[''],
      pendente:[''],
      observacao:['']

    });


    this.pedidoPosVendaForm = this.fb.group({
      cpf: [''],
      descricao: [''],
      nomeCliente: [''],
      numeroOs:[''],
      dataProgamadaInteracao:[''],
      dataInteracao:[''],
      statusPosVenda:[''],
      statusPosVendaDescricao:[''],
      contatoRealizado:[''],
      dataContato:[''],
      possivelChurn:[''],
      pendente:[''],
      dataPendencia:[''],
      observacao:['']

    });



  }

  add(msg: string, tipo: string): void {
    //danger, success, info, warning
    this.alerts.push({
      type: tipo,
      msg: msg,
      timeout: 5000
    });
    console.log(this.alerts);
  }

  onClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }



}
