import { ClienteTelefone } from "./clienteTelefone";
import { PedidoAgendamento } from "./pedidoAgendamento";
import { PedidoAuditoria } from "./pedidoAuditoria";
import { PedidoHistorico } from "./pedidoHistorico";
import { PedidoIQ } from "./pedidoIQ";
import { PedidoItem } from "./pedidoItem";

export class Pedido {

  constructor() { }

  id: number;

  numeroPedido: string;
  dataPedido: string;
  dataPedidoFim: string;

  tipoVenda: number;
  tipoVendaDescricao: string;
  segmento: number;
  segmentoDescricao: string;
  clienteId: number;
  nomeCliente: string;
  cpfCnpj: string

  statusVenda: number;
  statusVendaDescricao: string;

  tempoStatus: string;
  bolPrazoStatus: boolean;

  vendaRecadastrada: boolean;
  viabilidadeParcial: boolean;
  bolMigracaoFixoFibra: boolean;
  bolMigracaoVeloxFibra: boolean;
  bolPortabilidade: boolean;
  bolDFV: boolean;
  contaOnline: boolean;
  evidencia: boolean;
  evidenciaPrazo: boolean;

  logradouro: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
  cep: string;

  nomeResponsavel: string;
  cpfResponsavel: string;
  email:string;
  telefone:string;
  celular:string;

  observacao: string;

  diaVencimento: number;
  diaCorte: number;
  diaAviso: number;

  metodoPagamento: string;

  idBundle: string;
  dataBundle: string;
  funcionarioResponsavel: string;
  funcionarioResponsavelLogado: boolean;

  numeroOS: string;

  dataOSEmitida: string;
  dataOSEmitidaFim: string;

  dataInstalacao: string;
  dataInstalacaoFim: string;

  dataCancelamento: string;
  dataRetirada: string;


  dataAgendamento: string;
  dataAgendamentoFim: string;
  turno: string;

  funcionarioId: number;
  pedidoIndicacaoId: number;
  indicacao: string;
  vendedor: string;
  justificativa: string;

  item: PedidoItem[];
  auditoria: PedidoAuditoria[];
  historico: PedidoHistorico[];
  agendamento: PedidoAgendamento[];
  clienteTelefone: ClienteTelefone[];

  operadora: number;
  operadoraDescricao:string;
  operadoraHabilitada: boolean;

  safra:string;
  produto: string;
  valorPlanoDescricao: string;
  tipoPagamentoId:number;
  listaIQ: PedidoIQ[];
}
