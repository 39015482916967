import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { Equipe } from '../../_models/equipe';
import { EquipeService } from '../../_service/equipe.service';
import { GeralService } from '../../_service/geral.service';
import { Geral } from '../../_models/geral';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Vendedor } from '../../_models/vendedor';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: 'equipe.component.html'
})


export class EquipeComponent implements OnInit {

  @ViewChild('confirm') public confirm: ModalDirective;
  @ViewChild('modalAvulso') public modalAvulso: ModalDirective;
  @ViewChild('removeVendedor') public removeVendedor: ModalDirective;

  avulsoList: Vendedor[] = [];
  done: Vendedor[] = [];


  alerts: any[];
  isShow: boolean = false;
  listFiltrados: Equipe[];
  list: Equipe[];
  dados: Equipe;

  liderList: Geral[];
  liderSel: number = 0;
  idVendedor: number= 0;
  modoSalvar = 'post';

  registerForm: FormGroup;
  equipeForm: FormGroup;
  vendedorForm: FormGroup;

  descricao: string;
  nomeVendedor:string;
  dataInicio: string;

  _filtroLista = '';
  listVendedoresSel: Vendedor[] = [];

  constructor(private _service: EquipeService, private _serviceGeral: GeralService, private fb: FormBuilder, public router: Router, private spinner: NgxSpinnerService) { }

  get filtroLista(): string {
    return this._filtroLista;
  }
  set filtroLista(value: string) {
    this._filtroLista = value;
    this.listFiltrados = this.filtroLista ? this.filtrarDados(this.filtroLista) : this.list;
  }

  ngOnInit(): void {
    this.spinner.show();
    this.validation();
    this.getDados();
    this.alerts = new Array();
    window.scrollTo(0, 0);
  }



  voltar() {
    this.router.navigate(['/config']);
  }

  filtrarDados(filtrarPor: string): Equipe[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    return this.list.filter(
      d => d.descricao.toLocaleLowerCase().indexOf(filtrarPor) !== -1
    );
  }

  getDados() {
    this._service.getDados().subscribe({
      next: (_list: Equipe[]) => {
        this.list = _list;
        this.listFiltrados = this.list;

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  getLider() {
    this._serviceGeral.getCombo("Lider").subscribe({
      next: (_list: Geral[]) => {
        this.liderList = _list;
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  getAvulso() {

    if (!this.registerForm.valid) {

      this.add(`Informe o dados da Equipe`, 'danger');
      return;
    }

    this.spinner.show();

    this._service.getAvulso().subscribe({
      next: (_list: Vendedor[]) => {
        this.avulsoList = _list;
        this.modalAvulso.show();
        this.spinner.hide();
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }
    });
  }
  setData(data: string) {
    this.dataInicio = data;
  }

  newVendedor(item: Vendedor, incluir: any) {
    var incluir = incluir.target.checked;
    if (incluir)
      this.listVendedoresSel.push(item);
    else {
      var aux = [];
      this.listVendedoresSel.forEach((currentValue, index) => {
        if (currentValue.funcionarioId != item.funcionarioId)
          aux.push(currentValue);
      });

      this.listVendedoresSel = aux;
    }

  }
  incluirVendedor() {

    this.spinner.show();
    if (this.liderSel == 0)
      this.dados = Object.assign({}, this.registerForm.value);

    this.dados.dataFinal = this.dataInicio;

    this.dados.vendedores = this.listVendedoresSel;
    this._service.addVendedor(this.dados).subscribe({
      next: (retorno: Equipe) => {
        this.editar(retorno);
        this.add('Vendedores incluidos!', 'success');
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carregar dados`, 'danger');
      }
    });

    this.modalAvulso.hide();
  }

  refresh() {
    this.registerForm.reset();
    this.isShow = false;
    this.getDados();
  }

  incluir() {
    this.spinner.show();
    this.done = [];
    this.registerForm.reset();
    this.liderSel = 0;
    this.getLider();
    this.isShow = true;
    this.spinner.hide();
  }

  editar(item: Equipe) {
    this.modoSalvar = 'put';
    this.incluir();
    this.dados = Object.assign({}, item);
    this.registerForm.patchValue(this.dados);

    this.liderSel = this.dados.funcionarioId;
    this.done = this.dados.vendedores;
  }


  salvar() {
    if (this.registerForm.valid) {
      this.spinner.show();
      if (this.modoSalvar === 'post') {

        this.dados = Object.assign({}, this.registerForm.value);
        this.dados.vendedores = this.done;

        this._service.add(this.dados).subscribe({
          next: (novoRegistro: Equipe) => {
            this.refresh();
            this.add('Registro Salvo!', 'success');
          }, error: (error: any) => {
            this.spinner.hide();
            this.add(`Erro ao tentar carregar dados`, 'danger');
          }
        });
      } else {
        this.dados = Object.assign({ id: this.dados.id }, this.registerForm.value);
        this.dados.vendedores = this.done;

        this._service.update(this.dados).subscribe({
          next: () => {
            this.refresh();
            this.add('Registro Editado!', 'success');
          }, error: (error: any) => {
            this.spinner.hide();
            this.add(`Erro ao tentar Carregar: ${error}`, 'danger');
          }
        });
      }
    }
  }

  validation() {
    this.registerForm = this.fb.group({
      descricao: ['', [Validators.required]],
      funcionarioId: ['']
    });

    this.equipeForm = this.fb.group({
      dataFinal: ['', [Validators.required]]
    });

    this.vendedorForm = this.fb.group({
      id: [''],
      dataFinal: ['', [Validators.required]]
    });

  }

  excluir(item: Equipe) {
    this.equipeForm.reset();
    this.confirm.show();
    this.dados = item;
    this.descricao = item.descricao;
  }

  excluirVendedor(item: Vendedor) {

    this.idVendedor = item.id;
    this.vendedorForm.reset();
    this.removeVendedor.show();
    this.nomeVendedor = item.nomeFuncionario;
  }



  confirmeDelete() {


    if (!this.equipeForm.valid) {
      this.confirm.hide();
      this.add(`Informe a data desativação`, 'danger');
      return;
    }

    this.dados = Object.assign({ id: this.dados.id }, this.equipeForm.value);
    this._service.desativar(this.dados).subscribe({
      next: () => {
        this.confirm.hide();
        this.refresh();
        this.add('Equipe desativada!', 'success');
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar Deletar: ${error}`, 'danger');
      }
    });

  }


  desativarVendedor() {

      if (!this.vendedorForm.valid) {
      this.removeVendedor.hide();
      this.idVendedor = 0;
      this.add(`Informe a data desativação`, 'danger');
      return;
    }

    this.dados = Object.assign({ id:  this.idVendedor }, this.vendedorForm.value);
    this.dados.id = this.idVendedor;
    this._service.desativarVendedor(this.dados).subscribe({
      next: () => {
        this.removeVendedor.hide();
        this.idVendedor = 0;
        this.refresh();
        this.add('Vendedor desativado!', 'success');
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar Deletar: ${error}`, 'danger');
      }
    });

  }

  add(msg: string, tipo: string): void {
    //danger, success, info, warning
    this.alerts.push({
      type: tipo,
      msg: msg,
      timeout: 5000
    });
    console.log(this.alerts);
  }

  onClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }



}
