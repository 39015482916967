import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { GeralService } from '../../_service/geral.service';
import { Geral } from '../../_models/geral';
import { MetaService } from '../../_service/meta.service';
import { Meta } from '../../_models/meta';
import { FuncionarioMeta } from '../../_models/funcionarioMeta';


@Component({
  templateUrl: 'meta.component.html',
  styleUrls: ['meta.css'],
})

export class MetaComponent implements OnInit {



  alerts: any[];
  pag: Number = 1;
  contador: Number = 10;

  vendedorList: Geral[] = [];
  vendedorListOriginal: Geral[] = [];
  done: Geral[] = [];

  tipoMetaList: Geral[];

  isShow: boolean = false;
  modoSalvar = 'post';
  listFiltrados: Meta[];
  list: Meta[];
  registerForm: FormGroup;
  dados: Meta;


  _filtroLista = '';

  constructor(private _service: MetaService, private _serviceGeral: GeralService, private fb: FormBuilder,
    public router: Router, private spinner: NgxSpinnerService) { }

  get filtroLista(): string {
    return this._filtroLista;
  }
  set filtroLista(value: string) {
    this._filtroLista = value;
    this.listFiltrados = this.filtroLista ? this.filtrarDados(this.filtroLista) : this.list;
  }


  ngOnInit(): void {
    this.spinner.show();
    this.validation();
    this.getDados();
    this.alerts = new Array();
    window.scrollTo(0, 0);
  }

  voltar() {
    this.router.navigate(['/config']);
  }

  filtrarDados(filtrarPor: string): Meta[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    return this.list.filter(
      d => d.dataInicial.toLocaleLowerCase().indexOf(filtrarPor) !== -1
    );
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  getDados() {
    this._service.getDados().subscribe({
      next: (_list: Meta[]) => {
        this.list = _list;
        this.listFiltrados = this.list;

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  getTipoMeta() {

    this.tipoMetaList = [];

    this._serviceGeral.getCombo("TipoMeta").subscribe({
      next: (_list: Geral[]) => {
        this.tipoMetaList = _list;
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  getVendedor() {
    this.vendedorList = [];
    this._serviceGeral.getCombo("Vendedormeta").subscribe({
      next: (_list: Geral[]) => {
        this.vendedorList = _list;
        this.vendedorListOriginal = _list;
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar Vendedores`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }


  refresh() {

    this.spinner.show();

    this.registerForm.reset();

    this.isShow = false;
    this.getDados();

    this.spinner.hide();
  }

  async incluir() {
    this.spinner.show();

    this.registerForm.reset();
    this.done = [];
    this.getTipoMeta();
    this.getVendedor();

    this.isShow = true;

    this.dados = new Meta();
    this.spinner.hide();
  }
  validarGatilho(item:Meta)
  {
    if(item.gatilhoEmp == 0)
    {
      this.add(`Meta não possui Gatilho cadastrado`, 'danger');
      return;
    }
    this.spinner.show();
    this._service.validarGatilho(item).subscribe({
      next: (novoRegistro: Meta) => {
        this.spinner.hide();
        this.add('Gatilho validado e Pedidos atualizados', 'success');
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carregar dados`, 'danger');
      }
    });

  }
  removerInativos() {
    let aux = [];
    if (this.vendedorList.length == this.vendedorListOriginal.length) {
      this.vendedorList.forEach((currentValue, index) => {
        var vet = currentValue.descricao.split(' - ');
        if (vet.indexOf('INATIVO') == -1)
          aux.push(currentValue);
      });

      this.vendedorList = aux;
    }
    else
    this.vendedorList = this.vendedorListOriginal
  }

  salvar() {
    if (this.registerForm.valid) {
      this.spinner.show();


      this.dados = Object.assign({}, this.registerForm.value);


      let vendedores = new Array();
      for (let index = 0; index < this.done.length; index++) {
        const element = this.done[index];

        var vendedor = new FuncionarioMeta();
        vendedor.metaId = 0;
        vendedor.funcionarioId = element.id;
        vendedores.push(vendedor);
      }

      this.dados.vendedores = vendedores;

      this._service.add(this.dados).subscribe({
        next: (novoRegistro: Meta) => {
          this.refresh();
          this.add('Registro Salvo!', 'success');
        }, error: (error: any) => {
          this.spinner.hide();
          this.add(`Erro ao tentar carregar dados`, 'danger');
        }
      });

    }
  }

  validation() {
    this.registerForm = this.fb.group({
      metaTipo: ['', [Validators.required]],
      dataInicial: ['', [Validators.required]],
      dataFinal: ['', [Validators.required]],
      qtdDias: ['', [Validators.required]],
      gatilhoEmp: ['', [Validators.required]],
      pedidoLiquido: ['', [Validators.required]],
      pedidoInstalado: ['', [Validators.required]],
      pedidoLiquidoFinanceiro: [''],
      pedidoInstaladoFinanceiro: ['']


    });
  }

  add(msg: string, tipo: string): void {
    //danger, success, info, warning.
    window.scrollTo(0, 0);
    this.alerts.push({
      type: tipo,
      msg: msg,
      timeout: 5000
    });
    console.log(this.alerts);
  }

  onClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }



}
