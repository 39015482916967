import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { Constants } from '../../_utils/constants';
import { Pedido } from '../../_models/pedido';
import { PedidoService } from '../../_service/pedido.service';
import { GeralService } from '../../_service/geral.service';
import { Geral } from '../../_models/geral';
import { PlanoService } from '../../_service/plano.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ExcelService } from '../../_service/excel.service';


@Component({
  templateUrl: 'pedido.component.html'
})

export class PedidoComponent implements OnInit {

  @ViewChild('myModal') public myModal: ModalDirective;
  @ViewChild('myVendedor') public myVendedor: ModalDirective;


  alerts: any[];

  pag: Number = 1;
  contador: Number = 10;

  pedidoId: number = 0;
  status: Number = 0;

  isShow: boolean = false;
  isShowLimpar: boolean = false;
  isShowPesquisa: boolean = false;

  listExport: Pedido[];
  listFiltrados: Pedido[];
  list: Pedido[];


  vendedorList: Geral[];
  segmentoList: Geral[];
  statusList: Geral[];
  tipoList: Geral[];
  operadoraList: Geral[];

  dados: Pedido;
  dadosPesquisa: Pedido;
  modoSalvar = 'post';

  mask_cpf = Constants.CPF_FMT;
  mask_cnpj = Constants.CNPJ_FMT;
  mask_data = Constants.DATE_FMT;
  mask_telefone = Constants.TELEFONE_FMT;
  mask_celular = Constants.CELULAR_FMT;

  registerForm: FormGroup;
  pedidoForm: FormGroup;
  vendedorForm: FormGroup;

  obs = '';

  _filtroLista = '';

  constructor(private _service: PedidoService, private _serviceGeral: GeralService, private _servicePlano: PlanoService, private fb: FormBuilder,
    public router: Router, private spinner: NgxSpinnerService, private _serviceExcel: ExcelService) { }

  get filtroLista(): string {
    return this._filtroLista;
  }
  set filtroLista(value: string) {
    this._filtroLista = value;
    this.listFiltrados = this.filtroLista ? this.filtrarDados(this.filtroLista) : this.list;
  }


  ngOnInit(): void {
    this.spinner.show();
    this.validation();
    this.getDados();
    this.alerts = new Array();
    window.scrollTo(0, 0);
  }

  voltar() {
    this.router.navigate(['/dashboard']);
  }

  filtrarDados(filtrarPor: string): Pedido[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    return this.list.filter(
      d => d.nomeCliente.toLocaleLowerCase().indexOf(filtrarPor) !== -1
    );
  }

  getDados() {
    this.spinner.show()
    this._service.getDados().subscribe({
      next: (_list: Pedido[]) => {
        this.list = _list;
        this.listFiltrados = this.list;

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  getVendedor() {
    this._serviceGeral.getCombo("VendedorAtivo").subscribe({
      next: (_list: Geral[]) => {
        this.vendedorList = _list;
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar Vendedores`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  getSegmento() {
    this._serviceGeral.getCombo("Segmento").subscribe({
      next: (_list: Geral[]) => {
        this.segmentoList = _list;
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar Segmentos`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  getStatusVenda() {
    this._serviceGeral.getCombo("StatusVenda").subscribe({
      next: (_list: Geral[]) => {
        this.statusList = _list;
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar Status da Venda`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  getTipoVenda() {
    this._serviceGeral.getCombo("TipoVenda").subscribe({
      next: (_list: Geral[]) => {
        this.tipoList = _list;
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar Tipo de Venda`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  refresh() {

    this.spinner.show();


    this.registerForm.reset();
    this.isShow = false;
    this.isShowPesquisa = false;
    this.getDados();
    this.isShowLimpar = false;
  }

  editar(pedido: Pedido) {
    this.spinner.show()
    this._service.getPedido(pedido.id.toString()).subscribe({
      next: (item: Pedido) => {

        this.dados = item;
        this.pedidoForm.patchValue(this.dados);

        this.isShow = true;

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar o pedido`, 'danger');
      }, complete: () => this.spinner.hide()
    });

  }

  pesquisaAvancada() {
    this.spinner.show();
    this.isShowPesquisa = true;
    this.registerForm.reset();
    this.getSegmento();
    this.getStatusVenda();
    this.getTipoVenda();
    this.getOperadora();
    this.getVendedor();
    this.spinner.hide();
    this.dados = new Pedido();
    this.dadosPesquisa = new Pedido();
  }

  exportar(): void {
    this.spinner.show();
    this._service.downloadFile(this.dadosPesquisa).subscribe({
      next: fileData => {
        const b: any = new Blob([fileData], { type: 'application/vnd.ms-excel' });
        var dt = Date.now();
        let a = document.createElement('a');
        a.download = "Pedidos" + dt.toString() + ".xls";
        a.href = window.URL.createObjectURL(b);
        a.click();
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar ao gerar o relatório`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  pesquisar() {
    if (this.registerForm.valid) {
      this.spinner.show();

      this.dados = Object.assign({}, this.registerForm.value);
      this.dados.bolMigracaoFixoFibra ??= false;
      this.dados.bolMigracaoVeloxFibra ??= false;
      this.dados.bolPortabilidade ??= false;
      this.dados.funcionarioId ??= 0;
      this.dados.segmento ??= 0;
      this.dados.vendaRecadastrada ??= false;
      this.dados.viabilidadeParcial ??= false;
      this.dados.statusVenda ??= 0;
      this.dados.tipoVenda ??= 0;
      this.dados.operadora ??= 0;
      this.dadosPesquisa = this.dados;
      this._service.pesquisar(this.dados).subscribe({
        next: (_list: Pedido[]) => {
          this.list = _list;

          this.isShowPesquisa = false;
          this.isShowLimpar = true;
          this.listFiltrados = this.list;


        }, error: (error: any) => {
          this.spinner.hide();
          this.add(`Erro ao tentar carrgar dados`, 'danger');
        }, complete: () => this.spinner.hide()
      });
    }
  }

  openVendedor(pedido: Pedido)
  {
    this.vendedorForm.reset();
    this.getVendedor();
    this.pedidoId = pedido.id;
    this.myVendedor.show();
  }

  getOperadora() {
    this._serviceGeral.getOperadoraByUser().subscribe({
      next: (_listOperadora: Geral[]) => {
        this.operadoraList = _listOperadora;
        this.spinner.hide();
      }, error: (error: any) => {

        this.spinner.hide();
        this.add(`Erro ao tentar carrgar Operadoras`, 'danger');
      }
    });

  }

  trocarVendedor(myModal: any) {
    this.spinner.show();

    this.dados = new Pedido();
     this.dados = Object.assign({}, this.vendedorForm.value);
     this.dados.id = this.pedidoId;


    this._service.trocarVendedor(this.dados).subscribe({
      next: (registro: Pedido) => {
        myModal.hide()
        if (this.isShowLimpar)
        this.pesquisar();
      else
        this.getDados();
        this.add('Vendedor alterado', 'success');
      }, error: (e: any) => {
        this.spinner.hide();
        this.add(`Erro ao criar usuário: ${e.error.detail}`, 'danger');
      }
    });
  }

  openModal(pedido: Pedido) {
    this.obs = pedido.observacao;
    this.myModal.show();
  }

  validation() {
    this.registerForm = this.fb.group({
      cpfCnpj: [''],
      nomeCliente: [''],
      tipoVenda: [''],
      numeroPedido: [''],
      segmento: [''],
      numeroOS: [''],
      dataOSEmitida: [''],
      dataOSEmitidaFim: [''],
      dataAgendamento: [''],
      dataAgendamentoFim: [''],
      turno: [''],
      idBundle: [''],
      funcionarioId: [''],
      diaVencimento: [''],
      bolMigracaoFixoFibra: [''],
      bolMigracaoVeloxFibra: [''],
      bolPortabilidade: [''],
      vendaRecadastrada: [''],
      viabilidadeParcial: [''],
      observacao: [''],
      statusVenda: [''],
      bairro: [''],
      cidade: [''],
      dataBundle: [''],
      dataInstalacao: [''],
      dataInstalacaoFim: [''],
      dataPedido: [''],
      dataPedidoFim: [''],
      dataCancelamento: [''],
      dataRetirada: [''],
      statusVendaDescricao: [''],
      segmentoDescricao: [''],
      tipoVendaDescricao: [''],
      vendedor: [''],
      operadora: ['']
    });

    this.pedidoForm = this.fb.group({
      cpfCnpj: [''],
      nomeCliente: [''],
      tipoVenda: [''],
      numeroPedido: [''],
      segmento: [''],
      numeroOS: [''],
      dataOSEmitida: [''],
      dataPedido: [''],
      idBundle: [''],
      dataBundle: [''],
      funcionarioId: [''],
      bolMigracaoFixoFibra: [''],
      bolMigracaoVeloxFibra: [''],
      bolPortabilidade: [''],
      vendaRecadastrada: [''],
      viabilidadeParcial: [''],
      statusVenda: [''],
      logradouro: [''],
      numero: [''],
      complemento: [''],
      bairro: [''],
      cidade: [''],
      estado: [''],
      cep: [''],
      dataInstalacao: [''],
      dataCancelamento: [''],
      statusVendaDescricao: [''],
      segmentoDescricao: [''],
      tipoVendaDescricao: [''],
      vendedor: [''],
      justificativa: ['']

    });

    this.vendedorForm = this.fb.group({
      funcionarioId: ['']
     });

  }

  add(msg: string, tipo: string): void {
    //danger, success, info, warning
    this.alerts.push({
      type: tipo,
      msg: msg,
      timeout: 5000
    });
    console.log(this.alerts);
  }

  onClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }



}
