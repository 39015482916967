import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { Geral } from '../../_models/geral';
import { GeralService } from '../../_service/geral.service';
import { SincronizarDadosClaro } from '../../_models/sincronizarDadosClaro';
import { Pedido } from '../../_models/pedido';
import { ImportacaoService } from '../../_service/importacao.service';
import { ImportacaoRetorno } from '../../_models/importacaoRetorno';
import * as XLSX from 'xlsx';
import { ClienteTelefone } from '../../_models/clienteTelefone';

@Component({
  templateUrl: 'sincronizarDados.component.html'
})

export class SincronizarDadosComponent implements OnInit {


  alerts: any[];
  tarrR: any[];

  headers: any[];
  rows: any[];

  textButton: string;
  count: number;
  camposValidos: any[];
  registroClaro: SincronizarDadosClaro[];
  operadoraList: Geral[];
  operadora: number = 0;
  registerForm: FormGroup;

  constructor(private _service: ImportacaoService, private _serviceGeral: GeralService, private fb: FormBuilder, public router: Router, private spinner: NgxSpinnerService) { }



  ngOnInit(): void {
    this.spinner.show();
    this.validation();
    this.getOperadora();
    this.registerForm.reset();
    this.alerts = new Array();
    this.tarrR = new Array();
    this.headers = new Array();
    window.scrollTo(0, 0);
    this.textButton = "Importar";
  }


  voltar() {
    this.router.navigate(['/dashboard']);
  }



  getOperadora() {
    this._serviceGeral.getOperadoraByUser().subscribe({
      next: (_listOperadora: Geral[]) => {
        this.operadoraList = _listOperadora;
        this.spinner.hide();
      }, error: (error: any) => {

        this.spinner.hide();
        this.add(`Erro ao tentar carrgar Operadoras`, 'danger');
      }
    });

  }

  setOperadora(valor: number) {

    this.headers = [];
    this.operadora = valor;
    if (valor == 2) {
      this.headers.push("Codigo", "Contrato", "DataContrato", "Cliente", "TipoVenda", "DataPedido", "Situação", "Status");
    }
    else if (valor == 4) {
      this.headers.push("NumeroPedido", "DataPedido", "Cliente", "Produtos", "Status");
    }

  }

  changeListener(files: FileList) {

    this.tarrR = [];

    if (files && files.length > 0) {
      this.spinner.show();
      let file: File = files.item(0);

      //File reader method
      if (this.operadora == 2) {
        let reader: FileReader = new FileReader();
        reader.readAsText(file);
        reader.onload = (e) => {
          let csv: any = reader.result;
          let allTextLines = [];
          allTextLines = csv.split(/\r|\n|\r/);

          //Table Headings

          this.camposValidos = [];
          this.rows = [];

          let headersFile = allTextLines[0].split(';');
          let data = headersFile;
          let tarr = [];
          for (let j = 0; j < headersFile.length; j++) {

            if (this.operadora == 2) {
              if (data[j] == "CÓDIGO" || data[j] == "DATA DA PROPOSTA" || data[j] == "CONTRATO" || data[j] == "DATA DO PREENCHIMENTO DO CONTRATO" ||
                data[j] == "CLIENTE" || data[j] == "TIPO DA VENDA" || data[j] == "SITUAÇÃO")
                this.camposValidos.push(j);

            }

          }

          // Table Rows
          this.tarrR = [];

          let arrl = allTextLines.length;
          for (let i = 1; i < arrl; i++) {
            if (allTextLines[i] != "")
              this.rows.push(allTextLines[i].split(';'));
          }



          for (let j = 0; j < this.rows.length; j++) {
            var linha = [];

            for (let l = 0; l < this.camposValidos.length; l++) {
              linha.push(this.rows[j][this.camposValidos[l]]);

            }
            linha.push("NÃO IMPORTADO");
            this.tarrR.push(linha);

          }
          //Push rows to array variable

        }
      }
      else if (this.operadora == 4) {
        this.fileUpload(file);
      }
      this.spinner.hide();
    }
  }


  fileUpload(arq: any) {
    try {

      this.spinner.show();
      this.tarrR = []; this.rows = [];
      const selectedFile = arq;
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(selectedFile);
      fileReader.onload = (arq) => {
        let binaryData = arq.target.result;
        let workbook = XLSX.read(binaryData, { type: 'binary' });
        workbook.SheetNames.forEach(sheet => {
          const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);

          for (var x in data) {
            if (data.hasOwnProperty(x)) {
              var res = []; var linha = [];
              res.push(data[x]['Número do pedido']);
              linha.push(data[x]['Número do pedido']);

              res.push(data[x]['Data em que o pedido foi realizado']);
              linha.push(data[x]['Data em que o pedido foi realizado']);
              res.push(data[x]['Nome cliente']);
              linha.push(data[x]['Nome cliente']);
              res.push(data[x]['Data de nascimento']);
              res.push(data[x]['CPF/CNPJ']);
              res.push(data[x]['RG']);
              res.push(data[x]['Nome completo da mãe']);
              res.push(data[x]['Contato principal']);
              res.push(data[x]['Contato principal whatsapp']);
              res.push(data[x]['Contato secundário']);
              res.push(data[x]['Contato secundário whatsapp']);
              res.push(data[x]['E-mail']);
              res.push(data[x]['Fixo - Portabilidade']);
              res.push(data[x]['Fixo - Número']);
              res.push(data[x]['Banda Larga - Tecnologia']);
              res.push(data[x]['Banda Larga - Velocidade']);
              linha.push(data[x]['Banda Larga - Tecnologia'] + " " + data[x]['Banda Larga - Velocidade']);
              linha.push("NÃO IMPORTADO");
              res.push(data[x]['TV - Plano TV']);
              res.push(data[x]['Combo/Oferta contratada']);
              res.push(data[x]['Pagamento - Forma de Pagamento']);
              res.push(data[x]['Pagamento - Vencimento']);
              res.push(data[x]['Status Primário']);
              res.push(data[x]['Status Secundário']);
              res.push(data[x]['Data Termino Tratamento Pedido']);
              res.push(data[x]['Instalação - CEP']);
              res.push(data[x]['Instalação - Logradouro']);
              res.push(data[x]['Instalação - Número']);
              res.push(data[x]['Instalação - Bairro']);
              res.push(data[x]['Instalação - Cidade']);
              res.push(data[x]['Instalação - Estado']);
              res.push(data[x]['Instalação - Complemento 1 - tipo'] + " " + data[x]['Instalação - Complemento 1'] + " " + data[x]['Instalação - Complemento 2 - tipo'] + " " + data[x]['Instalação - Complemento 2']);

              res.push(data[x]['Pagamento - Conta Online']);

              this.tarrR.push(linha);
              this.rows.push(res);
            }
          }
          this.spinner.hide();
        });

      }
    } catch (error) {
      this.spinner.hide();
      this.add(`Erro ao tentar carrgar dados`, 'danger');

    }
  }

  importar() {
    this.count = this.rows.length;
    if (this.count == 0)
      return;

    this.spinner.show();

    if (this.operadora == 2) {

      for (let i = 0; i < this.count; i++) {

        let item = this.rows[i];

        let dados = new Pedido();
        dados.numeroPedido = item[1];
        dados.dataPedido = item[17].substring(0, 10);
        dados.tipoVendaDescricao = item[13];
        dados.nomeCliente = item[6];
        let cpf = item[7] == "-" ? item[8] : item[7];
        dados.cpfCnpj = cpf;
        dados.statusVendaDescricao = item[23];
        dados.diaVencimento = item[15];
        dados.metodoPagamento = item[14];
        dados.numeroOS = item[2];
        dados.dataOSEmitida = item[3].substring(0, 10);
        dados.justificativa = item[24];
        dados.dataOSEmitidaFim = item[12].substring(0, 10);
        dados.evidencia = item[21] == "SIM" ? true : false;
        dados.evidenciaPrazo = item[22] == "SIM" ? true : false;
        dados.operadora = this.operadora;
        dados.segmentoDescricao = item[29] + "*" + item[30] + "*" + item[32] + "*" + item[33] + "*" + item[35] + "*" + item[36] + "*" + item[38] + "*" + item[39];
        this._service.updatePedido(dados).subscribe({
          next: (novoRegistro: ImportacaoRetorno) => {
            this.tarrR[i][7] = novoRegistro.statusRegistro;

            if (i >= this.count - 1) {
              this.spinner.hide();
              this.add('Sincronização realizada', 'success');
              this.count = 0;
            }
          }, error: (error: any) => {
            this.tarrR[i][7] = "ERRO - " + error.error.detail;
            this.add(error.error.detail, 'danger');
            if (i >= this.count - 1) {
              this.spinner.hide();
              this.count = 0;
            }
          }
        });


      }
    }
    else if (this.operadora == 4) {

      let telefones: ClienteTelefone[] = [];

      for (let i = 0; i < this.count; i++) {

        let item = this.rows[i];

        let dados = new Pedido();
        dados.numeroPedido = item[0];
        dados.dataPedido = item[1].substring(0, 10);
        dados.tipoVendaDescricao = item[23] == "31840130" && item[25] == "10" ? "PRE VENDA" : "VENDA";
        dados.nomeCliente = item[2];
        dados.cpfCnpj = item[4];
        dados.diaVencimento = item[19];
        dados.metodoPagamento = item[18];
        dados.statusVendaDescricao = item[20];
        dados.dataAgendamento = item[3]; //Data Nascimento
        dados.nomeResponsavel = item[6]; //Nome da Mae
        dados.cpfResponsavel = item[5]; //RG
        dados.email = item[11];

        let tel = new ClienteTelefone();
        tel.bolPrincipal = 1;
        tel.bolWhatsApp = item[8] == "Sim" ? 1 : 0;
        tel.contato = item[7];
        telefones.push(tel);

        tel = new ClienteTelefone();
        tel.bolPrincipal = 0;
        tel.bolWhatsApp = item[10] == "Sim" ? 1 : 0;
        tel.contato = item[9];
        telefones.push(tel);

        if (item[13] != "-") {
          tel = new ClienteTelefone();
          tel.bolPrincipal = 0;
          tel.bolWhatsApp = 0;
          tel.contato = item[13];
          telefones.push(tel);
        }
        dados.clienteTelefone = telefones;
        dados.cep = item[23];
        dados.logradouro = item[24];
        dados.numero = item[25];
        dados.bairro = item[26];
        dados.cidade = item[27];
        dados.complemento = item[29];
        dados.estado = item[28];

        dados.dataCancelamento = item[20] == "Não Venda" ? item[22] : "-";
        dados.justificativa = item[20] == "Não Venda" ? item[21] : "-";

        dados.bolPortabilidade = item[12] == "-" || item[12] == "" ? false : true;
        dados.contaOnline = item[30] == "Sim" ? true : false;


        dados.produto = item[15];
        dados.valorPlanoDescricao = item[17]; // Plano - Combo
        dados.safra = item[16]; // plano TV


        dados.operadora = this.operadora;
        this._service.updatePedido(dados).subscribe({
          next: (novoRegistro: ImportacaoRetorno) => {
            this.tarrR[i][4] = novoRegistro.statusRegistro;
            this.textButton = "Importando : " + (i+1).toString() + " de " + this.rows.length.toString();
            if (i >= this.count - 1) {
              this.spinner.hide();
              this.add('Sincronização realizada', 'success');
              this.count = 0;
            }
          }, error: (error: any) => {
            this.tarrR[i][4] = "ERRO - " + error.error.detail;
            this.add(error.error.detail, 'danger');
            if (i >= this.count - 1)
              this.spinner.hide();
            this.count = 0;
          }
        });
      }
    }

  }


  validation() {
    this.registerForm = this.fb.group({
      tipoImportacao: [''],
      observacao: [''],
      dataReferenciaBov: [''],
      statusBov: [''],
      operadora: ['']
    });


  }

  add(msg: string, tipo: string): void {
    //danger, success, info, warning
    this.alerts.push({
      type: tipo,
      msg: msg,
      timeout: 5000
    });
    console.log(this.alerts);
  }

  onClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }



}
