import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { GeralService } from '../../_service/geral.service';
import { Geral } from '../../_models/geral';
import { ImportacaoService } from '../../_service/importacao.service';
import { Importacao } from '../../_models/importacao';
import { Subscription, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExcelService } from '../../_service/excel.service';
import * as XLSX from 'xlsx';
@Component({
  templateUrl: 'importacao.component.html'
})

export class ImportacaoComponent implements OnInit {


  alerts: any[];
  pag: Number = 1;
  contador: Number = 10;
  isShow: boolean = false;
  isShowBov: boolean = false;

  list: Importacao[];
  listFiltrados: Importacao[];

  tipoImportacaoList: Geral[];
  tipoBovList: Geral[];
  tipoSel: any;
  dados: Importacao;

  operadoraList: Geral[];

  registerForm: FormGroup;
  resultJSON!: string;
  timerSubscription: Subscription;

  constructor(private _service: ImportacaoService, private _serviceGeral: GeralService,
    private _serviceExcel: ExcelService, private fb: FormBuilder,
    public router: Router, private spinner: NgxSpinnerService) { }



  ngOnInit(): void {
    this.spinner.show();
    this.validation();
    this.getTipo();
    this.getOperadora();
    this.getBov();
    this.getDados();
    this.alerts = new Array();
    window.scrollTo(0, 0);
  }


  voltar() {
    this.router.navigate(['/config']);
  }

  getDados() {
    this._service.getDados().subscribe({
      next: (_list: Importacao[]) => {
        this.list = _list;
        this.listFiltrados = _list;
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }, complete: () => this.spinner.hide()
    });

  }

  getOperadora() {
    this._serviceGeral.getOperadoraByUser().subscribe({
      next: (_listOperadora: Geral[]) => {
        this.operadoraList = _listOperadora;
        this.spinner.hide();
      }, error: (error: any) => {

        this.spinner.hide();
        this.add(`Erro ao tentar carrgar Operadoras`, 'danger');
      }
    });

  }


  getTipo() {
    this._serviceGeral.getCombo("TipoImportacao").subscribe({
      next: (_list: Geral[]) => {
        this.tipoImportacaoList = _list;
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  getBov() {
    this._serviceGeral.getCombo("TipoBov").subscribe({
      next: (_list: Geral[]) => {
        this.tipoBovList = _list;
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  setTipo(valor: number) {
    this.tipoSel = valor;
    this.isShowBov = (valor == 2 || valor == 7);
  }

  setAtualizacao(valor: any) {
    var atualiza = valor.target.checked;
    if (atualiza) {
      this.timerSubscription = timer(0, 10000).pipe(
        map(() => {
          this.getDados();
          this.alerts = new Array();
        })
      ).subscribe();
    }
    else {
      this.timerSubscription.unsubscribe();
    }
  }

  refresh() {

    this.spinner.show();
    this.registerForm.reset();
    this.isShow = false;
    this.getDados();

    this.spinner.hide();
  }

  incluir() {
    this.spinner.show();
    this.isShow = true;
    this.getTipo();

    this.registerForm.reset();
    this.spinner.hide();
    this.dados = new Importacao();
  }


  importar() {
    this.spinner.show();

    this.dados = Object.assign({}, this.registerForm.value);
    if (this.resultJSON.length == 0) {
      this.add('Não é possível importar dados', 'danger');
      return;
    }
    this.dados.tipoImportacao = this.tipoSel;
    this.dados.dados = this.resultJSON;
    this._service.importar(this.dados).subscribe({
      next: (novoRegistro: Importacao) => {
        this.refresh();
        this.add('Dados enviado para Importação!', 'success');
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(error.error.detail, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  importManual(dados: Importacao) {

    this._service.importManual(dados).subscribe({
      next: (registro: Importacao) => {
        this.add("Importação Finalizada", 'success');
        this.getDados();
      }, error: (e: any) => {
        this.spinner.hide();
        this.add(e.error.detail, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  fileUpload(arq: any) {
    try {

      this.spinner.show();
      const selectedFile = arq.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(selectedFile);
      fileReader.onload = (arq) => {
        let binaryData = arq.target.result;
        let workbook = XLSX.read(binaryData, { type: 'binary' });
        workbook.SheetNames.forEach(sheet => {
          const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
          this.resultJSON = JSON.stringify(data, undefined, 4);
        });
        this.spinner.hide();
      }
    } catch (error) {
      this.spinner.hide();
      this.add(`Erro ao tentar carrgar dados`, 'danger');

    }
  }

  exportRetorno(dados: Importacao): void {
    this.spinner.show();
    this._service.downloadFile(dados.id).subscribe(fileData => {
      const b: any = new Blob([fileData], { type: 'application/vnd.ms-excel' });
      var dt =  Date.now();
      let a = document.createElement('a');
      a.download = "retornoImportacao" + dt.toString()+ ".xls";
      a.href = window.URL.createObjectURL(b);
      a.click();
      this.spinner.hide();
    }
    );


  }



  validation() {
    this.registerForm = this.fb.group({
      tipoImportacao: [''],
      observacao: [''],
      dataReferenciaBov: [''],
      statusBov: [''],
      operadora:['']
    });


  }

  add(msg: string, tipo: string): void {
    //danger, success, info, warning
    this.alerts.push({
      type: tipo,
      msg: msg,
      timeout: 5000
    });
    console.log(this.alerts);
  }

  onClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }



}
