import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../_utils/constants';
import { Resultado } from '../_models/resultado';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Consolidado } from '../_models/consolidado';
import { ConsolidadoFuncionario } from '../_models/consolidadoFuncionario';
import { ResultadoConsolidado } from '../_models/resultadoConsolidado';
import { Meta } from '../_models/meta';

@Injectable({
  providedIn: 'root'
})

export class ResultadoService {

  baseURL = Constants.URL_BASE + 'resultado';

  constructor(private http: HttpClient) { }

  getDados(dados: Resultado) {
    return this.http.post(`${this.baseURL}/dados`, dados);
  }

  getConsolidadoMeta(dados: Resultado) {
    return this.http.post<ResultadoConsolidado[]>(`${this.baseURL}/resultado`, dados);
  }



  getDadosHome() {
    var dados = new Resultado();
    return this.http.post(`${this.baseURL}/home`,dados);
  }

  getProduto() {
    return this.http.get<Consolidado[]>(`${this.baseURL}/produto`);
  }

  getAuditoriaHome() {
    return this.http.get<Consolidado[]>(`${this.baseURL}/auditoriaHome`);
  }

  getCadastroHome(status: string) {
    return this.http.get<Resultado>(`${this.baseURL}/cadastroHome?status=`+status);
  }

  getAgendamentoHome() {
    return this.http.get<Consolidado[]>(`${this.baseURL}/agendamentoHome`);
  }

  getTarefaHome() {
    return this.http.get<Consolidado[]>(`${this.baseURL}/tarefaHome`);
  }


}
