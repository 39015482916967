import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Safra} from '../_models/safra';
import { Constants } from '../_utils/constants';
import { Pedido } from '../_models/pedido';

@Injectable({
  providedIn: 'root'
})

export class SafraService {

  baseURL =  Constants.URL_BASE+'safra';

  constructor(private http: HttpClient) { }

  getSafraAtiva() {
    return this.http.get<Safra[]>(`${this.baseURL}/SafraAtiva`);
  }

  validarInstalacao(dados: Pedido) {
    return this.http.post(`${this.baseURL}/validarInstalacao`, dados);
  }

}
