import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Funcao} from '../_models/funcao';
import { Constants } from '../_utils/constants';

@Injectable({
  providedIn: 'root'
})

export class FuncaoService {

  baseURL =  Constants.URL_BASE+'setorfuncao';

  constructor(private http: HttpClient) { }

  getFuncao(setorId: number): Observable<Funcao[]> {
    return this.http.get<Funcao[]>(`${this.baseURL}?idSetor=${setorId}`);
  }

  add(dados: Funcao) {
    return this.http.post(this.baseURL, dados);
  }

  update(dados: Funcao) {
    return this.http.post(this.baseURL, dados);
  }
  delete(dados: Funcao) {
    return this.http.post(`${this.baseURL}/delete`, dados);
  }


}
