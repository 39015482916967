import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaginatedResult } from '../_models/pagination';
import { map, take } from 'rxjs/operators';
import { CheckListExe } from '../_models/checkListExe';
import { CheckListExeItem } from '../_models/checkListExeItem';
import { Constants } from '../_utils/constants';

@Injectable({
  providedIn: 'root'
})

export class CheckListExeService {

  baseURL =  Constants.URL_BASE+'checklistexe';

  constructor(private http: HttpClient) { }


  public getAll(checkListId: number): Observable<CheckListExe[]> {
    return this.http.get<CheckListExe[]>(`${this.baseURL}?checkListId=${checkListId}`);
  }

  public getItens(checkListId: number): Observable<CheckListExeItem[]> {
    return this.http.get<CheckListExeItem[]>(`${this.baseURL}/getItens?checkListId=${checkListId}`);
  }

  add(dados: CheckListExe) {
    return this.http.post(this.baseURL, dados);
  }


  delete(dados: CheckListExe) {
    return this.http.post(`${this.baseURL}/delete`, dados);
  }

  public getById(id: number): Observable<CheckListExe> {
    return this.http.get<CheckListExe>(`${this.baseURL}/${id}`).pipe(take(1));
  }

}
