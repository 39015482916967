import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaginatedResult } from '../_models/pagination';
import { map, take } from 'rxjs/operators';
import { Constants } from '../_utils/constants';
import { Produto } from '../_models/produto';

@Injectable({
  providedIn: 'root'
})

export class ProdutoService {

  baseURL =  Constants.URL_BASE+'produto';

  constructor(private http: HttpClient) { }


  public getAll(page?: number, itemsPerPage?: number, term?: string): Observable<PaginatedResult<Produto[]>> {
    const paginatedResult: PaginatedResult<Produto[]> = new PaginatedResult<Produto[]>();

    let params = new HttpParams;

    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page.toString());
      params = params.append('pageSize', itemsPerPage.toString());
    }

    if (term != null && term != '')
      params = params.append('term', term)

    return this.http
      .get<Produto[]>(this.baseURL, {observe: 'response', params })
      .pipe(
        take(1),
        map((response) => {
          paginatedResult.result = response.body;
          if(response.headers.has('Pagination')) {
            paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
          }
          return paginatedResult;
        }));
  }

  public getDados(){
    return this.http.get<Produto[]>(this.baseURL);  }

  add(dados: Produto) {
    return this.http.post(this.baseURL, dados);
  }

  update(dados: Produto) {
    return this.http.post(this.baseURL, dados);
  }
  delete(dados: Produto) {
    return this.http.post(`${this.baseURL}/delete`, dados);
  }

  public getFuncionarioById(id: number): Observable<Produto> {
    return this.http.get<Produto>(`${this.baseURL}/${id}`).pipe(take(1));
  }

}
