import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map, take } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import { User } from '../_models/user';
import { Constants } from '../_utils/constants';

@Injectable()
export class AuthService {
  private currentUserSource = new ReplaySubject<User>(1);
  currentUser$ = this.currentUserSource.asObservable();

  baseURL = Constants.URL_BASE + 'user';
  jwtHelper = new JwtHelperService();
  decodedToken: any;

  constructor(private http: HttpClient) { }

  login(model: User) {
    return this.http.post(`${this.baseURL}/login`, model).pipe(
      take(1),
      map((response: User) => {
        const user = response;
        if (user) {
          this.setCurrentUser(user);
          this.decodedToken = this.jwtHelper.decodeToken(user.token);
          localStorage.setItem('token', user.token);

          var first = user.firstLogin == true ? "1" : "0";
          localStorage.setItem('first', first);
          sessionStorage.setItem('username', this.decodedToken.unique_name);
        }
      })
    );
  }

  alterPassword(dados: User) {
    return this.http.post(`${this.baseURL}/alterPassword`, dados);
  }

  public setCurrentUser(user: User): void {
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', user.token);
    localStorage.setItem('nome', user.nome);
    this.currentUserSource.next(user);
  }

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('nome');
    this.currentUserSource.next(null);
    this.currentUserSource.complete();
  }

  public tokenExpired(): boolean {
    const token = localStorage.getItem('token');
    return this.jwtHelper.isTokenExpired(token)
  }

}
