import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Principal',
    url: '/dashboard',
    icon: 'icon-home',

  },
  {
    name: 'Painel de Resultado',
    url: '/resultado',
    icon: 'icon-pie-chart'
  },
  {
    name: 'Clientes',
    url: '/cliente',
    icon: 'icon-people'
  },
  {
    name: 'PEDIDOS',
    children: [
      {
        name: 'Consulta',
        url: '/pedido',
        icon: 'icon-magnifier-add'
      }, {
        name: 'Auditoria',
        url: '/auditoria',
        icon: 'icon-phone'
      },
      {
        name: 'Geração de OS',
        url: '/geraros',
        icon: 'icon-puzzle'
      },
       {
        name: 'Agendamento',
        url: '/agendamento',
        icon: 'icon-calendar'
      }
    ]
  },

  {
    name: 'QUALIDADE',
    children: [
     {
        name: 'Pos Venda',
        url: '/posvenda',
        icon: 'icon-bubbles'
      },
      {
        name: 'FPD - IQ',
        url: '/fpdIq',
        icon: 'icon-info'
      }
    ]
  }


];
