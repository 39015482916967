import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { AgendamentoComponent } from './views/agendamento/agendamento.component';
import { AuditoriaComponent } from './views/auditoria/auditoria.component';
import { AuthGuard } from './views/auth/auth.guard';
import { CheckListExeComponent } from './views/checkListExe/checkListExe.component';
import { ClienteComponent } from './views/cliente/cliente.component';
import { ClienteCategoriaComponent } from './views/clienteCategoria/clienteCategoria.component';
import { ConfigInternaComponent } from './views/configInterna/configInterna.component';
import { ContratacaoComponent } from './views/contratacao/contratacao.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { EmpresaComponent } from './views/empresa/empresa.component';
import { EquipeComponent } from './views/equipe/equipe.component';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { FilaTrabalhoComponent } from './views/filaTrabalho/filaTrabalho.component';
import { FpdIqComponent } from './views/fpdIq/fpdIq.component';
import { FuncaoComponent } from './views/funcao/funcao.component';
import { FuncionarioComponent } from './views/funcionario/funcionario.component';
import { GerarOSComponent } from './views/gerarOS/gerarOS.component';
import { ImportacaoComponent } from './views/importacao/importacao.component';
import { LoginComponent } from './views/login/login.component';
import { MetaComponent } from './views/meta/meta.component';
import { PedidoComponent } from './views/pedido/pedido.component';
import { ProdutoComponent } from './views/produto/produtoComponent';
import { RegisterComponent } from './views/register/register.component';
import { ResultadoComponent } from './views/resultado/resultado.component';
import { SetorComponent } from './views/setor/setor.component';
import { SincronizarDadosComponent } from './views/sincronizarDados/sincronizarDados.component';
import { SistemaComponent } from './views/sistema/sistema.component';
import { PosVendaComponent } from './views/posVenda/posVenda.component';

export const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: '404', component: P404Component, data: { title: 'Page 404' } },
  { path: '500', component: P500Component, data: { title: 'Page 500' } },
  { path: 'login', component: LoginComponent, data: { title: 'Login Page' } },
  { path: 'register', component: RegisterComponent, data: { title: 'Register Page' } },
  {
    path: '', component: DefaultLayoutComponent, data: { title: 'Home' }, runGuardsAndResolvers: 'always', canActivate: [AuthGuard],
    children: [
      { path: 'setor', component: SetorComponent, data: { title: 'Setor' } },
      { path: 'funcionario', component: FuncionarioComponent, data: { title: 'Funcionários' }},
      { path: 'funcao', component: FuncaoComponent, data: { title: 'Função' } },
      { path: 'contratacao', component: ContratacaoComponent, data: { title: 'Contratação' } },
      { path: 'clienteCategoria', component: ClienteCategoriaComponent, data: { title: 'Categoria dos Clientes' } },
      { path: 'empresa', component: EmpresaComponent, data: { title: 'Empresa' } },
      { path: 'dashboard', component: DashboardComponent, data: { title: 'Painel de Controle' } },
      { path: 'config', component: ConfigInternaComponent, data: { title: 'Configurações' } },
      { path: 'checklistexe', component: CheckListExeComponent, data: { title: 'Check Ponit' } },
      { path: 'cliente', component: ClienteComponent, data: { title: 'Cliente' } },
      { path: 'produto', component: ProdutoComponent, data: { title: 'Produto' } },
      { path: 'equipe', component: EquipeComponent, data: { title: 'Equipe' } },
      { path: 'auditoria', component: AuditoriaComponent, data: { title: 'Auditoria' } },
      { path: 'geraros', component: GerarOSComponent, data: { title: 'Geração da OS' } },
      { path: 'pedido', component: PedidoComponent, data: { title: 'Pedidos' } },
      { path: 'fila', component: FilaTrabalhoComponent, data: { title: 'Acompanhamento' } },
      { path: 'importacao', component: ImportacaoComponent, data: { title: 'Integração | Importação' } },
      { path: 'resultado', component: ResultadoComponent, data: { title: 'Painel de Resultado' } },
      { path: 'agendamento', component: AgendamentoComponent, data: { title: 'Agendamentos' } },
      { path: 'meta', component: MetaComponent, data: { title: 'Meta' } },
      { path: 'sistema', component: SistemaComponent, data: { title: 'Sistema' } },
      { path: 'fpdIq', component: FpdIqComponent, data: { title: 'FpdIQ' } },
      { path: 'posvenda', component: PosVendaComponent, data: { title: 'Pos Venda' } },
      { path: 'sincronizar', component: SincronizarDadosComponent, data: { title: 'Sincronizar Dados' } },
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
