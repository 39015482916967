import { Geral } from "./geral";

export class Funcionario {

    constructor() { }

    id: number;
    nome: string;
    nomeAbreviado: string;
    cpf: string;
    rg: string;
    email: string;
    dataNascimentoResponse: string;
    observacao: string;
    telefone: string;
    celular: string;
    logradouro: string;
    numero: string;
    complemento: string;
    bairro: string;
    cidade: string;
    estado: string;
    cep: string;
    dataAdmissaoResponse: string;
    dataDemissaoResponse: string;
    empresaId: number;

    contratacao: string;
    contratacaoId: number;

    tempo: string;
    funcao: string;
    setorFuncaoId: number;

    setor: string;
    setorId: number;
    bolSupervisor: boolean;
    bolVendendor: boolean;
    notificacaoVenda: boolean;
    cadastroVenda: boolean;

    jornadaFlexivel: boolean;
    jornadaInicio: string;
    jornadaFinal: string;
    jornadaDescanso: string;

    operadoras : string;


    login: boolean;
}
