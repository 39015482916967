import { Component, OnInit, ViewChild, } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Setor } from '../../_models/setor';
import { SetorService } from '../../_service/setor.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertComponent } from 'ngx-bootstrap/alert';


@Component({
  templateUrl: 'setor.component.html'
})


export class SetorComponent implements OnInit {
  alerts: any[];
  setorList: Setor[];
  setor: Setor;
  descricao: string;
  bodyDeletar = '';
  modoSalvar = 'post';
  registerForm: FormGroup;
  registerFuncaoForm: FormGroup;
  @ViewChild('myModal') public myModal: ModalDirective;
  @ViewChild('confirm') public confirm: ModalDirective;



  constructor(private _service: SetorService,
    private fb: FormBuilder,
    public router: Router,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.validation();
    this.getSetor();
    this.alerts = new Array();
    window.scrollTo(0, 0);
  }

  voltar() {
    this.router.navigate(['/config']);
  }

  getSetor() {
    this._service.getSetor().subscribe({
      next: (_list: Setor[]) => {
        this.setorList = _list;
        //this.eventosFiltrados = this.eventos;

      }, error: (error: any) =>
      {
        this.spinner.hide();
        this.add(`Erro ao tentar Carregar: ${error}`,'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  incluir() {
    this.modoSalvar = 'post';
    this.openModal();
  }

  editar(setor: Setor) {
    this.modoSalvar = 'put';
    this.openModal();
    this.setor = Object.assign({}, setor);
    this.registerForm.patchValue(this.setor);
  }

  openModal() {
    this.registerForm.reset();
    this.myModal.show();
  }

  salvar(myModal: any) {
    if (this.registerForm.valid) {
      this.spinner.show();
      if (this.modoSalvar === 'post') {

        this.setor = Object.assign({}, this.registerForm.value);
        this.setor.setorPrincipal == null ? this.setor.setorPrincipal = false : this.setor.setorPrincipal = this.setor.setorPrincipal;


        this._service.add(this.setor).subscribe({
          next: (novoRegistro: Setor) => {
            myModal.hide();
            this.getSetor();
            this.add('Registro Salvo!','success');
          }, error: (error: any) =>
          {
            this.spinner.hide();
            this.add(`Erro ao tentar Carregar: ${error}`,'danger');
          }, complete: () => this.spinner.hide()
        });
      } else {
        this.setor = Object.assign({ id: this.setor.id }, this.registerForm.value);


        this._service.update(this.setor).subscribe({
          next:() => {
            myModal.hide();
            this.getSetor();
            this.add('Registro Editado!','success');
          }, error: (error: any) =>
          {
            this.spinner.hide();
            this.add(`Erro ao tentar Carregar: ${error}`,'danger');
          }, complete: () => this.spinner.hide()
        });
      }
    }
  }

  validation() {
    this.registerForm = this.fb.group({
      descricao: ['', [Validators.required]],
      setorPrincipal: [false]
    });
  }

  excluir(setor: Setor) {
    this.confirm.show();
    this.setor = setor;
    this.bodyDeletar = `Tem certeza que deseja excluir o Registro: ${setor.descricao}, Código: ${setor.id}`;
  }

  confirmeDelete() {
    this._service.delete(this.setor).subscribe({
      next:() => {
        this.confirm.hide();
        this.getSetor();
        this.add('Registro deletado!','success');
      }, error: (error: any) =>
      {
        this.spinner.hide();
        this.add(`Erro ao tentar Deletar: ${error}`,'danger');
      }, complete: () => this.spinner.hide()
    });

  }

  add(msg: string, tipo: string): void {
    //danger, success, info, warning
      this.alerts.push({
      type: tipo,
      msg: msg,
      timeout: 5000
    });
    console.log(this.alerts);
  }

  onClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }



}
