import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from '../_utils/constants';
import { Importacao } from '../_models/importacao';
import { ImportacaoRetorno } from '../_models/importacaoRetorno';
import { map } from 'rxjs/operators';
import { Pedido } from '../_models/pedido';

@Injectable({
  providedIn: 'root'
})

export class ImportacaoService {

  baseURL = Constants.URL_BASE + 'importacao';

  constructor(private http: HttpClient) { }

  getDados(): Observable<Importacao[]> {
    return this.http.get<Importacao[]>(this.baseURL);
  }

  importar(dados: Importacao) {
    return this.http.post(this.baseURL, dados);
  }

  importManual(dados: Importacao) {
    return this.http.post(`${this.baseURL}/manual`, dados);
  }
  exportRetorno(id: number): Observable<ImportacaoRetorno[]> {
    return this.http.get<ImportacaoRetorno[]>(`${this.baseURL}/export?id=` + id);
  }

  update(dados: Importacao) {
    return this.http.post(this.baseURL, dados);
  }

  updatePedido(dados: Pedido) {
    return this.http.post(`${this.baseURL}/updatePedido`, dados);
  }

  downloadFile(id: number) {

    return this.http.get(this.baseURL + '/export?id=' + id, {responseType: 'blob' as 'blob'}).pipe(
      map((x) => {
        return x;
    })
    );
  }
}
