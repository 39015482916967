import { Component, OnInit, ViewChild, } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { ResultadoService } from '../../_service/resultado.service';
import { Resultado } from '../../_models/resultado';
import * as moment from 'moment';
import { Consolidado } from '../../_models/consolidado';
import { ConsolidadoFuncionario } from '../../_models/consolidadoFuncionario';
import { Pedido } from '../../_models/pedido';
import { AuthService } from '../../_service/auth.service';
import { ConsolidadoResultado } from '../../_models/consolidadoResultado';
import { ResultadoConsolidado } from '../../_models/resultadoConsolidado';
import { Meta } from '../../_models/meta';

@Component({
  templateUrl: 'Resultado.component.html'
})


export class ResultadoComponent implements OnInit {
  alerts: any[];
  registerForm: FormGroup;

  listaMetaConsolidado: ResultadoConsolidado[] ;




  dados: Resultado;
  list: Consolidado[];

  liquidoPeriodo: ConsolidadoResultado[];
  instaladoPeriodo: ConsolidadoResultado[];

  listProdutoCPF: Consolidado[];
  listProdutoCNPJ: Consolidado[];

  listEquipe: ConsolidadoFuncionario[];
  listFuncionario: ConsolidadoFuncionario[];
  listPedido: Pedido[];
  listCancelado: Pedido[];

  pedido: boolean = false;
  gatilhoEmp: boolean = false;
  valorGatilho: string = "";

  realizadoInstalado: string = "0%";
  realizadoCancelado: string = "0%";
  periodo: string;

  barChartType = 'bar';
  barChartLegend = true;



  barChartOptions: any = {
    scaleShowVerticalLines: true,
    title: {
      display: true,
      text: 'Detalhes do Cancelamento'
    },
    legend: {
      display: true,
      position: 'bottom'
    },
    responsive: true,
    scales: {
      xAxes: [{
        display: false
      }]
    }
  };
  barChartData: any[];
  barChartLabels: string[];

  AgendamentoOptions: any = {
    scaleShowVerticalLines: true,
    title: {
      display: true,
      text: 'Motivos'
    },
    legend: {
      display: false,
      position: 'bottom'
    },
    responsive: true
  };

  AgendamentoLabels: string[];
  AgendamentoData: number[];
  pieChartType = 'pie';

  pieChartColors =
    [{
      backgroundColor: ["#F42A0A", "#95382A", "#97bbzd", "#97bbad", "#97bb0d", "#97bb1d", "#97bb6d", "#97bb7d", "#97bb8d", "#97bb9d"]
    }];
  lineChartLegend = true;
  lineChartType = 'line';
  lineChartLabels: any[];

  lineChartData: any[];

  lineChartColours: Array<any> = [
    { // grey
      backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // dark grey
      backgroundColor: 'rgba(77,83,96,0.2)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    }
  ];

  public lineChartOptions: any = {
    responsive: true,
    legend: {
      display: true,
      position: 'bottom'
    }, title: {
      display: true,
      text: 'Evolução'
    },
  };

  referencia: number;
  stringArray: any = [];
  listReferencia: any[];
  referenciaMes: any[] = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];

  referenciaAno: any[] = ['2021', '2022'];

  config = {

    displayKey: 'name',
    search: false,
    height: 'auto',
    placeholder: 'Selecione',
    limitTo: 0,
    moreText: 'selecionado(s)',
    noResultsFound: 'Nenhum registro localizado',
    inputDirection: 'ltr'
  }

  constructor(private _service: ResultadoService, private fb: FormBuilder, public router: Router, public authService: AuthService, private spinner: NgxSpinnerService) { }

  async ngOnInit(): Promise<void> {
    this.alerts = new Array();

    this.listaMetaConsolidado = new Array();

    this.liquidoPeriodo = new Array();
    this.instaladoPeriodo = new Array();

    this.barChartData = new Array();
    this.barChartLabels = new Array();

    this.AgendamentoData = new Array();
    this.AgendamentoLabels = new Array();

    this.lineChartLabels = new Array();
    this.lineChartData = new Array();
    this.listReferencia = new Array();


    this.listPedido = new Array();
    this.listCancelado = new Array();

    this.listProdutoCPF = new Array();
    this.listProdutoCNPJ = new Array();

    this.dados = new Resultado();
    this.getDados();
    //this.getConsolidadoMeta();
    window.scrollTo(0, 0);

    if (this.authService.tokenExpired())
      this.logout();
  }


  logout(): void {
    this.authService.logout();
    this.router.navigateByUrl("/register");
  }

  voltar() {
    this.router.navigate(['/dashboard']);
  }

getConsolidadoMeta()
{
  this._service.getConsolidadoMeta(this.dados).subscribe({
    next: (_dados: ResultadoConsolidado[]) => {
      this.listaMetaConsolidado = _dados;

    }, error: (error: any) => {
      this.add(`Erro ao tentar carrgar dados`, 'danger');
    }
  });

}
  getDados() {
    this.spinner.show()
    this._service.getDados(this.dados).subscribe({
      next: (_dados: Resultado) => {
        this.dados = _dados;

        this.instaladoPeriodo = _dados.instaladoPeriodo;
        this.liquidoPeriodo = _dados.liquidoPeriodo;
        let desc = (_dados.equipe != "" ? " | " + _dados.equipe : (_dados.vendedor != "" ? " | " + _dados.vendedor : ""));
        this.periodo = _dados.dataInicio + " - " + _dados.dataFinal + desc;
        this.listEquipe = _dados.equipeConsolidado;
        this.listFuncionario = _dados.funcionarioConsolidado;
        this.pedido = (_dados.listaPedidos != null || _dados.listaCancelados != null);
        if (this.pedido) {
          this.listPedido = _dados.listaPedidos != null ? _dados.listaPedidos : [];
          this.listCancelado = _dados.listaCancelados != null ? _dados.listaCancelados : [];
        }

        this.gatilhoEmp = this.dados.totalGatilhoEmp <= this.dados.gatilhoEmp;
        this.valorGatilho = this.dados.gatilhoEmp.toString() + " | " + this.dados.totalGatilhoEmp.toString();

        /*


        var motivos = []; var descricaoMotivo = []; 1
        _dados.motivoCancelamento.forEach((currentValue, index) => {
          motivos.push(currentValue.total);
          descricaoMotivo.push(currentValue.descricao);
        });

        this.barChartLabels = descricaoMotivo;
        this.barChartData = [{ data: motivos, label: 'Motivo do Cancelamento' }];

        motivos = []; descricaoMotivo = [];
        _dados.agendamentoConsolidado.forEach((currentValue, index) => {
          motivos.push(currentValue.total);
          descricaoMotivo.push(currentValue.descricao);
        });

        this.list = _dados.agendamentoConsolidado;
        this.AgendamentoLabels = descricaoMotivo;
        this.AgendamentoData = motivos;

        this.listProdutoCNPJ = []; this.listProdutoCPF = [];
        _dados.listaProduto.forEach((currentValue, index) => {

          var item = new Consolidado();
          item.descricao = currentValue.descricao;
          item.total = currentValue.total;

          if (currentValue.descricao.includes('CNPJ'))
            this.listProdutoCNPJ.push(item);
          else
            this.listProdutoCPF.push(item);
        });



        var dt = _dados.dataInicio.split('/');
        _dados.dataInicio = dt[2] + "-" + dt[1] + "-" + dt[0] + "T00:00:00.000";
        let dtInicio = new Date(_dados.dataInicio);

        dt = _dados.dataFinal.split('/');
        _dados.dataFinal = dt[2] + "-" + dt[1] + "-" + dt[0] + "T00:00:00.000";
        let dtFinal = new Date(_dados.dataFinal);

        var arrayVL = []; var arrayInstalado = []; this.lineChartLabels = [];
        let diario = (this.stringArray.length == 0) || (this.stringArray.length == 1 && this.referencia == 2);
        if (diario) {
          while (dtInicio <= dtFinal) {


            let label = (moment(dtInicio)).format('DD/MM')
            this.lineChartLabels.push(label);

            var inserirdo = false;


            _dados.evolucaoLiquido.forEach((currentValue, index) => {
              if (currentValue.descricao == (moment(dtInicio)).format('DD/MM/YYYY')) {
                arrayVL.push(currentValue.total);
                inserirdo = true;
              }

            });
            if (!inserirdo)
              arrayVL.push(0);


            inserirdo = false;
            _dados.evolucaoInstalado.forEach((currentValue, index) => {
              if (currentValue.descricao == (moment(dtInicio)).format('DD/MM/YYYY')) {
                arrayInstalado.push(currentValue.total);
                inserirdo = true;

              }
            });
            if (!inserirdo)
              arrayInstalado.push(0);

            dtInicio.setDate(dtInicio.getDate() + 1);
          }
        }
        else {

          this.lineChartLabels = this.stringArray;

          var inserirdo = false;

          this.stringArray.forEach((currentMes, index) => {

            _dados.evolucaoLiquido.forEach((currentValue, index) => {
              if (currentValue.descricao == currentMes) {
                arrayVL.push(currentValue.total);
                inserirdo = true;
              }

            });
            if (!inserirdo)
              arrayVL.push(0);

            inserirdo = false;

          });


          this.stringArray.forEach((currentMes, index) => {
            _dados.evolucaoInstalado.forEach((currentValue, index) => {
              if (currentValue.descricao == currentMes) {
                arrayInstalado.push(currentValue.total);
                inserirdo = true;

              }
            });
            if (!inserirdo)
              arrayInstalado.push(0);
            inserirdo = false;
          });
        }

        this.lineChartData = [
          { data: arrayVL, label: 'VL' },
          { data: arrayInstalado, label: 'INSTALADO' }
        ];*/

        this.spinner.hide();
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }
    });
  }


  setReferencia(valor: number) {

    this.referencia = valor;
    if (valor == 1)

      this.listReferencia = this.referenciaAno;
    else if (valor == 2)
      this.listReferencia = this.referenciaMes;
    else {
      this.listReferencia = [];
      this.referencia = 0;
    }
  }


  add(msg: string, tipo: string): void {
    //danger, success, info, warning
    this.alerts.push({
      type: tipo,
      msg: msg,
      timeout: 5000
    });
    console.log(this.alerts);
  }

  onClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }

  filtrar() {
    var referenciaValor = this.stringArray.join(",");

    this.dados.valorReferencia = referenciaValor;
    this.dados.referencia = this.referencia.toString();

    //this.getConsolidadoMeta();
    this.getDados();
  }

  detalharEquipe(id: number) {
    this.dados.vendedor = "";
    this.dados.equipe = id.toString();
    this.getDados();
  }
  detalharFuncionario(id: number) {
    this.dados.equipe = "";
    this.dados.vendedor = id.toString();
    this.getDados();

  }

  refresh() {
    window.location.reload();
  }

}
