import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { Constants } from '../../_utils/constants';
import { Pedido } from '../../_models/pedido';
import { PedidoService } from '../../_service/pedido.service';
import { GeralService } from '../../_service/geral.service';
import { Geral } from '../../_models/geral';
import { PlanoService } from '../../_service/plano.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PedidoAuditoria } from '../../_models/pedidoAuditoria';
import { AuthService } from '../../_service/auth.service';
import { SafraService } from '../../_service/safra.service';


@Component({
  templateUrl: 'gerarOS.component.html'
})

export class GerarOSComponent implements OnInit {

  @ViewChild('myModal') public myModal: ModalDirective;
  @ViewChild('myAuditoria') public myAuditoria: ModalDirective;

  alerts: any[];


  isShow: boolean = false;
  isShowOS: boolean = false;
  isShowCancelameneto: boolean = false;
  isShowPendente: boolean = false;
  isShowInstalacao: boolean = false;
  isShowTratamento: boolean = false;
  isIndicacao: boolean = false;
  usuario: string ="";

  listFiltrados: Pedido[];
  list: Pedido[];

  listPendenteFiltrados: Pedido[];
  listPendente: Pedido[];

  listPreFiltrados: Pedido[];
  listPre: Pedido[];

  listOSGeradaFiltrados: Pedido[];
  listOSGerada: Pedido[];


  auditoria: PedidoAuditoria[];

  vendedorList: Geral[];
  segmentoList: Geral[];
  motivoList: Geral[];

  dados: Pedido;
  modoSalvar = 'post';

  mask_cpf = Constants.CPF_FMT;
  mask_cnpj = Constants.CNPJ_FMT;
  mask_data = Constants.DATE_FMT;
  mask_telefone = Constants.TELEFONE_FMT;
  mask_celular = Constants.CELULAR_FMT;

  registerForm: FormGroup;

  obs = '';

  _filtroLista = '';
  _filtroListaPendente = '';
  _filtroListaPreVenda = '';
  _filtroListaOSGerada = '';


  constructor(private _service: PedidoService,private _serviceSafra: SafraService, public authService: AuthService,private _serviceGeral: GeralService, private _servicePlano: PlanoService, private fb: FormBuilder,
    public router: Router, private spinner: NgxSpinnerService) { }

  get filtroLista(): string {
    return this._filtroLista;
  }
  set filtroLista(value: string) {
    this._filtroLista = value;
    this.listFiltrados = this.filtroLista ? this.filtrarDados(this.filtroLista) : this.list;
  }

  get filtroListaPendente(): string {
    return this._filtroListaPendente;
  }
  set filtroListaPendente(value: string) {
    this._filtroListaPendente = value;
    this.listPendenteFiltrados = this.filtroListaPendente ? this.filtrarDadosPendente(this.filtroListaPendente) : this.listPendente;
  }


  get filtroListaPreVenda(): string {
    return this._filtroListaPreVenda;
  }
  set filtroListaPreVenda(value: string) {
    this._filtroListaPreVenda = value;
    this.listPreFiltrados = this.filtroListaPreVenda ? this.filtrarDadosPreVendas(this.filtroListaPreVenda) : this.listPre;

  }

  get filtroListaOSGerada(): string {
    return this._filtroListaOSGerada;
  }
  set filtroListaOSGerada(value: string) {
    this._filtroListaOSGerada = value;
    this.listOSGeradaFiltrados = this.filtroListaOSGerada ? this.filtrarDadosOSGerada(this.filtroListaOSGerada) : this.listOSGerada;

  }


  ngOnInit(): void {
    this.spinner.show();
    this.validation();
    this.getDados();
    this.alerts = new Array();
    window.scrollTo(0, 0);

    this.usuario = localStorage.getItem('nome');
    if (this.authService.tokenExpired())
    this.logout();
}


logout(): void {
  this.authService.logout();
  this.router.navigateByUrl("/register");
}

  voltar() {
    this.router.navigate(['/dashboard']);
  }

  filtrarDados(filtrarPor: string): Pedido[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    return this.list.filter(
      d => d.nomeCliente.toLocaleLowerCase().indexOf(filtrarPor) !== -1
    );
  }

  filtrarDadosPendente(filtrarPor: string): Pedido[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    return this.listPendente.filter(
      d => d.nomeCliente.toLocaleLowerCase().indexOf(filtrarPor) !== -1
    );
  }


  filtrarDadosPreVendas(filtrarPor: string): Pedido[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    return this.listPre.filter(
      d => d.nomeCliente.toLocaleLowerCase().indexOf(filtrarPor) !== -1
    );
  }

  filtrarDadosOSGerada(filtrarPor: string): Pedido[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    return this.listOSGerada.filter(
      d => d.nomeCliente.toLocaleLowerCase().indexOf(filtrarPor) !== -1
    );
  }

  getDados() {
    this.spinner.show();
    this._service.getbyAprovada().subscribe({
      next: (_list: Pedido[]) => {

        this.list = _list;
        this.listFiltrados = this.list;

        this._service.getbyPendenteBO().subscribe({
          next: (_listPendente: Pedido[]) => {

            this.listPendente = _listPendente;
            this.listPendenteFiltrados = this.listPendente;

            this._service.getPreVenda().subscribe({
              next: (_listPre: Pedido[]) => {

                this.listPre = _listPre;
                this.listPreFiltrados = this.listPre;
                this._service.getOSGerada().subscribe({
                  next: (_listOSGerada: Pedido[]) => {

                    this.listOSGerada = _listOSGerada;
                    this.listOSGeradaFiltrados = this.listOSGerada;
                    this.spinner.hide();
                  }
                });
              }
            });
          }
        });

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }
    });

  }

  getVendedor() {
    this._serviceGeral.getCombo("Vendedor").subscribe({
      next: (_list: Geral[]) => {
        this.vendedorList = _list;
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar Vendedores`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  getMotivoCancelamento() {
    this._serviceGeral.getCombo("MotivoCancelamento").subscribe({
      next: (_list: Geral[]) => {
        this.motivoList = _list;
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar os Motivo de Cancelamento`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  getSegmento() {
    this._serviceGeral.getCombo("Segmento").subscribe({
      next: (_list: Geral[]) => {
        this.segmentoList = _list;
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar Segmentos`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  refresh() {


    this.registerForm.reset();
    this.isShow = false;
    this.isShowInstalacao = false;
    this.isShowTratamento = false;
    this.isShowCancelameneto = false;
    this.isShowPendente = false;

    this.getDados();

  }

  incluir() {
    this.spinner.show();

    this.getSegmento();
    this.getVendedor();
    this.getMotivoCancelamento();

    this.registerForm.reset();
    this.spinner.hide();
    this.dados = new Pedido();
  }

  editar(pedido: Pedido) {

    this.spinner.show();
    this._service.updateResponsavel(pedido).subscribe({
      next: async (registro: Pedido) => {
         this.getMotivoCancelamento();
         this.getSegmento();

        this.registerForm.reset();
        this.isShow = true;
        this.isShowTratamento = true;
        this.isShowOS = false;
        this.isShowCancelameneto = false;
        this.isShowPendente = pedido.statusVenda == 4;

        this.dados = Object.assign({}, pedido);
        this.isIndicacao = registro.indicacao?.length > 0;
        this.dados.indicacao = registro.indicacao;

        this.registerForm.patchValue(this.dados);

        this.auditoria = this.dados.auditoria;
        this.spinner.hide();

      }, error: (e: any) => {
        this.spinner.hide();
        this.add(e.error.detail, 'danger');
      }
    });

  }

  liberar(pedido: Pedido) {

    this.spinner.show();
    this._service.liberarPreVenda(pedido).subscribe({
      next: async (registro: Pedido) => {

        this.refresh();
        this.add('Pre Venda Liberada', 'success');

        this.spinner.hide();

      }, error: (e: any) => {
        this.spinner.hide();
        this.add(e.error.detail, 'danger');
      }
    });

  }

  gerarOS() {

    this.spinner.show();

    this.isShowOS = true;

    this.isShowInstalacao = false;
    this.isShowTratamento = false;
    this.isShowCancelameneto = false;
    this.isShowPendente = false;


    this.spinner.hide();
  }

  osGerada() {

    this.spinner.show();

    this.isShowOS = false;
    this.isShowTratamento = false;
    this.isShowCancelameneto = false;
    this.isShowPendente = false;
    this.isShowInstalacao = true;


    this.spinner.hide();
  }

  atualizarInstalacao(pedido: Pedido) {

    this.spinner.show();
    this._service.updateResponsavel(pedido).subscribe({
      next: async (registro: Pedido) => {
         this.getMotivoCancelamento();
         this.getSegmento();

        this.registerForm.reset();
        this.isShow = true;
        this.isShowTratamento = true;

        this.isShowOS = false;
        this.isShowCancelameneto = false;
        this.isShowPendente = pedido.statusVenda == 4;

        this.dados = Object.assign({}, pedido);
        this.isIndicacao = registro.indicacao?.length > 0;
        this.dados.indicacao = registro.indicacao;

        this.registerForm.patchValue(this.dados);

        this.auditoria = this.dados.auditoria;
        this.spinner.hide();

      }, error: (e: any) => {
        this.spinner.hide();
        this.add(e.error.detail, 'danger');
      }
    });

  }


  cancelar() {
    this.spinner.show();

    this.isShowInstalacao = false;
    this.isShowOS = false;
    this.isShowTratamento = false;
    this.isShowCancelameneto = true;
    this.isShowPendente = false;

    this.spinner.hide();
  }

  vendaPendente() {

    this.spinner.show();


    this.isShowInstalacao = false;
    this.isShowOS = false;
    this.isShowTratamento = false;
    this.isShowCancelameneto = false;
    this.isShowPendente = true;
    this.spinner.hide();
  }


  salvar() {
    if (this.registerForm.valid) {
      this.spinner.show();
      if (this.modoSalvar === 'post') {
        var id = this.dados.id;
        this.dados = Object.assign({}, this.registerForm.value);
        this.dados.id = id;


        if (this.dados.numeroOS == "" && !this.dados.viabilidadeParcial) {
          this.add(`Informe o número da OS`, 'danger');
        } else {
          this._service.gerarOS(this.dados).subscribe({
            next: (novoRegistro: Pedido) => {
              this.refresh();
              this.add('Registro salvo!', 'success');
            }, error: (error: any) => {
              this.spinner.hide();
              this.add(error.error.detail, 'danger');
            }, complete: () => this.spinner.hide()
          });
        }
      }
    }
  }

  salvarCancelamento() {
    if (this.registerForm.valid) {
      this.spinner.show();
      if (this.modoSalvar === 'post') {
        var id = this.dados.id;
        this.dados = Object.assign({}, this.registerForm.value);
        this.dados.id = id;


        this._service.cancelar(this.dados).subscribe({
          next: (novoRegistro: Pedido) => {
            this.refresh();
            this.add('Registro cancelado!', 'success');
          }, error: (error: any) => {
            this.spinner.hide();

            this.add(error.error.detail, 'danger');
          }, complete: () => this.spinner.hide()
        });
      }
    }
  }

  salvarInstalacao() {
    if (this.registerForm.valid) {
      this.spinner.show();
      if (this.modoSalvar === 'post') {
        var id = this.dados.id;
        this.dados = Object.assign({}, this.registerForm.value);
        this.dados.id = id;


        this._serviceSafra.validarInstalacao(this.dados).subscribe({
          next: (novoRegistro: Pedido) => {
            this.refresh();
            this.add('Registro Atualizado!', 'success');
          }, error: (error: any) => {
            this.spinner.hide();

            this.add(error.error.detail, 'danger');
          }, complete: () => this.spinner.hide()
        });
      }
    }
  }

  salvarPendencia() {
    if (this.registerForm.valid) {
      this.spinner.show();
      if (this.modoSalvar === 'post') {
        var id = this.dados.id;
        this.dados = Object.assign({}, this.registerForm.value);
        this.dados.id = id;


        this._service.pendenciar(this.dados).subscribe({
          next: (novoRegistro: Pedido) => {
            this.refresh();
            this.add('Registro salvo!', 'success');
          }, error: (error: any) => {
            this.spinner.hide();

            this.add(error.error.detail, 'danger');
          }, complete: () => this.spinner.hide()
        });
      }
    }
  }

  openModal(pedido: Pedido) {
    this.obs = pedido.justificativa;
    this.myModal.show();
  }

  openAuditoria() {
    this.myAuditoria.show();
  }


  validation() {
    this.registerForm = this.fb.group({
      cpfCnpj: ['', [Validators.required]],
      nomeCliente: ['', [Validators.required]],
      tipoVenda: ['', [Validators.required]],
      numeroPedido: [''],
      segmento: [''],
      numeroOS: [''],
      dataOSEmitida: [''],
      dataAgendamento: [''],
      dataInstalacao: [''],
      turno: [''],
      idBundle: [''],
      funcionarioId: [''],
      diaVencimento: [''],
      bolMigracaoFixoFibra: [''],
      bolMigracaoVeloxFibra: [''],
      bolPortabilidade: [''],
      vendaRecadastrada: [''],
      viabilidadeParcial: [''],
      contaOnline: [''],
      observacao: [''],
      indicacao:[''],
      vendedor:[''],
  valorPlanoDescricao:['']

    });
  }

  add(msg: string, tipo: string): void {
    //danger, success, info, warning
    window.scrollTo(0, 0);
    this.alerts.push({
      type: tipo,
      msg: msg,
      timeout: 5000
    });
    console.log(this.alerts);
  }

  onClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }



}
