import { Component } from '@angular/core';
import { navItems } from '../../_nav';
import { Router } from '@angular/router';
import { AuthService } from '../../_service/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constants } from '../../_utils/constants';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems = navItems;
  public usuario = "";
  public  versao =  Constants.VERSAO;

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
  constructor(private router: Router, public authService: AuthService, private spinner: NgxSpinnerService,) {
  }

  ngOnInit(): void {

    this.usuario = localStorage.getItem('nome');
    window.scrollTo(0, 0);

    if (this.authService.tokenExpired())
      this.logout();
  }


  logout(): void {
    this.authService.logout();
    this.router.navigateByUrl("/register");
  }




}
