import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { Constants } from '../../_utils/constants';
import { Pedido } from '../../_models/pedido';
import { PedidoService } from '../../_service/pedido.service';
import { GeralService } from '../../_service/geral.service';
import { Geral } from '../../_models/geral';
import { Endereco } from '../../_models/endereco';
import { PlanoService } from '../../_service/plano.service';
import { Produto } from '../../_models/produto';
import { ProdutoItem } from '../../_models/produtoItem';
import { PedidoItem } from '../../_models/pedidoItem';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PedidoAuditoria } from '../../_models/pedidoAuditoria';
import { PedidoIndicacao } from '../../_models/pedidoIndicacao';
import { DadosReceita } from '../../_models/dadosReceita';
import { ClienteTelefone } from '../../_models/clienteTelefone';
import { AuthService } from '../../_service/auth.service';


@Component({
  templateUrl: 'auditoria.component.html'
})

export class AuditoriaComponent implements OnInit {

  @ViewChild('myModal') public myModal: ModalDirective;
  @ViewChild('myResult') public myResult: ModalDirective;
  @ViewChild('myModalIndicacao') public myModalIndicacao: ModalDirective;
  @ViewChild('myModalReceita') public myModalReceita: ModalDirective;

  @ViewChild('myModalTelefone') public myModalTelefone: ModalDirective;



  alerts: any[];
  pag: Number = 1;
  contador: Number = 10;

  segmentoSel: number = 1;
  segmentoList: Geral[];
  tipoVendaList: Geral[];
  vendedorList: Geral[];
  indicacaoList: Geral[];
  origemList: Geral[];
  operadoraList: Geral[];
  operadora: number = 0;
  tipo: number = 0;

  produtoList: Produto[];
  produtoItemlist: ProdutoItem[];
  itemSelecionado: ProdutoItem[];

  planoList: Geral[];

  telefone: ClienteTelefone;
  telefoneList: ClienteTelefone[];
  origemDscricao: string;

  isShow: boolean = false;
  isShowEndereco: boolean = false;
  isShowCnpj: boolean = false;
  isShowAuditoria: boolean = false;
  isIndicacao: boolean = false;

  bolPrincipal: boolean = false;
  bolWhatsApp: boolean = false;

  listFiltrados: Pedido[];
  list: Pedido[];
  dadosReceita: DadosReceita;
  mensagemReceita: string = "";

  dados: Pedido;
  dadosAuditoria: PedidoAuditoria;
  dadosIndicacao: PedidoIndicacao;

  mask_cpf = Constants.CPF_FMT;
  mask_cnpj = Constants.CNPJ_FMT;
  mask_data = Constants.DATE_FMT;
  mask_telefone = Constants.TELEFONE_FMT;
  mask_celular = Constants.CELULAR_FMT;

  registerForm: FormGroup;
  auditoriaForm: FormGroup;
  indicacaoForm: FormGroup;
  telefoneForm: FormGroup;

  obs = ''; nome = ''; parentesco = '';
  operadoraImg = '../../../assets/img/brand/';
  _filtroLista = '';

  constructor(private _service: PedidoService, public authService: AuthService, private _serviceGeral: GeralService, private _servicePlano: PlanoService, private fb: FormBuilder,
    public router: Router, private spinner: NgxSpinnerService) { }

  get filtroLista(): string {
    return this._filtroLista;
  }
  set filtroLista(value: string) {
    this._filtroLista = value;
    this.listFiltrados = this.filtroLista ? this.filtrarDados(this.filtroLista) : this.list;
  }


  ngOnInit(): void {
    this.spinner.show();
    this.validation();
    this.getDados();
    this.getOperadora();
    this.alerts = new Array();
    this.telefoneList = new Array();
    window.scrollTo(0, 0);
    if (this.authService.tokenExpired())
      this.logout();
  }


  logout(): void {
    this.authService.logout();
    this.router.navigateByUrl("/register");
  }

  voltar() {
    this.router.navigate(['/dashboard']);
  }

  filtrarDados(filtrarPor: string): Pedido[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    return this.list.filter(
      d => d.nomeCliente.toLocaleLowerCase().indexOf(filtrarPor) !== -1
    );
  }

  openModal() {
    this.myModal.show();
  }

  getDados() {
    this._service.getbyAuditoria().subscribe({
      next: (_list: Pedido[]) => {
        this.list = _list;
        this.listFiltrados = this.list;

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }


  getOperadora() {
    this._serviceGeral.getOperadoraByUser().subscribe({
      next: (_listOperadora: Geral[]) => {
        this.operadoraList = _listOperadora;
        this.spinner.hide();
      }, error: (error: any) => {

        this.spinner.hide();
        this.add(`Erro ao tentar carrgar Operadoras`, 'danger');
      }
    });

  }

  getVendedor() {
    this.vendedorList = [];
    this._serviceGeral.getCombo("VendedorAtivo").subscribe({
      next: (_list: Geral[]) => {
        this.vendedorList = _list;
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar Vendedores`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  getIndicacao() {
    this.spinner.show();
    this.indicacaoList = [];

    this.isIndicacao = !this.isIndicacao;

    if (this.isIndicacao) {
      this._serviceGeral.getCombo("IndicacaoAitvo").subscribe({
        next: (_list: Geral[]) => {
          this.indicacaoList = _list;
          this.spinner.hide();
        }, error: (error: any) => {
          this.spinner.hide();
          this.add(`Erro ao tentar carrgar dados das Indicações`, 'danger');
        }
      });
    }
  }

  getOrigem() {
    this.spinner.show();
    this.origemList = [];
    this._serviceGeral.getCombo("OrigemTelefone").subscribe({
      next: (_list: Geral[]) => {
        this.origemList = _list;
        this.spinner.hide();
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados das origem do telefone`, 'danger');
      }
    });
  }

  setOrigem(valor: any) {
    this.origemDscricao = valor.target.selectedOptions[0].text;
  }

  deleteTelefone(telefone: string) {
    var aux: ClienteTelefone[] = new Array();
    this.telefoneList;

    this.telefoneList.forEach(element => {
      if (element.contato != telefone)
        aux.push(element);

    });

    this.telefoneList = aux;
  }

  getSegmento() {
    this.segmentoList = [];
    this._serviceGeral.getCombo("Segmento").subscribe({
      next: (_list: Geral[]) => {
        this.segmentoList = _list;
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar Segmentos`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  getPlano() {
    this.spinner.show();
    this._serviceGeral.getCombo("Plano").subscribe({
      next: (_list: Geral[]) => {
        this.planoList = _list;
        this.itemSelecionado = new Array();
        this.spinner.hide();
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar Planos`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  setSegmento(valor: number) {
    this.segmentoSel = valor;
    if (this.operadora == 4)
      this.isShowEndereco = valor > 1;
    this.isShowCnpj = valor > 1;

    if (this.isShowCnpj)
      this.getPlano();
  }

  setOperadora(valor: number) {

    this.spinner.show();
    this.tipoVendaList = [];
    this._serviceGeral.getTipoVendaByOperadora(valor).subscribe({
      next: (_list: Geral[]) => {
        this.tipoVendaList = _list;
        this.operadora = valor;
        this.spinner.hide();
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }
    });
  }


  setTipo(valor: number) {
    if (this.operadora == 4)
      this.isShowEndereco = (valor == 1 || this.isShowCnpj);
    else if (this.operadora == 2)
      this.isShowEndereco = true;

    this.tipo = valor;
  }


  setPlano(valor: number) {
    this.spinner.show();
    this._servicePlano.getProdutoById(valor).subscribe({
      next: (_list: Produto[]) => {
        this.produtoList = _list;
        this.itemSelecionado = new Array();
        this.spinner.hide();
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  incluirProduto(valor: string) {

    var prod: ProdutoItem;

    for (let index = 0; index < this.produtoList.length; index++) {
      const element = this.produtoList[index].item;
      prod = element.filter(obj => obj.id == parseInt(valor))[0];
      if (prod) break;
    }
    if (prod) {
      var r = this.itemSelecionado.filter(obj => obj.produtoId == prod.produtoId)[0];
      if (r)
        r.id = parseInt(valor);
      else {
        var r = this.itemSelecionado.filter(obj => obj.id == parseInt(valor))[0];
        if (!r) {

          var produto = new ProdutoItem();
          produto.descricao = "";
          produto.id = parseInt(valor);
          produto.produtoId = prod.produtoId;

          this.itemSelecionado.push(produto);
        }
      }
    }
    else {
      var produto = new ProdutoItem();
      produto.descricao = "";
      produto.id = parseInt(valor);
      produto.produtoId = prod.produtoId;

      this.itemSelecionado.push(produto);
    }

  }

  getEndereco(cep: string) {
    if (cep == "")
      return;

    this.spinner.show();
    this._serviceGeral.getEndereco(cep).subscribe({
      next: (_endereco: Endereco) => {

        this.dados.logradouro = _endereco.logradouro;
        this.dados.complemento = _endereco.complemento;
        this.dados.bairro = _endereco.bairro;
        this.dados.cidade = _endereco.localidade;
        this.dados.estado = _endereco.estado;
        this.dados.cep = _endereco.cep;
        this.dados.numero = '';

        this.registerForm.patchValue(this.dados);

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar Carregar endereço`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  refresh() {

    this.spinner.show();

    this.registerForm.reset();
    this.auditoriaForm.reset();
    this.indicacaoForm.reset();

    this.isShow = false;
    this.isShowEndereco = false;

    this.getDados();

    this.spinner.hide();
  }

  incluir() {
    this.spinner.show();

    this.registerForm.reset();
    this.auditoriaForm.reset();


    this.getSegmento();
    this.getVendedor();


    this.isShow = true;
    this.isShowAuditoria = false;
    this.isShowEndereco = false;
    this.isShowCnpj = false;
    this.isIndicacao = false;

    this.telefoneList = [];

    this.dados = new Pedido();
    this.spinner.hide();
  }

  auditar(pedido: Pedido) {
    this.spinner.show();
    this._service.updateResponsavel(pedido).subscribe({
      next: (registro: Pedido) => {


        this.isShowAuditoria = true;
        this.isShow = true;

        this.dados = Object.assign({}, pedido);
        this.isIndicacao = registro.indicacao?.length > 0;
        this.dados.indicacao = registro.indicacao;

        this.auditoriaForm.patchValue(this.dados);



        this.setSegmento(this.dados.segmento);


        this.dadosAuditoria = new PedidoAuditoria();
        this.dadosAuditoria.bolConfimacaoCliente = true;
        this.dadosAuditoria.bolDadosBancarios = false;
        this.dadosAuditoria.bolDadosCliente = false;
        this.dadosAuditoria.bolDadosInstalacao = false;
        this.dadosAuditoria.bolDadosPlanos = false;
        this.dadosAuditoria.bolDebitoConta = false;
        this.dadosAuditoria.bolDocumentos = false;


      }, error: (e: any) => {
        this.spinner.hide();
        this.add(e.error.detail, 'danger');
      }, complete: () => this.spinner.hide()
    });


  }

  validarReceita() {
    this.myModalReceita.hide();
    this.getDadosCnpj(this.dados.cpfCnpj);
  }

  enviarAuditoria(valida: boolean) {
    if (this.registerForm.valid) {
      this.spinner.show();

      this.dados = Object.assign({}, this.registerForm.value);
      this.dados.valorPlanoDescricao = this.dados.valorPlanoDescricao ?? "0";

      if (this.isShowCnpj) {
        if (this.telefoneList.length == 0 && this.operadora == 4) {
          this.add("Informe ao menos 1 telefone de contato", 'danger');
          this.spinner.hide();
          return;

        }

        if (this.dadosReceita == null && valida) {
          this.mensagemReceita = "Não foi possivel validar cliente na Receita Federal";
          this.spinner.hide();
          this.myModalReceita.show();
          return;

        }
        else if (this.dadosReceita.status == "ERROR" && valida) {
          this.mensagemReceita = "Não foi possivel validar cliente na Receita Federal";
          this.spinner.hide();
          this.myModalReceita.show();
          return;


        } else if (this.dadosReceita.status != "ATIVA" && valida) {
          this.mensagemReceita = "Cliente está com status INATIVO na Receita Federal";
          this.spinner.hide();
          this.myModalReceita.show();
          return;

        }


        let itens = new Array();
        for (let index = 0; index < this.itemSelecionado.length; index++) {
          const element = this.itemSelecionado[index];

          var itemPedido = new PedidoItem();
          itemPedido.pedidoId = 0;
          itemPedido.produtoItemId = element.id;
          itens.push(itemPedido);
        }
        if (itens.length < 2 && this.operadora == 4) {
          this.add(`Informe o produto do pedido`, 'danger');
          this.spinner.hide();
          return;
        }
        this.dados.item = itens;
        this.dados.clienteTelefone = this.telefoneList;

        this.salvar();

      }
      else {
        if (this.dados.numero == "" && !this.isShowCnpj) {
          this.add(`Informe o Número do Pedido | Código`, 'danger');
          this.spinner.hide();
        } else {

          this.salvar();
        }
      }

    }
  }


  salvar() {
    this._service.enviarAuditoria(this.dados).subscribe({
      next: (novoRegistro: Pedido) => {
        this.refresh();
        this.add('Pedido enviado!', 'success');
        this.spinner.hide();
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(error.error.detail, 'danger');
      }
    });
  }

  salvarIndicacao() {
    if (this.indicacaoForm.valid) {
      this.spinner.show();
      this.dadosIndicacao = new PedidoIndicacao();
      this.dadosIndicacao = Object.assign({}, this.indicacaoForm.value);

      this._service.salvarIndicacao(this.dadosIndicacao).subscribe({
        next: (novoRegistro: PedidoIndicacao) => {
          this.myModalIndicacao.hide();
          this.isIndicacao = false;
          this.getIndicacao();

          this.spinner.hide();
        }, error: (error: any) => {
          this.myModalIndicacao.hide();
          this.spinner.hide();
          this.add(error.error.detail, 'danger');
        }
      });
    }
  }

  setAuditoria(valor: number) {
    this.dadosAuditoria.bolConfimacaoCliente = valor == 1
  }


  valida(parametro: string, valor: boolean) {

    if (parametro == 'bolDadosCliente')
      this.dadosAuditoria.bolDadosCliente = valor;
    else if (parametro == 'bolDadosPlanos')
      this.dadosAuditoria.bolDadosPlanos = valor;
    else if (parametro == 'bolDadosInstalacao')
      this.dadosAuditoria.bolDadosInstalacao = valor;
    else if (parametro == 'bolDadosBancarios')
      this.dadosAuditoria.bolDadosBancarios = valor;
    else if (parametro == 'bolDebitoConta')
      this.dadosAuditoria.bolDebitoConta = valor;
    else if (parametro == 'bolDocumentos')
      this.dadosAuditoria.bolDocumentos = valor;

  }


  modalIndicacao() {
    this.indicacaoForm.reset();
    this.myModalIndicacao.show();
  }

  openModalTelefone() {
    this.getOrigem();
    this.telefoneForm.reset();
    this.myModalTelefone.show();
  }

  setPrincipal(valor: any) {

    var bol = valor.target.checked;
    this.bolPrincipal = bol;


  }

  setWhatsApp(valor: any) {

    var bol = valor.target.checked;
    this.bolWhatsApp = bol;

  }
  incluirTelefone() {

    this.telefone = Object.assign({}, this.telefoneForm.value);
    this.telefone.bolPrincipal = this.bolPrincipal == true ? 1 : 0;
    this.telefone.bolWhatsApp = this.bolWhatsApp == true ? 1 : 0;
    this.telefone.origemDescricao = this.origemDscricao;
    this.telefoneList.push(this.telefone);
    this.myModalTelefone.hide();

  }

  validaAuditoria() {

    var retorno = this.dadosAuditoria.bolDadosCliente && this.dadosAuditoria.bolDadosInstalacao &&
      this.dadosAuditoria.bolDadosPlanos && this.dadosAuditoria.bolDadosBancarios && this.dadosAuditoria.bolDebitoConta && this.dadosAuditoria.bolDocumentos;


    this.dadosAuditoria.nome = this.nome;
    this.dadosAuditoria.parentesco = this.parentesco;

    if (retorno) {
      this.dadosAuditoria.observacao = "Todos os itens da venda foram validados.";
      this.salvarAuditoria(1);
    }
    else {
      var observacao = "";

      //Validação de Cliente Solicitante
      observacao += this.dadosAuditoria.bolConfimacaoCliente ? "\n - Auditoria feita com o Solicitante" : "\n - Auditoria não foi feita com o solicitante";

      //Validação do Cliente
      observacao += this.dadosAuditoria.bolDadosCliente ? "" : "\n - Dados do Cliente.";

      //Validação da Venda
      observacao += this.dadosAuditoria.bolDadosPlanos ? "" : "\n - Dados do Plano Vendido.";

      //Validação da Instalação
      observacao += this.dadosAuditoria.bolDadosInstalacao ? "" : "\n - Dados da Instalação.";

      //Validação dados Bancários
      observacao += this.dadosAuditoria.bolDadosBancarios ? "" : "\n - Dados Bancários";

      //Validação da Débito em Conta
      observacao += this.dadosAuditoria.bolDebitoConta ? "" : "\n - Débito em Conta | Conta Online";

      //Validação de Documentos
      observacao += this.dadosAuditoria.bolDocumentos ? "" : "\n - Documentação do cliente | venda";

      this.obs = "Os itens da venda não foram validados: " + observacao + "\n " + this.obs;
      this.myResult.show();
    }


  }

  negar() { this.salvarAuditoria(3); }

  aprovarRessalva() { this.salvarAuditoria(2); }

  semContato() {
    this.spinner.show();
    this._service.updateSemContato(this.dados).subscribe({
      next: (registro: Pedido) => {
        this.refresh();
        this.add('Registro de Auditoria salvo!', 'success');
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carregar dados`, 'danger');
      }, complete: () => this.spinner.hide()
    });

  }

  setObs(valor: string) {
    this.obs = valor;
  }

  setParentesco(valor: string) {
    this.parentesco = valor;
  }

  setNome(valor: string) {
    this.nome = valor;
  }

  getDadosCnpj(valor: string) {
    if (valor == "" || valor == undefined)
      return;

    this._serviceGeral.getCnpj(valor).subscribe({
      next: (_list: DadosReceita) => {

        this.dadosReceita = _list;
        if (_list.status == "ERROR")
          this.add(_list.message, 'danger');
        this.spinner.hide();
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      },
    });
  }

  salvarAuditoria(status: number) {
    this.spinner.show();
    var msg = '';
    switch (status) {
      case 1:
        msg = "AUDITORIA APROVADA\n";
        break;
      case 2:
        msg = "AUDITORIA APROVADA COM RESSALVA\n";
        break;
      case 3:
        msg = "AUDITORIA NEGADA\n";
        break;
      default:
        break;
    }

    this.dadosAuditoria.observacao = msg + " - " + this.obs;
    this.dadosAuditoria.statusContato = status;
    this.dadosAuditoria.pedidoId = this.dados.id;
    this.dadosAuditoria.nome = this.nome;
    this.dadosAuditoria.parentesco = this.parentesco;

    this._service.salvarAuditoria(this.dadosAuditoria).subscribe({
      next: (novoRegistro: PedidoAuditoria) => {
        this.refresh();
        this.add(msg, 'success');
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar salvar dados`, 'danger');
      }, complete: () => this.spinner.hide()
    });

  }

  validation() {
    this.registerForm = this.fb.group({
      cpfCnpj: ['', [Validators.required]],
      nomeCliente: ['', [Validators.required]],
      tipoVenda: ['', [Validators.required]],
      numeroPedido: [''],
      segmento: [''],
      funcionarioId: ['', [Validators.required]],
      logradouro: [''],
      numero: [''],
      complemento: [''],
      bairro: [''],
      cidade: [''],
      estado: [''],
      cep: [''],
      diaVencimento: [''],
      metodoPagamento: [''],
      nomeResponsavel: [''],
      cpfResponsavel: [''],
      email: [''],
      telefone: [''],
      celular: [''],
      pedidoIndicacaoId: [''],
      operadora: ['']


    });

    this.auditoriaForm = this.fb.group({
      cpfCnpj: ['', [Validators.required]],
      nomeCliente: ['', [Validators.required]],
      tipoVenda: ['', [Validators.required]],
      numeroPedido: [''],
      segmento: [''],
      funcionarioId: ['', [Validators.required]],
      logradouro: [''],
      numero: [''],
      complemento: [''],
      bairro: [''],
      cidade: [''],
      estado: [''],
      cep: [''],
      diaVencimento: [''],
      metodoPagamento: [''],
      nomeResponsavel: [''],
      cpfResponsavel: [''],
      email: [''],
      telefone: [''],
      celular: [''],
      tipoVendaDescricao: [''],
      segmentoDescricao: [''],
      vendedor: [''],
      indicacao: [''],
      operadora: ['']

    });

    this.indicacaoForm = this.fb.group({
      nome: ['', [Validators.required]],
      referencia: ['', [Validators.required]]
    });

    this.telefoneForm = this.fb.group({
      contato: ['', [Validators.required]],
      origem: [''],
      bolPrincipal: [''],
      bolWhatsApp: [''],
    });
  }

  add(msg: string, tipo: string): void {
    //danger, success, info, warning.
    window.scrollTo(0, 0);
    this.alerts.push({
      type: tipo,
      msg: msg,
      timeout: 5000
    });
    console.log(this.alerts);
  }

  onClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }



}
