import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { ClienteService } from '../../_service/cliente.service';
import { Cliente } from '../../_models/cliente';
import { Constants } from '../../_utils/constants';
import { GeralService } from '../../_service/geral.service';
import { Geral } from '../../_models/geral';
import { ClienteTelefone } from '../../_models/clienteTelefone';


@Component({
  templateUrl: 'cliente.component.html'
})

export class ClienteComponent implements OnInit {


  categoriaList: Geral[];
  segmentoList: Geral[];
  alerts: any[];
  pag: Number = 1;
  contador: Number = 10;


  isShow: boolean = false;
  listFiltrados: Cliente[];
  list: Cliente[];
  telefone: ClienteTelefone[]= new Array();
  dados: Cliente;
  modoSalvar = 'post';

  mask_cpf = Constants.CPF_FMT;
  mask_data = Constants.DATE_FMT;
  mask_telefone = Constants.TELEFONE_FMT;
  mask_celular = Constants.CELULAR_FMT;


  registerForm: FormGroup;
  bodyDeletar = '';

  _filtroLista = '';

  constructor(private _service: ClienteService, private _serviceGeral: GeralService, private fb: FormBuilder, public router: Router, private spinner: NgxSpinnerService) { }

  get filtroLista(): string {
    return this._filtroLista;
  }
  set filtroLista(value: string) {
    this._filtroLista = value;
    this.listFiltrados = this.filtroLista ? this.filtrarDados(this.filtroLista) : this.list;
  }

  ngOnInit(): void {
    this.spinner.show();
    this.validation();
    this.getDados();
    this.alerts = new Array();
    window.scrollTo(0, 0);
  }

  voltar() {
    this.router.navigate(['/dashboard']);
  }

  filtrarDados(filtrarPor: string): Cliente[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    return this.list.filter(
      d => d.nome.toLocaleLowerCase().indexOf(filtrarPor) !== -1
    );
  }

  getDados() {
    this._service.getDados().subscribe({
      next: (_list: Cliente[]) => {
        this.list = _list;
        this.listFiltrados = this.list;

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  refresh() {

    this.spinner.show();
    this.registerForm.reset();
    this.isShow = false;
    this.getDados();

    this.spinner.hide();
  }

  getTelefone(id: number)
  {
    this._service.pesquisarTelefone(id).subscribe({
      next: (_list: ClienteTelefone[]) => {
        this.telefone = _list;
      }, error: (error: any) => {
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }
    });

  }

  getCategoria() {
    this._serviceGeral.getCombo("ClienteCategoria").subscribe({
      next: (_list: Geral[]) => {
        this.categoriaList = _list;
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  getSegmento() {
    this._serviceGeral.getCombo("Segmento").subscribe({
      next: (_list: Geral[]) => {
        this.segmentoList = _list;
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar carrgar dados`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  incluir() {
    this.registerForm.reset();
    this.getCategoria();
    this.getSegmento();
    this.isShow = true;
  }

  editar(item: Cliente) {

    this.spinner.show();
    this.modoSalvar = 'put';
    this.incluir();
    this.dados = Object.assign({}, item);
    this.registerForm.patchValue(this.dados);
    this.getTelefone(item.id);

    this.spinner.hide();
  }

  openModal() {
    this.registerForm.reset();
  }

  salvar() {
    if (this.registerForm.valid) {
      this.spinner.show();
      if (this.modoSalvar === 'post') {

        this.dados = Object.assign({}, this.registerForm.value);


        this._service.add(this.dados).subscribe({
          next: (novoRegistro: Cliente) => {
            this.refresh();
            this.add('Registro Salvo!', 'success');
          }, error: (error: any) => {
            this.spinner.hide();
            this.add(`Erro ao tentar carregar dados`, 'danger');
          }, complete: () => this.spinner.hide()
        });
      } else {
        this.dados = Object.assign({ id: this.dados.id }, this.registerForm.value);


        this._service.update(this.dados).subscribe({
          next: () => {
            this.refresh();
            this.add('Registro Editado!', 'success');
          }, error: (error: any) => {
            this.spinner.hide();
            this.add(`Erro ao tentar carregar dados`, 'danger');
          }, complete: () => this.spinner.hide()
        });
      }
    }
  }

  validation() {
    this.registerForm = this.fb.group({
      nome: ['', [Validators.required]],
      nomeMae: [''],
      cpf: ['', [Validators.required]],
      rg: [''],
      dataNascimento: [''],
      email: ['', [Validators.required]],
      observacao: [''],
      clienteCategoriaId: [''],
      tipoSegmento: ['']
    });
  }

  excluir(item: Cliente) {
    //this.confirm.show();
    this.dados = item;
    this.bodyDeletar = `Tem certeza que deseja excluir o Registro: ${item.nome}, Código: ${item.id}`;
  }

  confirmeDelete() {
    this._service.delete(this.dados).subscribe({
      next: () => {
        //this.confirm.hide();
        this.getDados();
        this.add('Registro deletado!', 'success');
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar Deletar: ${error}`, 'danger');
      }, complete: () => this.spinner.hide()
    });

  }

  add(msg: string, tipo: string): void {
    //danger, success, info, warning
    this.alerts.push({
      type: tipo,
      msg: msg,
      timeout: 5000
    });
    console.log(this.alerts);
  }

  onClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }



}
