import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgxPaginationModule } from 'ngx-pagination';
import { IconModule, IconSetModule, IconSetService } from '@coreui/icons-angular';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';



const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing.module';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { SetorService } from './_service/setor.service';
import { SetorComponent } from './views/setor/setor.component';
import { ConfigInternaComponent } from './views/configInterna/configInterna.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectDropDownModule } from 'ngx-select-dropdown'
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { AuthService } from './_service/auth.service';
import { AuthInterceptor } from './views/auth/auth.interceptor';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { EmpresaComponent } from './views/empresa/empresa.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ContratacaoComponent } from './views/contratacao/contratacao.component';
import { ContratacaoService } from './_service/contratacao.service';
import { FuncaoComponent } from './views/funcao/funcao.component';
import { FuncionarioComponent } from './views/funcionario/funcionario.component';
import { FuncionarioService } from './_service/funcionario.service';
import { FuncaoService } from './_service/funcao.service';
import { CheckListExeComponent } from './views/checkListExe/checkListExe.component';
import { CheckListExeService } from './_service/checkListExe.service';
import { CheckListService } from './_service/checkList.service';
import { DateTimeFormatPipePipe } from './_utils/DateTimeFormatPipe.pipe.pipe';
import { ClienteCategoriaComponent } from './views/clienteCategoria/clienteCategoria.component';
import { ClienteCategoriaService } from './_service/clienteCategoria.service';
import { ClienteComponent } from './views/cliente/cliente.component';
import { ClienteService } from './_service/cliente.service';
import { GeralService } from './_service/geral.service';
import { ProdutoComponent } from './views/produto/produtoComponent';
import { EquipeComponent } from './views/equipe/equipe.component';
import { EquipeService } from './_service/equipe.service';
import { PedidoService } from './_service/pedido.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AuditoriaComponent } from './views/auditoria/auditoria.component';
import { PlanoService } from './_service/plano.service';
import { GerarOSComponent } from './views/gerarOS/gerarOS.component';
import { FilaTrabalhoComponent } from './views/filaTrabalho/filaTrabalho.component';
import { ImportacaoComponent } from './views/importacao/importacao.component';
import { ImportacaoService } from './_service/importacao.service';
import { ExcelService } from './_service/excel.service';
import { PedidoComponent } from './views/pedido/pedido.component';
import { ResultadoComponent } from './views/resultado/resultado.component';
import { AgendamentoComponent } from './views/agendamento/agendamento.component';
import { ResultadoService } from './_service/resultado.service';
import { MetaService } from './_service/meta.service';
import { MetaComponent } from './views/meta/meta.component';
import { SistemaComponent } from './views/sistema/sistema.component';
import { MensagemService } from './_service/mensagem.service';
import { FpdIqComponent } from './views/fpdIq/fpdIq.component';
import { SincronizarDadosComponent } from './views/sincronizarDados/sincronizarDados.component';
import { PosVendaComponent } from './views/posVenda/posVenda.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  imports: [
    SelectDropDownModule,
    BrowserModule,
    BrowserAnimationsModule,
    AccordionModule.forRoot(),
    TooltipModule.forRoot(),
    CollapseModule.forRoot(),
    ButtonsModule.forRoot(),
    AlertModule.forRoot(),
    NgxPaginationModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    IconModule,
    IconSetModule.forRoot(),
    HttpClientModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot({
      timeOut: 3500,
      preventDuplicates: true,
      progressBar: true
   }),
   NgxSpinnerModule,
   DragDropModule,
   NgxMaskModule.forRoot(maskConfig)
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    SetorComponent,
    EmpresaComponent,
    FuncaoComponent,
    DashboardComponent,
    FuncionarioComponent,
    ConfigInternaComponent,
    ContratacaoComponent,
    CheckListExeComponent,
    ClienteCategoriaComponent,
    PedidoComponent,
    ProdutoComponent,
    ClienteComponent,
    EquipeComponent,
    AuditoriaComponent,
    GerarOSComponent,
    FilaTrabalhoComponent,
    ImportacaoComponent,
    ResultadoComponent,
    AgendamentoComponent,
    MetaComponent,
    DateTimeFormatPipePipe,
    SistemaComponent,
    FpdIqComponent,
    SincronizarDadosComponent,
    PosVendaComponent
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    IconSetService,
    FuncionarioService,
    FuncaoService,
    SetorService,
    AuthService,
    ContratacaoService,
    CheckListService,
    CheckListExeService,
    ClienteCategoriaService,
    ClienteService,
    GeralService,
    EquipeService,
    PedidoService,
    PlanoService,
    ImportacaoService,
    ExcelService,
    ResultadoService,
    MetaService,
    MensagemService,
    {provide: HTTP_INTERCEPTORS, useClass:AuthInterceptor, multi:true}
  ],
  bootstrap: [ AppComponent ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]

})
export class AppModule { }
