import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { User } from '../../_models/user';
import { AuthService } from '../../_service/auth.service';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {

    constructor(private router: Router, private authService: AuthService) { }



    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let currentUser:User;

        this.authService.currentUser$.pipe(take(1)).subscribe(user =>
          {
            currentUser = user;
            if (currentUser) {
              req = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${currentUser.token}`)
            });
            }

          });


        return next.handle(req);
      }
}
