import { Component, OnInit, ViewChild, } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Funcao } from '../../_models/funcao';
import { SetorService } from '../../_service/setor.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { Setor } from '../../_models/setor';
import { FuncaoService } from '../../_service/funcao.service';


@Component({
  templateUrl: 'funcao.component.html'
})


export class FuncaoComponent implements OnInit {
  alerts: any[];
  setorList: Setor[];
  funcao: Funcao;
  funcaoList: Funcao[];
  descricao: string;
  bodyDeletar = '';
  setorSelDescricao = '';
  modoSalvar = 'post';
  registerForm: FormGroup;
  registerFuncaoForm: FormGroup;

  setorSel: any;

  @ViewChild('myModal') public myModal: ModalDirective;
  @ViewChild('confirm') public confirm: ModalDirective;



  constructor(private _serviceSetor: SetorService, private _service: FuncaoService,
    private fb: FormBuilder, public router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.validation();
    this.getSetor();

    this.alerts = new Array();
    window.scrollTo(0, 0);
  }

  voltar() {
    this.router.navigate(['/config']);
  }

  getSetor() {
    this._serviceSetor.getSetor().subscribe({
      next: (_list: Setor[]) => {
        this.setorList = _list;

      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar Carregar: ${error}`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  listar() {

    let setor = this.setorSel;
    this._service.getFuncao(setor).subscribe({
      next: (_list: Funcao[]) => {
        this.funcaoList = _list;
        this.setorSelDescricao = '';
      }, error: (error: any) => {
        this.spinner.hide();
        this.add(`Erro ao tentar Carregar: ${error}`, 'danger');
      }, complete: () => this.spinner.hide()
    });
  }

  incluir() {
    if (this.setorSel == null) {
      this.add(`Selecione um SETOR`, 'danger');
      return;
    }

    this.setorList.forEach((currentValue, index) => {
      if (currentValue.id == this.setorSel) {
        this.setorSelDescricao = currentValue.descricao;
      }
    });
    this.modoSalvar = 'post';
    this.openModal();
  }

  editar(funcao: Funcao) {

    if (this.setorSel == null) {
      this.add(`Selecione um SETOR`, 'danger');
      return;
    }

    this.setorList.forEach((currentValue, index) => {
      if (currentValue.id == this.setorSel) {
        this.setorSelDescricao = currentValue.descricao;
      }

    });
    this.modoSalvar = 'put';
    this.openModal();
    this.funcao = Object.assign({}, funcao);
    this.registerForm.patchValue(this.funcao);
  }

  openModal() {
    this.registerForm.reset();
    this.myModal.show();
  }

  salvar(myModal: any) {
    if (this.registerForm.valid) {
      this.spinner.show();
      if (this.modoSalvar === 'post') {

        this.funcao = Object.assign({}, this.registerForm.value);
        this.funcao.funcaoPrincipal == null ? this.funcao.funcaoPrincipal = false : this.funcao.funcaoPrincipal = this.funcao.funcaoPrincipal;
        this.funcao.setorId = this.setorSel;

        this._service.add(this.funcao).subscribe({
          next: (novoRegistro: Funcao) => {
            myModal.hide();
            this.listar();
            this.add('Registro Salvo!', 'success');
          }, error: (error: any) => {
            this.spinner.hide();
            this.add(`Erro ao tentar Carregar: ${error}`, 'danger');
          }, complete: () => this.spinner.hide()
        });
      } else {
        this.funcao = Object.assign({ id: this.funcao.id }, this.registerForm.value);


        this._service.update(this.funcao).subscribe({
          next: () => {
            myModal.hide();
            this.listar();
            this.add('Registro Editado!', 'success');
          }, error: (error: any) => {
            this.spinner.hide();
            this.add(`Erro ao tentar Carregar: ${error}`, 'danger');
          }, complete: () => this.spinner.hide()
        });
      }
    }
  }

  validation() {
    this.registerForm = this.fb.group({
      descricao: ['', [Validators.required]],
      funcaoPrincipal: [false]
    });
  }

  excluir(funcao: Funcao) {
    this.confirm.show();
    this.funcao = funcao;
    this.bodyDeletar = `Tem certeza que deseja excluir o Registro: ${funcao.descricao}, Código: ${funcao.id}`;
  }

   confirmeDelete() {
     this._service.delete(this.funcao).subscribe({
       next:() => {
         this.confirm.hide();
         this.listar();
         this.add('Registro deletado!','success');
       }, error: (error: any) =>
       {
         this.spinner.hide();
         this.add(`Erro ao tentar Deletar: ${error}`,'danger');
       }, complete: () => this.spinner.hide()
     });

   }

  add(msg: string, tipo: string): void {
    //danger, success, info, warning
    this.alerts.push({
      type: tipo,
      msg: msg,
      timeout: 5000
    });
    console.log(this.alerts);
  }

  onClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }



}
